import { Link } from 'react-router-dom'


function TermsPage(props) {
  return (
    <div className='container min-height'>
      <div className='terms'>
        <h1>Terms of Use</h1>
        <p>Effective Date: April 18, 2022</p>

        <p>
          These Terms of Use ("Terms of Use") apply to all websites, apps, and
          services operated and controlled by Palansoft Private Limited. (either
          "we," "us" or "PS") or a third-party agent of PS, and other websites,
          apps, and services where these Terms of Use are posted (the "Sites").
          The Sites include, without limitation, the services offered on or in
          connection with the Palansoft (https://palansoft.com/), Modern Digital
          World LLC (https://www.dgtalworld.com/), and
          nasharatech.com(https://www.nasharatech.com/) websites and any related
          mobile applications or platforms. These Terms of Use set forth legally
          binding terms that govern and restrict your use of the Sites.
        </p>

        <p>
          YOUR AFFIRMATIVE ACT OF USING AND/OR REGISTERING WITH US, IF YOU DO
          NOT AGREE, YOU MAY NOT USE AND/OR REGISTER WITH THE SITES.
        </p>
        <p>
          IMPORTANT NOTICE REGARDING ARBITRATION: Please note the arbitration
          provision set forth below, which, with limited exceptions, requires
          you to arbitrate any claims you may have against PALANSOFT on an
          individual basis. ARBITRATION ON AN INDIVIDUAL BASIS MEANS THAT YOU
          WILL NOT HAVE, AND YOU WAIVE, THE RIGHT FOR A JUDGE OR JURY TO DECIDE
          YOUR CLAIMS, AND THAT YOU MAY NOT PROCEED IN A CLASS, CONSOLIDATED, OR
          REPRESENTATIVE CAPACITY.
        </p>
        <p>
          You understand and agree that the Sites are provided to you on an AS
          IS and AS AVAILABLE basis. We disclaim all responsibility and
          liability for the availability, timeliness, security or reliability of
          the Sites, the accuracy of any opinion or information contained on the
          Sites, or the availability and reliability of any software on the
          Sites. The opinions expressed on the Sites are not necessarily the
          opinions of Palansoft or its employees and may or may not be totally
          accurate. The content provided through the Sites is for educational
          and informational purposes only. The Sites are not intended to provide
          tax, legal, accounting, financial, or any other form of professional
          advice.
        </p>
        <p>
          Certain areas, features, or functionality of the Sites may be subject
          to different or additional terms, rules, guidelines or policies
          (“Additional Terms”), and we may provide such additional rules to you
          via postings, pop-up notices, links, or any other means, or otherwise
          in the manner required by applicable law, at the time that you access
          or use the relevant area, feature or functionality. Any reference to
          the “Terms of Use” in this agreement includes the Additional Terms.
        </p>
        <div className='divider-5'></div>
        <h3>1. Ownership of the Sites</h3>
        <p>
          The Sites contain copyrighted material, trademarks, and other
          proprietary information, including activities, videos, comments,
          articles, information, catalogs, brochures, data, text, software,
          photos, and graphics ("Content"). This Content is subject to
          copyrights and/or other intellectual property or proprietary rights
          owned by Palansoft and other individuals or entities and is protected
          by international intellectual property laws.
        </p>
        <div className='divider-5'></div>
        <h3>2. Use of Palansoft Content</h3>
        <p>
          THE SITES AND THE CONTENT OFFERED ON THE SITES ARE MADE AVAILABLE TO
          USERS FOR NON-COMMERCIAL USE AS SET FORTH IN THESE TERMS. You may use
          the Sites and the Content for, and only for, (i) your personal use; or
          (ii) bona fide educational purposes in a classroom or academic
          setting. In either case, PALANSOFT authorizes you to view a single
          copy of the Content as made available on the Sites or in the App.
          Under no circumstances may the Sites or Content be used in or for any
          commercial or for-profit manner or purpose. You may not either
          directly or through use of any device, software, internet site,
          web-based service, or other means, modify, publish, transmit, stream,
          upload, display, participate in the transfer or sale of, create
          derivative works of, or in any way exploit the Content of these Sites
          or any portion of such Content except as expressly provided herein.
          You may not use any data mining, robots, or similar data gathering and
          extraction tools on the Content, frame any portion of the Sites or
          Content, or reproduce, reprint, copy, store, publicly display,
          broadcast, stream, transmit, modify, translate, port, publish,
          sublicense, assign, transfer, sell, loan, or otherwise distribute the
          Content without our prior written consent. You may not circumvent any
          mechanisms included in the Content for preventing the unauthorized
          reproduction or distribution of the Content. You may not introduce
          viruses or any other computer code, files, or programs that interrupt,
          destroy, or limit the functionality of any computer software or
          hardware or telecommunications equipment; or damage, disable,
          overburden, impair, or gain unauthorized access to the Sites,
          including PALANSOFT's servers, computer network, or user accounts.
        </p>
        <p>
          Any use of the Content not expressly permitted by these Terms of Use
          is a breach of these Terms of Use and may violate international
          copyright, trademark, and/or other laws. If you violate these Terms of
          Use, your permission to use the Content automatically terminates.
        </p>
        <div className='divider-5'></div>
        <h3>3. Trademark Rights</h3>
        <p>
          The names, trademarks, service marks, and logos of Palansoft belong
          exclusively to PALANSOFT and are protected from reproduction,
          imitation, dilution or confusing or misleading uses under national and
          international trademark and other intellectual property laws. All
          other trademarks, service marks, and logos (including third-party
          product names) are the property of their respective owners. The use or
          misuse of these trademarks is expressly prohibited, and nothing stated
          or implied on the Sites confers on you any license or right under any
          trademark of PALANSOFT or any third party.
        </p>
        <div className='divider-5'></div>
        <h3>4. Subscriptions, Billing, and Cancellation</h3>
        <p>
          <strong>A.</strong>Paid Subscriptions. Many aspects of the Sites and
          the services offered thereon require payment before they can be
          accessed or used ("Paid Subscriptions"). While Paid Subscription
          options will vary from time to time, they will require you to pay a
          recurring fee on a monthly, bi-annual, or annual basis, or at some
          other recurring interval disclosed to you prior to purchase (the
          “Subscription Period”).
        </p>
        <p>
          Your Paid Subscription will typically be subject to Additional Terms
          presented to you prior to purchase. Importantly, Paid Subscriptions
          typically will not provide access to all services owned and operated
          by PALANSOFT. By way of example, a Paid Subscription offer presented
          on the Palansoft Private Limited website usually will not entail
          access to Digital World LLC or other website content or services.
          Please carefully review the terms of the applicable offer before
          registering for a Paid Subscription. We may change the price of Paid
          Subscriptions from time to time, but we will communicate any price
          changes to you in advance in the manner required by applicable law (if
          any) and, if applicable, we will tell you how to accept those changes.
          Price changes for Paid Subscriptions will take effect at the start of
          the next Subscription Period.
        </p>
        <p>
          By registering for a Paid Subscription, you authorize us to charge
          your credit card or other account that you have designated, on a
          recurring basis, for the applicable subscription fee at the then
          current rate plus applicable tax. All fees are charged in Indian Rupee
          for subscribers from Republic of India or in US Dollars (USD) for
          users outside India.
        </p>
        <p>
          <strong>B.</strong> Auto-Renewal and Cancellation. The subscription
          fee for a Paid Subscription will be billed at the beginning of your
          Subscription Period or expiration of your free trial period, if any.
          Paid Subscriptions automatically renew until they are cancelled as
          described below. This means that, after your initial Subscription
          Period, and again after any subsequent Subscription Periods, you will
          automatically be billed for an additional period of the same length.
          For example, if you select a monthly subscription, you will be billed
          for an additional month at the beginning of the next month, and then
          for each month after that, until you cancel your subscription.
        </p>
        <p>
          You may cancel your subscription at any time by logging into your
          account on the Sites or contacting us at help@palansoft.com. You have
          the right to cancel your subscription without fee or penalty at any
          time. If you cancel your subscription, cancellation will be effective
          at the end of the current Subscription Period. After cancellation, you
          will have continued access to the applicable content and/or services
          for the remainder of the current Subscription Period, but you will not
          receive any refund, unless otherwise stated at the time of
          cancellation. Please note that the provision of a full or partial
          refund in one instance will not entitle you or any person to a full or
          partial refund under similar circumstances or for any reason not
          specifically agreed to by PALANSOFT.
        </p>
        <p>
          <strong>C.</strong>Free Trials. On certain occasions, PALANSOFT may
          offer free trials of its paid services. If we offer you a free trial,
          the specific terms of the free trial will be stated on the relevant
          portion of the Sites or the marketing material describing the free
          trial. If you do not cancel your free trial within the trial period
          set out in the terms of the free trial, it will automatically convert
          to a Paid Subscription. This means that we will charge your payment
          card for the applicable subscription fee once your free trial period
          ends and we will continue charging you for each recurring Subscription
          Period until you cancel the subscription. You may cancel your
          subscription at any time by logging into your account or contacting us
          at help@palansoft.com. The free trial offer terms will present the
          details of the free trial and the Paid Subscription that will result
          absent cancellation.
        </p>
        <p>
          <strong>D.</strong>Promotions and Gift Cards. On certain occasions,
          you may acquire your Paid Subscription or be offered access to the
          Sites and/or the Content by an agreement between PALANSOFT and a
          third-party, such as your cable operator or television provider. In
          such cases, separate terms and conditions with such third party may
          apply to your use of the Sites in addition to these Terms of Use.
        </p>
        <div className='divider-5'></div>
        <h3>5. Other Transactions</h3>
        <p>
          Certain areas of the Sites may permit users to purchase products or
          services on a non-subscription basis, including physical merchandise.
          By using the Sites, you agree to provide current, complete and
          accurate purchase information for all purchases made on the Sites. You
          further agree to promptly update your account and other information,
          including your email address and credit card numbers and expiration
          dates, as applicable, so that we can complete your transactions and
          contact you as needed.
        </p>
        <p>
          PALANSOFT reserves the right to refuse any order you place with us. We
          may, in our sole discretion, limit or cancel quantities purchased per
          person, per household, or per order. These restrictions may include
          orders placed by or under the same customer account, the same credit
          card, and/or orders that use the same billing and/or shipping address.
          If we make a change to or cancel an order, we will notify you by
          contacting the email and/or billing phone number provided at the time
          the order was made, stating the relevant reasons.
        </p>
        <p>
          Certain products or services displayed on the Sites may have limited
          quantities and may not always be available. Purchase of our products
          for resale purposes is not authorized. We reserve the right to limit
          or prohibit orders that, in our reasonable judgment, appear to be
          placed by unauthorized dealers, resellers, or distributors, and to
          cease doing business with any such customers, with no further notice.
        </p>
        <p>
          We strive to display the colors and other features of our products
          that appear on the Sites as accurately as possible, but we cannot
          guarantee that your computer monitor&rsquo;s display of any of colors
          or other features will always be accurate.
        </p>
        <p>
          More generally, we do not warrant that the quality of any products
          purchased or obtained by you will meet your expectations. If a product
          offered on the Sites is not as described or pictured, or otherwise
          does not meet your expectations, your sole remedy is to seek to return
          the product under the terms of the applicable return policy, if any.
          All decisions related to returns will be made by PALANSOFT, in its
          sole discretion. Some jurisdictions do not allow the exclusion of
          certain warranties.
        </p>
        <div className='divider-5'></div>
        <h3>6. Product and Service Descriptions, Availability, and Pricing</h3>
        <p>
          PALANSOFT strives to be as accurate as possible and eliminate errors
          on the Sites. However, there may be information on the Sites that
          contains typographical errors, inaccuracies or omissions that may
          relate to product or service descriptions, pricing, promotions,
          offers, and availability. The prices displayed on the Sites are quoted
          in USD and are subject to change without notice as far as permitted
          under applicable law. To the extent permitted by applicable law, we
          reserve the right to correct any errors, inaccuracies or omissions and
          to change or update information if any information on the Sites is
          inaccurate at any time without prior notice.
        </p>
        <div className='divider-5'></div>
        <h3>7. License Grant for Your Content</h3>
        <p>
          If you make any submission to or communications on the Sites, you
          automatically grant, or warrant that you and/or the owner of such
          content has expressly granted PALANSOFT, a royalty-free, perpetual,
          irrevocable, worldwide, unlimited, nonexclusive license to use,
          reproduce, create derivative works from, modify, publish, edit,
          translate, distribute, perform, and display the communication or
          content in any media or medium, or any form, format, or forum now
          known or hereafter developed. PALANSOFT may sublicense its rights
          through multiple tiers of sublicenses.
        </p>
        <p>
          You agree that PALANSOFT shall have the right, but not the obligation,
          to monitor the content of the Sites to determine compliance with these
          Terms of Use. PALANSOFT shall have the right, where permitted by
          applicable law in its sole discretion, to edit, move, delete, or
          refuse to post any material shared by users of the Sites for any
          reason, including violation of these Terms of Use, whether for legal
          or other reasons. Notwithstanding this right of ours, users shall
          remain solely responsible for the content that they share on or
          through the Sites. Further, you acknowledge that, once published, we
          cannot always remove your content.
        </p>
        <p>
          Communications and content submitted on or through the Sites shall not
          be deemed confidential and PALANSOFT shall not have any obligation to
          keep any such material confidential. PALANSOFT shall be free to use
          any ideas, concepts, know-how or techniques contained in such
          information for any purpose whatsoever including, but not limited to,
          developing, manufacturing, and marketing products incorporating such
          information.
        </p>
        <div className='divider-5'></div>
        <h3>8. Age Limitation</h3>
        <p>
          The Sites are not intended for use by persons under the age of 13, or
          under the age of 18 for EU visitors. PALANSOFT does not knowingly
          collect information from visitors under the age of 13, or 18 in the
          EU, and in the event that we learn that a person under the age of 13,
          or 18 for the EU, has provided PALANSOFT with personal information, we
          will delete such personal information. If you are 13 years of age or
          older but under the age of majority in your jurisdiction of residence,
          you may only use the Sites with the permission and involvement of a
          parent or legal guardian who has agreed to these Terms of Use.
        </p>
        <div className='divider-5'></div>
        <h3>9. User Conduct</h3>
        <strong>
          By using the Sites, you agree to not use the Sites in any manner that:
        </strong>
        <p>
          • Is designed to interrupt, destroy or limit the functionality of, any
          computer software or hardware or telecommunications equipment
          (including by means of software viruses or any other computer code,
          files or programs).
        </p>
        <p>
          • Interferes with or disrupts the Sites, services connected to the
          Sites, or otherwise interferes with operations or services of the
          Sites in any way.
        </p>
        <p>
          • Infringes any copyright, trademark, trade secret, patent or other
          right of any party, or defames or invades the publicity rights or the
          privacy of any person, living or deceased (or impersonates any such
          person).
        </p>
        <p>
          • Consists of any unsolicited or unauthorized advertising, promotional
          materials, “junk mail,” “spam,” “chain letters,” “pyramid schemes,” or
          any other form of solicitation.
        </p>
        <p>
          • Causes PALANSOFT to lose (in whole or in part) the services of our
          Internet service providers or other suppliers.
        </p>
        <p>
          • Links to materials or other content, directly or indirectly, to
          which you do not have a right to link.
        </p>
        <p>
          • Is false, misleading, harmful, threatening, abusive, harassing,
          tortious, defamatory, vulgar, obscene, libelous, invasive of
          another&rsquo;s privacy, hateful, or racially, ethnically or otherwise
          objectionable, as determined by PALANSOFT in our sole discretion.
        </p>
        <p>
          • Copies, modifies, creates a derivative work of, reverse engineers,
          decompiles or otherwise attempts to extract the source code of the
          software underlying the Sites or any portion thereof or otherwise
          exploit the services or any software or content therein, except as
          expressly permitted by us or as permitted under applicable law.
        </p>
        <p>
          • Violates, or encourages anyone to violate these Terms of Use or the
          Privacy Policy; or
        </p>
        <p>
          • Violates, or encourages to violate, any applicable local, state,
          national, or international law, regulation, or order.
        </p>
        <div className='divider-5'></div>
        <h3>10. Disclaimer of Warranties</h3>
        <div className='divider-5'></div>
        <p>
          THE SITES AND THE CONTENT ON THE SITES ARE PROVIDED "AS IS." PALANSOFT
          MAKES NO REPRESENTATION OR WARRANTY OF ANY KIND WHATSOEVER TO YOU OR
          ANY OTHER PERSON RELATING IN ANY WAY TO THE SITES OR THE CONTENT OR
          COMMUNICATIONS ON THE SITES, OR ANY WEBSITE OR OTHER CONTENT OR
          SERVICE THAT MAY BE ACCESSIBLE DIRECTLY OR INDIRECTLY THROUGH THE
          SITES, TO THE EXTENT PERMITTED BY LAW. PALANSOFT DISCLAIMS IMPLIED
          WARRANTIES THAT THE SITES AND ALL SOFTWARE, CONTENT, SERVICES, AND
          INFORMATION DISTRIBUTED THROUGH THE SITES ARE MERCHANTABLE, OF
          SATISFACTORY QUALITY, ACCURATE, TIMELY, FIT FOR A PARTICULAR PURPOSE
          OR NEED, OR NON-INFRINGING. NO ORAL OR WRITTEN INFORMATION OR ADVICE
          GIVEN BY PALANSOFT OR A REPRESENTATIVE SHALL CREATE A WARRANTY.
        </p>
        <p>
          WE DO NOT GUARANTEE THAT THE SITES WILL MEET YOUR REQUIREMENTS, OR
          THAT THEY ARE ERROR-FREE, RELIABLE, WITHOUT INTERRUPTION OR AVAILABLE
          AT ALL TIMES. WE DO NOT GUARANTEE THAT THE SERVICES THAT MAY BE
          OBTAINED FROM THE USE OF THE SITES, INCLUDING ANY SUPPORT SERVICES,
          WILL BE EFFECTIVE, RELIABLE, AND ACCURATE OR MEET YOUR REQUIREMENTS.
          WE DO NOT GUARANTEE THAT YOU WILL BE ABLE TO ACCESS OR USE THE SITES
          (EITHER DIRECTLY OR THROUGH THIRD-PARTY NETWORKS) AT ALL TIMES OR
          LOCATIONS OF YOUR CHOOSING.
        </p>
        <p>
          FURTHER, PALANSOFT DOES NOT ENDORSE AND MAKES NO WARRANTY REGARDING
          THE ACCURACY OR RELIABILITY OF ANY OPINION, INFORMATION, ADVICE OR
          STATEMENT ON THE SITES. UNDER NO CIRCUMSTANCES WILL PALANSOFT BE
          LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY YOUR RELIANCE ON INFORMATION
          OBTAINED THROUGH THE CONTENT ON THE SITES. IT IS YOUR RESPONSIBILITY
          TO EVALUATE THE ACCURACY, COMPLETENESS OR USEFULNESS OF ANY
          INFORMATION, OPINION, ADVICE OR OTHER CONTENT AVAILABLE THROUGH THE
          SITES. PLEASE SEEK THE ADVICE OF PROFESSIONALS, AS APPROPRIATE,
          REGARDING THE EVALUATION OF ANY SPECIFIC INFORMATION, OPINION, ADVICE
          OR OTHER CONTENT ON THE SITES.
        </p>
        <div className='divider-5'></div>
        <h3>11. Limitation of Liability</h3>
        <p>
          In no event shall PALANSOFT, its partners, sponsors, advertisers,
          agents or licensors, or any third parties mentioned on the Sites be
          liable for any damages (including, without limitation, incidental,
          consequential or other indirect damages, personal injury/wrongful
          death, lost profits, or damages resulting from lost data or business
          interruption) resulting from the use or inability to use the Sites,
          the Content or any communications of the Sites, whether based on
          warranty, contract, tort, or any other legal theory, and whether or
          not PALANSOFT is advised of the possibility of such damages. PALANSOFT
          is not liable for any personal injury, including death, caused by your
          use or misuse of the Sites or Content.
        </p>
        <p>
          In addition, when using the Sites, information will be transmitted
          over a medium which is beyond the control and jurisdiction of
          PALANSOFT, its partners, advertisers, and sponsors or any other third
          party mentioned on the Sites. Accordingly, PALANSOFT assumes no
          liability for or relating to the delay, failure, interruption, or
          corruption of any data or other information transmitted in connection
          with use of the Sites.
        </p>
        <p>
          YOUR SOLE AND EXCLUSIVE REMEDY FOR ANY OTHER DISPUTE WITH PALANSOFT IS
          TO DISCONTINUE YOUR USE OF THE SITES OR ANY SERVICE OFFERED BY
          PALANSOFT. IN NO EVENT SHALL OUR LIABILITY, OR THE LIABILITY OF OUR
          AFFILIATES AND SUBSIDIARIES FOR ANY AND ALL CLAIMS RELATING TO THE USE
          OF THE SITES EXCEED THE TOTAL AMOUNT OF FEES THAT YOU PAID US DURING A
          ONE-YEAR PERIOD FOR THE SPECIFIC SERVICE AT ISSUE.
        </p>
        <div className='divider-5'></div>
        <h3>12. Passwords and Account Creation</h3>
        <p>
          To access certain portions of the Sites, you must create an account.
          You agree to provide truthful and accurate information during the
          account creation process. You also agree to maintain the accuracy of
          any submitted data. If you provide any information that is untrue,
          inaccurate, or incomplete, we reserve the right to terminate your
          account and suspend your use of any and all of the Sites.
        </p>
        <p>
          Users agree that they are responsible for all acts or omissions that
          occur on their account while their password is being used. PALANSOFT
          reserves the right to access and disclose in accordance with
          applicable law any information, including, without limitation,
          usernames of accounts and other information, to comply with applicable
          laws and lawful government requests.
        </p>
        <div className='divider-5'></div>
        <h3>13. Indemnification</h3>
        <p>
          You agree to defend (at PALANSOFT&rsquo;s option), indemnify, and hold
          PALANSOFT harmless from and against any and all liabilities, claims,
          damages, costs, and expenses, including attorneys&rsquo; fees and
          costs, arising from or related to your misuse of the Sites or any
          breach by you of these Terms of Use. We reserve the right, at our
          expense, to assume exclusive defense and control of any matter
          otherwise subject to indemnification by you and, in any case, you
          agree to cooperate with us if and as requested by us in the defense
          and settlement of such matter.
        </p>
        <div className='divider-5'></div>
        <h3>14. Privacy</h3>
        <p>
          We collect personal information on the Sites, which is subject to the
          terms of our Privacy Policy, which is available here
          <Link className='text-red' to='/privacy'>
            (Privacy).
          </Link>
        </p>
        <div className='divider-5'></div>
        <h3>15. Links to External Sites</h3>
        <p>
          The Sites may contain links to other websites. We do not control and
          are not responsible for the content or availability of these external
          sites nor do we endorse the activities or services provided by these
          websites. Insofar as permitted by applicable law, under no
          circumstances shall we be held responsible or liable, directly or
          indirectly, for any loss or damage caused or alleged to have been
          caused to a user in connection with the use of or reliance on any
          content, goods or services available on such external websites.
          Accessing third-party websites from our Sites is done entirely at your
          own risk.
        </p>
        <div className='divider-5'></div>
        <h3>16. Dispute Resolution</h3>
        <p>
          By using the Sites, you and PALANSOFT agree that, if there is any
          controversy, claim, action, or dispute arising out of or related to
          your use of the Sites or the breach, enforcement, interpretation, or
          validity of these Terms of Use or any part of them (“Dispute”), both
          parties shall first try in good faith to settle such Dispute by
          providing written notice to the other party describing the facts and
          circumstances of the Dispute and allowing the receiving party thirty
          (30) days in which to respond to or settle the Dispute. Notice shall
          be sent to:
        </p>
        <p>
          • Us, at Palansoft Private Limited, S-84 Al Muqaam, Vishala Circle,
          Nr. APMC Market, Ahmedabad-380055. Gujarat, India.
        </p>
        <p>
          • You, at the physical, registered mobile or email address we have on
          file for you.
        </p>
        <p>
          Both you and PALANSOFT agree that this dispute resolution procedure is
          a condition precedent that must be satisfied before initiating any
          litigation or filing any claim against the other party. IF ANY DISPUTE
          CANNOT BE RESOLVED BY THE ABOVE DISPUTE RESOLUTION PROCEDURE, YOU
          AGREE THAT SUCH DISPUTE WILL BE DECIDED BY BINDING ARBITRATION ON AN
          INDIVIDUAL BASIS. ARBITRATION ON AN INDIVIDUAL BASIS MEANS THAT YOU
          WILL NOT HAVE, AND YOU WAIVE, THE RIGHT TO HAVE A JUDGE OR JURY TO
          DECIDE YOUR CLAIMS, AND THAT YOU MAY NOT PROCEED IN A CLASS,
          CONSOLIDATED, COLLECTIVE OR REPRESENTATIVE CAPACITY. Other rights that
          you and we would otherwise have in court will not be available or will
          be more limited in arbitration, including discovery and appeal rights.
        </p>
        <p>
          All such Disputes shall be exclusively submitted to Judicial
          Arbitration and Mediation Services (JAMS) (www.jamsadr.com) for
          binding arbitration under its rules then in effect (as modified by
          this agreement to arbitrate), before one arbitrator to be mutually
          agreed upon by both parties. The arbitration shall be conducted in
          accordance with the JAMS Consumer Arbitration Minimum Standards
          (https://www.jamsadr.com/consumer-minimum-standards/) if it is
          determined by JAMS or the arbitrator that these standards are
          applicable to the Dispute. The location of any hearings will be
          determined by the applicable JAMS rules, provided that if the claim is
          for $10,000 or less, you may choose to have the arbitration conducted
          (1) solely on the basis of the documents submitted to the arbitrator
          or (2) through a non-appearance-based hearing by teleconference or
          videoconference.
        </p>
        <p>
          The arbitrator, and not any federal, state, or local court or agency,
          shall have exclusive authority to resolve any dispute arising under or
          relating to the interpretation, applicability, enforceability, or
          formation of these terms, including any claim that all or any part of
          these terms are void or voidable. For the avoidance of doubt, you and
          PALANSOFT agree that the arbitrator shall have the exclusive power to
          rule on his or her own jurisdiction, including any objections with
          respect to the existence, scope, or validity of this agreement to
          arbitrate or the arbitrability of any claim or counterclaim. The
          arbitrator may award (on an individual basis) any relief that would be
          available in a court. The award rendered by the arbitrator may be
          confirmed and enforced in any court having jurisdiction thereof.
        </p>
        <p>
          Notwithstanding the foregoing, in lieu of arbitration either you or
          PALANSOFT may (1) bring an individual claim in small claims court in
          the Gujarat, India consistent with any applicable jurisdictional and
          monetary limits that may apply and (2) file an individual claim in
          court to enjoin the infringement or other misuse of its intellectual
          property rights, provided that any such claim is brought and
          maintained on an individual basis.
        </p>
        <div className='divider-5'></div>
        <h3>17. Choice of Law</h3>
        <p>
          You agree that the laws of the India and the State of Gujarat govern
          these Terms of Use and any claim or dispute that you may have against
          us, without regard to Gujarat&rsquo;s or any other
          jurisdiction&rsquo;s conflict of laws rules.
        </p>
        <p>
          This does not deprive consumers of the protection afforded to them by
          provisions that cannot be derogated from by agreement by virtue of the
          law which, in the absence of a choice of law, would have been
          applicable (e.g., consumer laws of the consumer’s state of habitual
          residence). Thus, you will benefit from any mandatory provisions of
          the law of your local jurisdiction, and nothing in these Terms of Use
          affects your rights as a consumer to rely on such mandatory provisions
          of local law.
        </p>
        <p>
          The parties acknowledge that these Terms of Use evidence a transaction
          involving interstate commerce.
        </p>
        <div className='divider-5'></div>
        <h3>18. Choice of Forum</h3>
        <p>
          Except for disputes or claims properly lodged in a small claims court
          in the India, any disputes or claims not subject to the arbitration
          provision discussed above shall be resolved by a court located in
          Ahmedabad, Gujarat, India and you agree and submit to the exercise of
          personal jurisdiction of such courts for the purpose of litigating any
          such claim or action. You further agree that any such claims will be
          brought and maintained solely on an individual basis and not as part
          of any class, consolidated, collective or representative capacity, and
          that you waive your right to a jury trial with respect to any such
          action.
        </p>
        <div className='divider-5'></div>
        <h3>19. Modification &nbsp; Termination</h3>
        <p>
          We reserve the right to modify these Terms of Use at any time. When we
          do so, we will update the “Effective Date” above and/or provide notice
          to users by any other means we deem reasonable, in our sole
          discretion. By continuing to use the Sites, or any portion thereof,
          after we post any such changes, you accept these Terms of Use, as
          modified. Any changes to the Terms of Use will only apply to orders
          placed on or after the effective date of the updated Terms of Use. In
          this respect, the version of the Terms of Use in force and made
          available at the time of the order shall prevail.
        </p>
        <p>
          We may change, restrict access to, suspend or discontinue the Sites,
          or any portion of the Sites, at any time and at our sole discretion.
        </p>
        <p>
          We shall have the right to immediately terminate these Terms of Use
          and your of the Sites at any time and for any reason, including if we
          consider your use of the Sites to be unacceptable, or in the event of
          any breach by you of these Terms of Use.
        </p>
        <p>
          The provisions of Sections 1, 2, 3, 4, 7, 10, 11, 13, 16, 17, 18, 19,
          and 20 shall survive termination of these Terms of Use, as well as
          (insofar as permitted by applicable law) any other sections of these
          Terms of Use that, either explicitly or by their nature, must remain
          in effect even after termination of these Terms of Use.
        </p>
        <div className='divider-5'></div>
        <h3>20. Miscellaneous</h3>
        <p>
          <strong>A. </strong>No waiver. Our failure to act with respect to a
          breach by you or others does not waive our right to act with respect
          to a subsequent or similar breach or breaches. If PALANSOFT does not
          exercise or enforce any legal right or remedy which is contained in
          these Terms (or which PALANSOFT has the benefit of under any
          applicable law or regulation), such action or inaction shall not be
          taken to be a formal waiver of PALANSOFT’s rights, and all such rights
          or remedies shall still be available to PALANSOFT.
        </p>
        <p>
          <strong>B. </strong>Severability. If any provision of these Terms of
          Use is held to be invalid by a court having competent jurisdiction,
          the invalidity of such provision shall not affect the validity of the
          remaining provisions of these Terms, which shall remain in full force
          and effect.
        </p>
        <p>
          <strong>C. </strong>Entire Agreement. These Terms of Use, including
          any Additional Terms, set forth the entire understanding and agreement
          between us with respect to your use of the Sites and Applications.
        </p>
        <p>
          <strong>D. </strong>Assignment. You may not assign, transfer, or
          sublicense any or all of your rights or obligations under these Terms
          of Use without our express prior written consent. We may assign,
          transfer, or sublicense any or all of our rights or obligations under
          these Terms of Use without restriction.
        </p>
        <p>
          <strong>E. </strong>No relationship. These Terms of Use do not, and
          shall not be construed to, create any partnership, joint venture,
          employer-employee, agency, or franchisor-franchisee relationship
          between you and PALANSOFT.
        </p>
        <div className='divider-5'></div>
        <h3>21. Contacting PALANSOFT</h3>
        <p>
          If you have any questions about these Terms of Use, please contact us
          at the following address:
        </p>
        <p>
          <strong>Palansoft Private Limited,</strong>
          <br />
          S-84, Al Muqaam, Vishala
          <br />
          Circle, Nr. APMC Market, <br />
          Ahmedabad, 380055 Gujarat, India. <br />
          <br />
          <strong>Phone:</strong> +91 989 898 3059 <br />
          <br />
          <strong>Email:</strong> help[at]palansoft.com
        </p>
        {/* <p>
          Read about &nbsp;
          <Link className='text-red' to='/privacy'>
            Privacy
          </Link>
        </p> */}
      </div>
    </div>
  )
}

export default TermsPage
