
import ActivityPoster from '../components/ActivityPoster'
import { useState, useEffect } from 'react'
import Faqs from '../components/Faqs'
import Howtoguide from '../components/Howtoguide'
import MediaNasharatech from '../api/mediaNash'
import ActivityRaw from '../components/ActivityRaw'
import HomeFreeActivity from '../components/HomeFreeActivity'
import { Link } from 'react-router-dom'
import { FaBookOpen, FaSwatchbook, FaSmile,FaBell, FaCommentAlt, FaCalendarCheck, FaReceipt, FaRegAddressBook } from 'react-icons/fa'
//import ActivityRaw from '../components/demoapi'
import PalansoftSupportIcon from '../assets/app-icon-palansoft-support.png'
import appstore from '../assets/app-icon-appstore.png'
import playstore from '../assets/app-icon-playstore.png'
import FilterTeacher from '../components/FilterTeacher'
import ActivityBookTeacher from '../components/ActivityBookTeacher'

function Homepage(props) {
  const [userinfo, setUserinfo] = useState(null)
  const [useruid, setUseruid] = useState(null)
  const [Teachername, setTeachername] = useState('null')
  //const [teacheruserrole, setTeacheruserrole] = useState('null')
  
  const teacheruserrole = JSON.parse(localStorage.getItem('userinfo'))

  useEffect(async () => {
    //const userdata = await JSON.parse(localStorage.getItem('userinfo'))
    //const data = await JSON.parse(localStorage.getItem('userUID'))    
    const userdata = typeof window !== 'undefined' ? await JSON.parse(localStorage.getItem('userinfo')) : null
    const data = typeof window !== 'undefined' ? await JSON.parse(localStorage.getItem('userUID')) : null
    const teacherdata = typeof window !== 'undefined' ? await JSON.parse(localStorage.getItem('teacherinfo')) : null
    setUserinfo(userdata)
    setUseruid(data)
    setTeachername(teacherdata)
    
  }, [])
 
  return (
    <>
    
    {/* <div className='container'>
      <ActivityBookTeacher />
      </div> */}
        {userinfo && Teachername !== null ? 
          <>
          <div className='hometop-maincon'>        
            <div className='container'>
              <span className='hometop-usertype'>Teacher</span>
              {teacheruserrole?.Role == 'Teacher' ? <span className='hometop-filter-con'><FilterTeacher /></span> : null }              
              <ul className='hometop-filter-menu-con'>
                <li><span className='filtermenuicon iconcon'><FaCalendarCheck/></span> <span className='filtermenu-label'>Time Table</span></li>
                <li><span className='filtermenuicon iconcon'><FaReceipt /></span> <span className='filtermenu-label'>Daily Report</span></li>
                <li><span className='filtermenuicon iconcon'><FaSwatchbook/></span> <span className='filtermenu-label'>Syllabus</span></li>
                <li><span className='filtermenuicon iconcon'><FaSmile/></span> <span className='filtermenu-label'>Holidays</span></li>
                <li><span className='filtermenuicon iconcon'><FaBell/></span> <span className='filtermenu-label'>Announcements</span></li>
                <li><span className='filtermenuicon iconcon'><FaCommentAlt/></span> <span className='filtermenu-label'>Chat</span></li>
                <li><span className='filtermenuicon iconcon'><FaBookOpen/></span> <span className='filtermenu-label'>Homework</span></li>
                <li><span className='filtermenuicon iconcon'><FaRegAddressBook/></span> <span className='filtermenu-label'>Assessments</span></li>
              </ul>
            </div>
          </div>
          </> 
          : 
          null      
        }
      <div className='homepage-main-con'>
        <div className='home-inn-con-1'>
          {/* {userinfo && <h1>Welcome back, {userinfo.Name}</h1>} */}
          {useruid ? (
            <div className='container'>
              <div className='text-light-1 app-con-con-welcome-con'>
                <h1 className='app-con-con-welcome-con-h1'>
                  Activities runs better as APP, download now -
                </h1>
                <div className='app-con-con-welcome'>
                  <a
                    title='Get it on Google Play'
                    className='icon-box' target='_blank'
                    href='https://play.google.com/store/apps/details?id=com.palansoftinteractive'
                  >
                    <img
                      className='applogo icon-palan'
                      src={playstore}
                      alt='Download from Playstore'
                    />
                  </a>
                  <a
                    title='Available on the App Store'
                    className='icon-box' target='_blank'
                    href='https://www.apple.com/in/app-store/'
                  >
                    <img
                      className='applogo icon-palan'
                      src={appstore}
                      alt='Download on Appstore'
                    />
                  </a>
                </div>
              </div>
            </div>
          ) : (
            <div className='faq-con-main-home-con home-free-act-nobg'>
              <div className='container'>
                {!userinfo ? <HomeFreeActivity /> : ''}
              </div>
            </div>
            // <div className=''>
            //   <div className='video-bg-con-home'>
            //     <video className='homevideocon' autoPlay loop muted playsInline>
            //       <source src={MediaNasharatech.videoone} type='video/mp4' />
            //       Your browser does not support the video tag.
            //     </video>
            //   </div>
            // </div>
          )}
          {useruid && (
            <div className='container'>
              <ActivityPoster />
              <ActivityRaw />
            </div>
            
          )}
        </div>
      </div>
      {/* { userinfo ? ( */}
      
      
        
      {!useruid && userinfo ? (       
        <> 
        <div className='container'>
          {teacheruserrole?.Role == 'Teacher' ?  <ActivityBookTeacher /> : <ActivityPoster /> }
        </div>
           <div className='container'>            
            <ActivityRaw />
          </div>   
          {/* <div className='container text-white text-center'>
            <p>Please select user to view activity</p>
            <Link
              style={{ marginTop: '-10px' }}
              className='btn btn-sm btn-start btn-danger'
              to='/userlisting'
            >
              Select user
            </Link>
            </div> */}
        </>
      ) : (
        null
      )}
      
      {!userinfo && (
        <>
          <div className='home-call-action-con-top text-left'>
            <div className='container text-white'>
              <p>
                <strong>Education has a purpose.</strong> To shape our future
                generation and help achieve their dream. We at{' '}
                <strong> PALANSOFT,</strong> pamper their curiosity with our 3D
                interactive activities. We believe understanding is more
                important than just knowing. Let us explore the syllabus from a
                unique route and experience a new learning angle.
              </p>
            </div>
          </div>
          <div className='home-call-action-con'>
            <div className='container text-center'>
              <span className='text-white h2'>
                All our activities are FREE for 14 days. &nbsp;
              </span>
              <Link
                style={{ marginTop: '-10px' }}
                className='btn btn-lg btn-start btn-danger'
                to='/signup'
              >
                Try Now
              </Link>
            </div>
          </div>
        </>
      )}
      <div className='faq-con-main-home-con'>
        <div className='container'>
          <Howtoguide />
          {!userinfo ? (
            <div className=''>
              <div className='video-bg-con-home'>
                <video className='homevideocon' autoPlay loop muted playsInline>
                  <source src={MediaNasharatech.videoone} type='video/mp4' />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div className='divider-5'></div>
          <Faqs />
            </div>
            
          ) : (
            ''
          )}
          {/* {!userinfo ? <HomeFreeActivity /> : ''} */}
          
        </div>
        <div className='container text-right'>
          <a
            title='Try our Support Portal'
            href='https://support.palansoft.com/'
            target='_blank'
            style={{
              margin: '20px 0px 10px 20px',
              float: 'right',
              display: 'block',
            }}
          >
            <img
              src={PalansoftSupportIcon}
              alt='Support'
              style={{
                width: '150px',
                margin: 'auto',
                float: 'right',
                display: 'block',
              }}
            />
          </a>
          <span
            style={{
              margin: '25px 0px 0px 0px',
              float: 'right',
              color: '#999',
              fontSize: '20px',
              display: 'block',
            }}
          >
            Need more details?
          </span>
        </div>
      </div>
    </>
  )
}

export default Homepage
