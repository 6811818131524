import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import AxiosClient from '../api/axiosClient'
import { FaSpinner } from 'react-icons/fa'
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';


function BookChapter({props,item,bookitem,}) {

  const [loading, setLoading] = useState(false)
  const [chapterinfo, setChapterinfo] = useState([])
  const [bookchapinfo, setBookchapinfo] = useState([])
  const [bookchapinfono, setBookchapinfono] = useState()
  let navigate = useNavigate()
  
  const location = useLocation();

  //const [teacherbookinfo, setTeacherbookinfo] = useState([])
  const [apiError, setApiError] = useState(null)
  
  const teacherbookinfo = JSON.parse(localStorage.getItem('filterbook'))
  const WebImgUrl = 'http://www.atakat.com/Palansoft'
   

  useEffect(() => {
    if (!localStorage.getItem('filterbook')) {
      navigate('/signin')
    }    
  }, [])

  useEffect (() => {
    //console.log('Chapter detail - ', location.state.bookitem.Code);
    setBookchapinfo(location.state.bookitem)
    setBookchapinfono(location.state.bookitem.Code)
    let chapno = location.state.bookitem.Code    
  },[])


  // function chapterdetail() {
  //   navigate('/book-chapter-detail')
  // }


  const getChapterinfo = async (chapno) => {  
    //setLoading(true)
    var sendData = {
      LanguageCode: teacherbookinfo.LanguageCode,      
      ChapterCode:location.state.bookitem.Code,
      GradeCode: teacherbookinfo.GradeCode,
      BoardCode: teacherbookinfo.BoardCode,
      SubjectCode: teacherbookinfo.SubjectCode,
    }
    //console.log('Chapter data', sendData)
    await AxiosClient({
      method: 'post',
      url: '/palansoft/allContent',
      //url: APIURLUpdate,
      data: sendData,
    })
      .then((response) => {
        setLoading(false)
        if (response.data.status) {
          setChapterinfo(response.data.data)
          //console.log('chapter data is -', response.data.data)
          setLoading(false)
        } else {
          setLoading(false)
        }
      })
      .catch(function (error) {
        //console.log('Activity ', error)
      })
  }

  useEffect(() => {
    getChapterinfo()
    //console.log('book detail page', chapterinfo)
  }, [])

  return (
    <>
      <div className='container min-height'>
        {loading ? (
          <>
            <div className='col-12'>
              <div className='divider-4'></div>
              <FaSpinner className='loader' />
            </div>
          </>
        ) : (
          <>
            <div className='booklisting-main-con'>
            {teacherbookinfo !== null ?
              <>
              <div className='booklisting-mainbook-con'>
                <img
                  src={WebImgUrl + teacherbookinfo.img?.substring(1)}
                  alt='Loading...'
                  style={{ width: 'auto', margin: 'auto', display: 'block' }}
                />
              </div>
              
              <h2 className=' '>{location.state.bookitem?.ChapterName}</h2>
              <div className='divider-2'></div>               
              <nav aria-label='breadcrumb'>
                <ol onClick={() => navigate(-1)} className='breadcrumb'>
                  <li className='breadcrumb-item'>{teacherbookinfo.Board}</li>
                  <li className='breadcrumb-item'>{teacherbookinfo.GradeCode}</li>
                  <li className='breadcrumb-item'>{teacherbookinfo.Language}</li>
                  <li className='breadcrumb-item active' aria-current='page'>
                  {teacherbookinfo.Subject}
                  </li>                  
                </ol>
              </nav>
               
              <div className='divider-3'></div>
              <Tab.Container>
                <div className='book-chapterlist-boxcon'>
                  {chapterinfo.map((item, index) => (
                                     
                    <div className='book-chap-tab' key={index}>
                      <div                       
                        className={item.count >=1 ? 'book-chapter-inn' : 'book-chapter-inn nodata'}
                        style={{ backgroundColor: item.color1 }}
                        //onClick={() => chapterdetail()}
                        // onClick={(event) => {                          
                        //   changepopup(item)
                        // }}
                         //onClick={`/book-chapter/${item.title}`}
                      >
                         
                       <Link 
                    to={`/${item.title.replace(/ /g, "-")}`}
                    //to='/book-chapter-detail' 
                    state={{item,bookchapinfo}}
                    > 
                    <span
                    className='chaptericon'
                    style={{ backgroundColor: item.color2 }}
                  >
                    <img src={item.image} />
                  </span>
                  <span className='chaptername'>
                    {item.title}
                    <span className='chaptercount'>({item.count})</span>
                  </span>
                  </Link>
                      {/* <Nav.Item>
                      <Nav.Link eventKey={item.title} >
                        <span
                          className='chaptericon'
                          style={{ backgroundColor: item.color2 }}
                        >
                          <img src={item.image} />
                        </span>
                        <span className='chaptername'>
                          {item.title}
                          <span className='chaptercount'>({item.count})</span>
                        </span>
                        </Nav.Link>
                    </Nav.Item></Link> */}
                      </div>
                    </div>                                                        
                   
                  ))}
                </div>
                <div className='divider-4'></div>
                
              </Tab.Container>

              </>
              : null
              }
            </div>
            
            <div className='divider-4'></div>
            
          </>
        )}
      </div>
    </>
  )
}

export default BookChapter
