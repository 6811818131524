import React, {useState, useEffect} from 'react'
import { v4 as uuid } from 'uuid';

import {useNavigate} from "react-router-dom"
import ChatpPage from '../chat/ChatPage'

 

function Chat({socket}) { 
  const chatuniqueid = uuid();
  const smallchatuniqueid = chatuniqueid.slice(0,8)
  const [userUID, setUserUID] = useState("testuserUID");
  const [chatroomId, setChatroomId] = useState("chat1");

  const [username, setUsername] = useState("");
  const [room, setRoom] = useState("");
  const [showChat, setShowChat] = useState(false);

  const joinRoom = () => {
    const userDetails = {
      username:username,
      userUID:userUID,
      chatroomId:chatroomId,
      room:room,
    };
    if (username !== "" && room !== "") {      
      //console.log('test', userDetails)
      socket.emit("join_room", room,userDetails);
      
      setShowChat(true);
    }
  };

  return (
    <>
    <div className="container min-height">
      {!showChat ? (
        <div className='form-main-con-1'>
        <div className="joinChatContainer">          
          <div className="col-12">
          <h2 className="page-title-1 text-left">Join A Chat Room</h2>
          <p>Enter your Username to continue - Chat id - {smallchatuniqueid} </p>
          </div>
          <div className="input-group">
          <input
            className='form-control'  
            type="text"
            placeholder="Your Name..."
            onChange={(event) => {
              setUsername(event.target.value);
            }}
          />
          </div>
          <div className="input-group">
          <input
            className='form-control'  
            type="text"               
            placeholder="Room ID..."
            onChange={(event) => {
              setRoom(event.target.value);
            }}
          />
          </div>
          <div className='divider-2'></div>
        <div className='col-12'>
        <button onClick={joinRoom} className='btn btn-danger d-grid gap-2 col-12'>Join A Room</button>
        </div>
          
        </div>
        </div>
      ) : (
        <div className="d-flex flex-column flex-lg-row">
        
        
          <ChatpPage socket={socket} username={username} room={room} />
          
        </div>
      )}
      <div className='divider-9'></div>
    </div>
    
    </>
  )
}

export default Chat
