import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'

function Metatags({children, pagetitle, pagedesc, metakeyword, canonicalurl}) {
    return (
      <Helmet>
        <meta charset='utf-8' />
        <meta http-equiv='X-UA-Compatible' content='IE=edge' />
        <meta name='viewport' content='width=device-width, initial-scale=1.0' />
        <title>{pagetitle}</title>
        <meta name='description' content={pagedesc} />
        <meta name='geo.country' content='India' />
        <meta name='keywords' content={metakeyword} />
        {/* <meta
          property='og:description'
          content='Leading software development company in Melbourne, Australia, Synotive is the perfect technology partner for Australian businesses seeking technology solutions.'
        />
        <meta
          property='og:title'
          content='Software Development Melbourne, Australia | Synotive'
        />
        <meta property='og:url' content='https://www.synotive.com/about-us' /> */}
        <link rel='canonical' href={canonicalurl} />
      </Helmet>
    )
}

export default Metatags
