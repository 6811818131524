import React from 'react'

function Howtoguide() {
  return (
    <>
      <div className='howtoguide-con-main text-white'>
        {/* <div className='row'>
          <h1 className='text-white'>
            Palansoft Interactive Learning App &#45; FAQs
          </h1>
        </div> */}
        <div className='row'>
          <div className='container howtoguide-main-con'>
            <div className='row row-cols-1 row-cols-md-3 g-4 info-box-con'>
              <div className='col team-inn-con'>
                <div className='card-body'>
                  <h2 className='card-title'>Price 99 ₹ / Activity</h2>
                  <ol className='card-text'>
                    <li>400 days validity</li>
                    <li>Up to 3 children can access</li>
                    <li>Available on 3 devices</li>
                    <li>10% Activities are free</li>
                    <li>Videos, Definitions</li>
                    <li>Q &amp; A, Quiz</li>
                  </ol>
                </div>
              </div>
              <div className='col team-inn-con'>
                <div className='card-body'>
                  <h2 className='card-title'>Supported Devices</h2>
                  <ol className='card-text'>
                    <li>Smartphones</li>
                    <li>Tablets and iPads</li>
                    <li>Laptops and Desktops</li>
                    <li>Apple, Linux, Windows</li>
                    <li>Smart TVs</li>
                    <li>Smart Boards</li>
                  </ol>
                </div>
              </div>
              <div className='col team-inn-con'>
                <div className='card-body'>
                  <h2 className='card-title'>Available Languages</h2>
                  <ol className='card-text'>
                    <li>English</li>
                    <li>Hindi</li>
                    <li>Gujarati</li>
                    
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Howtoguide
