import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import AxiosClient from '../api/axiosClient'
import { FaSpinner } from 'react-icons/fa'
import Accordion from 'react-bootstrap/Accordion'

import {useParams} from "react-router-dom"

function BookKeywords ({props,item}) {

    const [loading, setLoading] = useState(false)
    const [chapterinfo, setChapterinfo] = useState([])
    const [bookchapinfo, setBookchapinfo] = useState([])
    const [bookchapinfono, setBookchapinfono] = useState()
    let navigate = useNavigate()
    
    const location = useLocation();

  const [mainData, setMainData] = useState(null)
  const [data, setData] = useState(null)
  const [activity, setActivity] = useState([])
  
  const [pageNo, setPageNo] = useState(1)
  const [showmorebtn, setShowmorebtn] = useState(true)
  // Popup box function
  const [open, setOpen] = useState(true)
  const [popupcontent, setPopupcontent] = useState([])
  const [ActivityViewCount, setActivityViewCount] = useState([])
  const [popupopentoggle, setPopupopentoggle] = useState(false)
  const [LanguageSelect, setLanguageSelect] = useState('')
  const [search, setSearch] = useState('')
  const [UserUidVal, setUserUidVal] = useState('')
  const [APIURLUpdate, setAPIURLUpdate] = useState('')
  const [videoNewUrl, setVideoNewUrl] = useState('')

  const [UserScoreStart, setUserScoreStart] = useState('')

  const NewUserUidVal = JSON.parse(localStorage.getItem('selectCurrentUserUID'))

  const teacherbookinfo = JSON.parse(localStorage.getItem('filterbook'))
  const WebImgUrl = 'http://www.atakat.com/Palansoft'

  useEffect (() => {
    if (!localStorage.getItem('filterbook')) {      
      navigate('/signin')
    } 
    //console.log('Chapter detail - ', location.state.item);
    //console.log('old detail - ', location.state.bookchapinfo);
    setBookchapinfo(location.state.item)
    setBookchapinfono(location.state.item.Code)
    let chapno = location.state.item.Code    
  },[])

  useEffect(async () => {
    const mainData = await JSON.parse(localStorage.getItem('userinfo'))
    const data = await JSON.parse(localStorage.getItem('selectCurrentUserUID'))
    const Langdata = await JSON.parse(localStorage.getItem('filterviewactivity'))    
    setMainData(mainData)
    setData(data)
    fetchData(data, Langdata, mainData)
  }, [search])

  useEffect(() => {
    if (localStorage.getItem('data')) {
      navigate('/UserListing')
    }
  }, [])

  async function fetchData(data, Langdata, mainData) {
    var sendData = {
      UID: NewUserUidVal? NewUserUidVal.UID : mainData?.UID,
      UserUID: NewUserUidVal ? NewUserUidVal.UserUID : '0',
      LanguageCode: Langdata ? Langdata.LanguageCode : teacherbookinfo?.LanguageCode,
      ChapterCode:location.state.bookchapinfo?.Code,
      GradeCode: Langdata ? Langdata.GradeCode : teacherbookinfo?.GradeCode,
      SubjectCode: Langdata ? Langdata.SubjectCode : teacherbookinfo?.SubjectCode,
      Keywords:'',
      Type:'-',      
      page: pageNo,
      Keywords: search,
    }
    //console.log('Book Keywords value - ', sendData)
    await AxiosClient({
      method: 'post',
      url: '/palansoft/Definition',
      //url: APIURLUpdate,
      data: sendData,
    })
      .then((response) => {
        setLoading(false)
        if (response.data.status) {
          //console.log('Book Keywords data - ', response.data);
          //localStorage.setItem('activitylist', JSON.stringify(response.data))
          pageNo == 1
            ? setActivity(response.data.data)
            : setActivity([...activity, ...response.data.data])
          setPageNo((prevValue) => prevValue + 1)
        } else {
          setShowmorebtn(false)
          //console.log('Book Keywords data Error ')
        }
      })
      .catch(function (error) {
        //console.log('Book Keywords data Error ',error)
      })
  }

  // const showMoreItems = async () => {
  //   setLoading(true)
  //   const Langdata = await JSON.parse(
  //     localStorage.getItem('filterviewactivity')
  //   )
  //   fetchData(data, Langdata)
  // }

  
  return (
    <>
    <div className='container min-height '>
        <div className='bookfaqminheight'>
        <div className='row'>
        {loading ? (
            null
            ) : (
            <>
            {teacherbookinfo ? 
            
            <div className='booklisting-main-con'>                
                <h2 className=' '>{location.state.bookchapinfo?.ChapterName}</h2>
                <div className='divider-2'></div>
                <nav aria-label='breadcrumb'>
                <ol onClick={() => navigate(-1)} className='breadcrumb'>
                    {/* <li className='breadcrumb-item'>{chapterinfodetail.BoardCode} </li> */}
                    <li className='breadcrumb-item'>{teacherbookinfo.Board}</li>
                    <li className='breadcrumb-item'>{teacherbookinfo.GradeCode}</li>
                    <li className='breadcrumb-item'>{teacherbookinfo.Language}</li>
                    <li className='breadcrumb-item active' aria-current='page'>{teacherbookinfo.Subject}</li>                   
                </ol>
                </nav>
                <div className='divider-1'></div>              
            </div>    
             : null }        
            <div className='divider-3'></div>
            <div className='bookfaqminheight-inncon'>
            <div className='faq-con-main'>
                <div className='webview-con'>
                {activity ? (
                    <Accordion>
                    {activity
                        .map((item, index) => (
                            <Accordion.Item  key={index} eventKey={index}>
                            <Accordion.Header>
                            {item.Word}
                            </Accordion.Header>
                            <Accordion.Body>
                                {item.Definition}
                            </Accordion.Body>                    
                            </Accordion.Item>
                        ))}
                    </Accordion>
                ) : (
                   ''
                )}

        
                </div>
            </div>
            </div>                               
        </>
        )}
        <div className='row'>
        {loading ? (
                <div className='container text-center mt-5'>
                    <FaSpinner className='loader' />
                </div>
                ) : null}
            <div className='divider-3'></div>  
            {/* {showmorebtn ? (
                <>
                    <div className='container text-center mt-5'>
                    <button
                        className='btn btn-danger'
                        onClick={showMoreItems}                
                    >
                        Load More
                    </button>
                    </div>
                </>
                ) : (
                <>
                    {loading ? (
                    ''
                    ) : (
                    <div className='container text-center mt-5'>
                        <span className='btn btn-secondary text-white disabled'>
                        That's all, for now, more activities are coming soon.
                        </span>
                    </div>
                    )}
                </>
                )}               */}
        </div>
      </div>      
    </div>    
    </div>
    
</>
  )
}

export default BookKeywords 
