import React from 'react'
import ChatPrivate from '../chat/ChatBody'
function ChatPageOld() {
  return (
    <div className='container min-height'>
      <div className='faqpage'>
          <ChatPrivate/>
      </div>
    </div>
  )
}

export default ChatPageOld
