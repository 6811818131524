import axios from 'axios'
const AxiosClient = axios.create({
  baseURL: 'http://www.atakat.com/node_palansoft_interactive',
  //baseURL: 'http://192.168.0.106:5000',
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})
export default AxiosClient
