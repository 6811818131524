import React from 'react'
import ReactDOM from 'react-dom'
import bootstrap from 'bootstrap/dist/css/bootstrap.min.css'
//import '../src/styles/metronic8.css'
import './index.css'


import App from './App'

ReactDOM.render(<App />, document.getElementById('root'))
