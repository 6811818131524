import React from 'react'
import Accordion from 'react-bootstrap/Accordion'

function Faqs() {
  return (
    <div className='faq-con-main'>
      <div className=''>
        <h2 className='text-white'>
          Palansoft Interactive Learning App &#45; FAQs
        </h2>
        <div className='divider-4'></div>
        <Accordion>
          <Accordion.Item eventKey='0'>
            <Accordion.Header>
              What is Palansoft Interactive Learning APP?
            </Accordion.Header>
            <Accordion.Body>
              Palansoft Interactive Learning App (PIL App) is an Interactive
              Learning App service that offers a wide variety of topics,
              interactive activities, educational videos, animations, learning
              material and more on various devices.
              <br />
              <br />
              Users can play as much as they want, whenever you want without a
              single commercial&#45;all for a very low fixed activity&#45;based
              price. There's always something new to discover and new
              interactive activities and learning material are added every week!
            </Accordion.Body>
          </Accordion.Item>
          
          <Accordion.Item eventKey='1'>
            <Accordion.Header>How much PIL cost?</Accordion.Header>
            <Accordion.Body>
              Play PIL activities on your smartphone, tablet, Smart TV, laptop,
              gaming console, VR Headsets, AR supported tablets or smart board,
              all for a very low fixed activitybased price. Price is fixed at ₹
              99.00 (Inclusive 18% GST) or USD 1.4 per activity. No extra costs,
              no contracts.
              <br />
              <br />
              Purchased activities are available in the device for 400 days from
              the date of purchase.
              <br />
              <br />
              Videos, Definitions, Questions and Answers, Quiz are absolutely
              free, and we do not charge direct or indirect cost for the same.
              <br />
              <br />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='2'>
            <Accordion.Header>
              Where can we play PIL activities?
            </Accordion.Header>
            <Accordion.Body>
              Play it anywhere, anytime. Sign in with your Palansoft account to
              play instantly on the web at palansoft.com from your personal
              computer or on any internetconnected device that offers the PIL
              (Palansoft Interactive Learning) app, including smart TVs,
              smartphones, Amazon Fire Stick, tablets, smart&#45;boards, and
              game consoles. *&#45;All devices may not be available as of now.
              <br />
              <br />
              You can also download your favorite shows with the iOS, Android,
              or Windows 10 app.
              <br />
              <br />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='3'>
            <Accordion.Header>
              How can I cancel PIL Activities?
            </Accordion.Header>
            <Accordion.Body>
              PIL App is flexible. There are no pesky contracts and no
              commitments. You can easily uninstall your application. There are
              no cancellation fees&#45;start or stop your account anytime. Paid
              activities will be available for you, in&#45;case you log back to
              App or browser.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey='4'>
            <Accordion.Header>
              What are other features available on PIL App?
            </Accordion.Header>
            <Accordion.Body>
              PIL App has an extensive library of Educational Videos, Topic
              Keywords, Question Answers, Animations, Quiz, and more. Play as
              much as you want, anytime you want.
              <br />
              <br />
              PIL supports 3 students under one parent login. Activities
              purchased will be available for both accounts.
              <br />
              <br />
              Parents can restrict children accounts to access activities for
              same, upper, or lower grades.
              <br />
              <br />
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey='5'>
            <Accordion.Header>
              How many profiles can I have under one parent login?
            </Accordion.Header>
            <Accordion.Body>
              The PIL app allows parent to create 3 students accounts within
              on login. Each account will have their access rights set by
              admin(parent).
              <br />
              <br />
              children profiles come with M&#45;PIN protected controls that let you
              restrict the grade level activity content children can play and block
              specific grades activity you don&apos;t want children to see.
              <br />
              <br />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='6'>
            <Accordion.Header>
              How can I add more than 3 children to my account?
            </Accordion.Header>
            <Accordion.Body>
              Current settings allow only 3 children under one parent account.
              To add more than 3 children, you may require adding additional admin
              user. PIL App is flexible. There are no pesky contracts and no
              commitments. You can easily uninstall your application. There are
              no cancellation fees&#45;start or stop your account anytime. Paid
              activities will be available for you, in-case you log back to App
              or browser.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='7'>
            <Accordion.Header>
              What are other features available on PIL App?
            </Accordion.Header>
            <Accordion.Body>
              PIL App has an extensive library of Educational Videos, Topic
              Keywords, Question Answers, Animations, Quiz, and more. Play as
              much as you want, anytime you want.
              <br />
              <br />
              PIL supports 3 students under one parent login. Activities
              purchased will be available for both accounts.
              <br />
              <br />
              Parents can restrict children accounts to access activities for
              same, upper, or lower grades.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='8'>
            <Accordion.Header>
              How many profiles can I have under one parent login?
            </Accordion.Header>
            <Accordion.Body>
              The PIL app allows parent to create 3 students accounts within
              on login. Each account will have their access rights set by
              admin(parent).
              <br />
              <br />
              children profiles come with M-PIN protected controls that let you
              restrict the grade level activity content children can play and block
              specific grades activity you don&apos;t want children to see.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='9'>
            <Accordion.Header>
              How can I add more than 3 children to my account?
            </Accordion.Header>
            <Accordion.Body>
              Current settings allow only 3 children under one parent
              account. To add more than three children, you may require adding
              additional admin user.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  )
}

export default Faqs
