import React, {  useState,  useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Collapse } from 'react-bootstrap'
import {    FaSpinner, FaLanguage } from 'react-icons/fa'
import { VscSettings } from 'react-icons/vsc'
import AxiosClient from '../api/axiosClient'
import appstore from '../assets/app-icon-appstore.png'
import playstore from '../assets/app-icon-playstore.png'
import FilterMenu from './FilterMenu'

function HomeFreeActivity() {
  const navigate = useNavigate()
  
  
  const [homeactivity, setHomeActivity] = useState([])
  const [loading, setLoading] = useState(true)
  const [pageNo, setPageNo] = useState(1)
  const [showmorebtn, setShowmorebtn] = useState(true)
  // Popup box function
  const [open, setOpen] = useState(true)
  const [popupcontent, setPopupcontent] = useState([])
  const [homeActivityViewCount, setHomeActivityViewCount] = useState([])
  const [popupopentoggle, setPopupopentoggle] = useState(false)
  const [LanguageSelect, setLanguageSelect] = useState('')
  const [search, setSearch] = useState('')
  const [UserUidVal, setUserUidVal] = useState('')
  const [selectedLan, setSelectedLan] = useState('')


  const NewUserUidVal = JSON.parse(localStorage.getItem('selectCurrentUserUID'))
  
  let WebImgUrl = 'http://www.atakat.com/Palansoft'

//Get Grade Data
  useEffect(() => {    
    async function fetchData() {        
          const request = await AxiosClient({
            method: 'get',
            url: '/palansoft/homeScreenfreeActivityList',
          })
            .then((response) => {
            setLoading(false)
            if (response.data.status) {
              //console.log('homeactivity', response.data)
              setHomeActivity(response.data.data)
            //     ? //localStorage.setItem('homeactivitylist', JSON.stringify(response.data))
            //       //pageNo === 1
            //       setHomeActivity(response.data.data)
            //     : setHomeActivity([...homeactivity, ...response.data.data])
            //     setHomeActivity([response.data.data])
            //   setPageNo((prevValue) => prevValue + 1)
            } else {
              setShowmorebtn(false)
            }
          })
            .catch(function (error) {
              console
                .log
                // 'There has been a problem with your fetch operation: ',
                //console.log('this is a', error.message),
                //console.log(error.message)
                ()
              //setApiStatus('Network error, Please try again after some time')
            })
         
    }
    fetchData()
  }, [])
  // End Get Grade Data
    
    
  // homeactivity Launch Popup
  const changepopup = (item, selectedLan) => {
    setPopupcontent([item])
    setPopupopentoggle(!popupopentoggle)    
  }

//   const showMoreItems = async () => {
//     setLoading(true)
//     const Langdata = await JSON.parse(
//       localStorage.getItem('filterviewactivity')
//     )
//     fetchData(data, Langdata)
//   }

  const refreshPage = () => {
    window.location.reload()
  }



  return (
    <>
      <div className='row'>
        {/* <h1 className='text-white text-left'>
          Experience interactive learning Activities
        </h1> */}
        <div className='container'>
          <div className='text-light-1 app-con-con-welcome-con'>
            <h1 className='app-con-con-welcome-con-h1'>
              Experience interactive learning Activities
            </h1>
            <div className='app-con-con-welcome'>
              <a
                title='Get it on Google Play'
                className='icon-box'
                href='https://play.google.com/store/apps/details?id=com.palansoftinteractive'
              >
                <img
                  className='applogo icon-palan'
                  src={playstore}
                  alt='Download from Playstore'
                />
              </a>
              <a
                title='Available on the App Store'
                className='icon-box'
                href='https://www.apple.com/in/app-store/'
              >
                <img
                  className='applogo icon-palan'
                  src={appstore}
                  alt='Download on Appstore'
                />
              </a>
            </div>
            <a className='link-all-activity' href='/all-activity'>All Activity</a>
          </div>
        </div>
      </div>
      {/* <FilterMenu /> */}
      {/* <Select
        options={options}
        onChange={handleFilterInput}
        value={selectedValue}
        placeholder='Select a region'
      /> */}

      <div className='container scrollableDiv homefreeactivity'>
        {/* <InfiniteScroll
          dataLength={activity.length}
          next={showMoreItems}
          hasMore={true}
          //loader={<h4>Loading...</h4>}
          scrollableTarget='scrollableDiv'
        >
        </InfiniteScroll> */}
        <div className='user-active-out-con'>
          {homeactivity ? (
            <>
              {homeactivity.map((item, index) => (
                <div key={index} className='card act-box-main-con'>
                  <span className='con-data act-img-con'>
                    {/* <span
                      className={`con-data act-price ${
                        item.Price > 0 && 'paid'
                      }`}
                    >
                      <FaRupeeSign className='fa icon-small' />
                      {item.Price}
                    </span> */}
                    <img
                      className='card-img-top act-img'
                      //src={item.ThumbnailBig}
                      //src={item.ThumbnailSmall}
                      src={WebImgUrl + item.Image.substring(1)}
                      alt={item.HomeActivity}
                    />
                  </span>
                  <div className='card-body act-info-con'>
                    <span className='card-title con-data act-name'>
                      {item.Title}
                    </span>
                    <span className='con-data act-category text-blue'>
                      {item.Subtitle}
                      {/* {item.Category}
                      <FaChevronRight className='fa' /> */}
                    </span>
                    <div className='divider-2'></div>
                    {/* <span className='con-data act-chapter'>{item.Lang1}</span> 
                    <span className='con-data act-grade text-text-gray-1 '>
                      Grade : {item.Lang1}
                      <span className='con-data act-lang'>
                        <FaLanguage className='fa icon-big' />
                        {item.Lang1}
                      </span>
                    </span>
                    */}
                    <div className='homefree-bton-con'>
                      <span
                        className='con-data act-rat-icon-con act-clicks text-blue2'
                        onClick={(event) => {
                          changepopup(item) || setSelectedLan(item.Link1)
                        }}
                      >
                        {/* <FaLanguage className='fa icon-big' /> */}
                        <span className='val-con'>{item.Lang1}</span>
                      </span>
                      <span
                        className='con-data act-rat-icon-con act-reviews text-yellow2'
                        onClick={(event) => {
                          changepopup(item) || setSelectedLan(item.Link2)
                        }}
                      >
                        <span className='val-con '>{item.Lang2}</span>
                      </span>
                      <span
                        className='con-data act-rat-icon-con act-purchase-count text-pink'
                        onClick={(event) => {
                          changepopup(item) || setSelectedLan(item.Link3)
                        }}
                      >
                        <span className='val-con'>{item.Lang3}</span>
                      </span>
                    </div>
                    {/* {item.Status === 'buy' ? (
                      <span className={`con-data act-buy-btn-con`}>
                        <Link
                          to='/'
                          //onClick={() => placeOrder(item)}
                          className='btn btn-warning text-white'
                        >
                          Buy
                        </Link>
                      </span>
                    ) : (
                      <span className={`con-data act-buy-btn-con`}>
                        <Link
                          to='/'
                          className='btn btn-danger text-white'
                          //onClick={() => changepopup(item); homeactivityviewcount(item)}
                          onClick={(event) => {
                            changepopup(item)
                            //|| homeactivityviewcount(item.Code)
                          }}
                        >
                          Launch
                        </Link>
                      </span>
                    )} */}
                  </div>
                </div>
              ))}
            </>
          ) : (
            ''
          )}
        </div>

        {loading ? (
          <div className='container text-center mt-5'>
            <FaSpinner className='loader' />
          </div>
        ) : null}
        {/* {showmorebtn ? (
          <>
            <div className='container text-center mt-5'>
              <button
                className='btn btn-danger'
                //onClick={showMoreItems}
                //onClick={() => setPageNo(pageNo + 1)}
                //onClick={() => alert( pageNo + 1)}
              >
                Load More
              </button>
            </div>
          </>
        ) : (
          <>
            <div className='container text-center mt-5'>
              <span className='btn btn-secondary text-white disabled'>
                That's all, for now, more activities are coming soon.
              </span>
            </div>
          </>
        )} */}
      </div>

      {popupopentoggle && (
        <div className='popup-outer-con' onClick={(e) => e.stopPropagation()}>
          <div className='popup-header' onClick={changepopup}>
            x
          </div>
          <div className='popup-body'>
            {popupcontent.map((popupinfo) => {
              return (
                <div key={popupinfo.id}>
                  <div className='viewdetailinfobox-con'>
                    <a
                      className='btn btn-danger'
                      onClick={() => setOpen(!open)}
                      aria-controls='viewdetailinfobox'
                      aria-expanded={open}
                    >
                      Detail
                    </a>
                    <Collapse in={open}>
                      <div id='viewdetailinfobox'>
                        <span>{popupinfo.Title}</span>
                        <span>:</span>
                        <span>{popupinfo.Subtitle}</span>
                      </div>
                    </Collapse>
                  </div>
                  <div className='iframe-box-outer-con'>
                    <div className='iframe-box-inn-con'>
                      <iframe
                        className='iframe-responsive'
                        src={selectedLan}
                      ></iframe>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          <div className='popup-footer'></div>
        </div>
      )}
    </>
  )
}

export default HomeFreeActivity
