import React, { useState,  useEffect } from 'react'
import AxiosClient from '../api/axiosClient'
import {  useNavigate } from 'react-router-dom'
import Nasrul from '../assets/team/nasrul.jpg'
import Metatags from '../components/shared/Metatags'
function Team() {

  const navigate = useNavigate()
  const [teaminfo, setTeaminfo] = useState([])
  const [nasdata, setNasdata] = useState([])
  const nasid = 'Code:PLN-0002'

  //console.log('team name is', teaminfo)
    useEffect(async () => {           
        const response = await AxiosClient({
          method: 'get',          
          url: '/palansoft/teamPalansoft',
        })
        if (response.data.status) {
          //console.log('get',response.data.data)
          setTeaminfo(response.data.data)
          //setNasdata(response.data(nasid))
        }      
    }, [])

  //console.log('and the', nasdata)
  return (
    <>
      <Metatags
        pagetitle='Palansoft Interactive Learning - Team'                
        canonicalurl='https://palansoft.com/team'
      />

      <div className='container min-height'>
        <div className='careers'>
          <h1>Team Palansoft Interactive Learning</h1>
          {/* {teaminfo.find((x) => nasid !== teaminfo.Code) ? <>Heeee</> : null} */}
          <div className='divider-5'></div>
          <div className='row'>
            <div className='container'>
              <div className='post-img-con'>
                <img
                  style={{ float: 'left', margin: '0px 20px 20px 0px' }}
                  src={Nasrul}
                  width='400px'
                />
                {/* <img
                style={{ float: 'left', margin: '0px 20px 20px 0px' }}
                src={`teaminfo.Image2/Code:PLN-${0002}`}
              /> */}

                <h1>Nasrul Saiyed</h1>
                <p>
                  With over 25 years of experience, he is actively involved with
                  three companies as Solution Architect and plays the main role
                  to get solutions developed in timely manner. He has an
                  academic background in Computer Science and has expertise in
                  Microsoft technologies.
                </p>
                {/* <h4 style={{ marginTop: '30px' }}>Academic</h4>
                <p>
                  A Computer Science Student and have Microsoft Certified
                  Professional (MCP), Microsoft Certified Solution Developer
                  (MCSD), Microsoft Certified System Engineer (MCSE) and
                  Microsoft Certified Trainer (MCT).
                </p> */}
                <h4>3D Augmented Reality, Virtual Reality and Mixed Reality</h4>
                <p>
                  He is very closely involved in AR, VR and MR Interactive
                  Educational Content development. His role is to steer company
                  on inked roadmap and guide core development team to keep pace
                  with ever changing technology. Providing them learning topics
                  and resources. Helping them to find a way if they are stuck
                  with any problem. Since March- 2019, we came long-way and
                  achieved major milestones.
                </p>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='container team-main-con'>
              <div className='row row-cols-1 row-cols-md-3 g-4'>
                {teaminfo.map((item, index) => (
                  <div id={item.Code} key={index} className='col team-inn-con'>
                    <div className=' h-100'>
                      <img
                        src={item.Image2}
                        className='team-img-top'
                        width=''
                        alt='Team Member'
                      />
                      <div className='card-body'>
                        <h5 className='card-title noborder-bottom'>
                          {item.TeamName}
                        </h5>
                        {/* <h5 className='card-title'>{item.Position1}</h5>
                      <h6 className='card-title'>{item.Position2}</h6>
                      <p className='card-text'>{item.Description}</p> */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Team
