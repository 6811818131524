import { Link } from 'react-router-dom'


 
function AboutPage(props) {
  return (
    <div className='container min-height'>
      <div className='about'>                
        <h1>About Palansoft Interactive Learning</h1>
        <br/>
        <p>
          We are an Ed-Tech company. The name "Palansoft" emanated from the
          first letters of our hometown "Palanpur" and the Software, the core
          coding activity we are involved in.
        </p>
        <p>
          At Palansoft, we create interactive activities in multiple languages,
          that can be capitalized on to improve students' conceptual
          understanding related to mathematics and science.
        </p>
        <p>
          We develop feature-rich activities considering the age group and
          knowledge acceptance level.
        </p>
        <p>
          We are way ahead in explaining the basic concepts utilizing the latest
          available technology.
        </p>
        <p>
          The learning applications and systems developed by us would strengthen
          students' knowledge to stand in the changing and competitive world.
        </p>

        <p>
          Our application delivers activities that are pocket friendly and they
          always enable you to enjoy the competitive advantages of the
          technologies and serve the knowledge in the easiest form.
        </p>
        <p>
          We aim to differentiate ourselves by developing applications and
          activities that are well explained, pocket-friendly, and compatible
          with a wide range of available devices.
        </p>

        <p>
          To design and develop applications and activities by using the latest
          technology that helps students, parents, teachers, schools, society
          and the environment.
        </p>
        {/* <p>
          <Link className='btn btn-danger' to='/'>
            Back To Home
          </Link>
        </p> */}
      </div>
    </div>
  )
}

export default AboutPage
