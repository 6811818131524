import React, { useState, useEffect, useCallback, Select } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FaSpinner} from 'react-icons/fa'


function ActivityBookTeacher() {
  const navigate = useNavigate()
  const [teacherBooks, setTeacherBooks] = useState([])
  const [loading, setLoading] = useState(false)
  
  
  useEffect(async () => {
    const mainData = await JSON.parse(localStorage.getItem('teacherbookinfo'))        
    setTeacherBooks(mainData)  
  }, [teacherBooks])

    
  const WebImgUrl = 'http://www.atakat.com/Palansoft'
  function chapterdetail(item) {
    setLoading(true)
    localStorage.setItem('filterbook', JSON.stringify(item))
    setTimeout(() => {
      setLoading(false)
      navigate('/book-detail')
    },4000)
    
  }

  return (
    <>
      <div className='row'>
      <div className='col-12'>
          {loading ? <FaSpinner className='loader' /> : ''}           
        </div>        
        <div className='movie-row-inn-con'>
          {teacherBooks?.map((item, index) => (
            <div
              className='row movie-img'
              onClick={() => chapterdetail(item)}
              id={item.id}
              key={index}
            >
              <img
                className=''
                alt={item.Language}
                title={item.Name}
                src={WebImgUrl + item.img?.substring(1)}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default ActivityBookTeacher
