import React, {  useState,  useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Collapse } from 'react-bootstrap'
import {
  FaEye,
  FaChevronRight,
  FaComment,
  FaShoppingCart,
  FaSpinner,
  FaLanguage,
  FaRupeeSign,
  FaFilter,
} from 'react-icons/fa'
import { VscSettings } from 'react-icons/vsc'
import AxiosClient from '../api/axiosClient'
import appstore from '../assets/app-icon-appstore.png'
import playstore from '../assets/app-icon-playstore.png'
import FilterMenu from './FilterMenu'

function HomeViewAllActivity() {
  const navigate = useNavigate()
  
  
  const [homeactivity, setHomeActivity] = useState([])
  const [loading, setLoading] = useState(true)
  const [pageNo, setPageNo] = useState(1)
  const [showmorebtn, setShowmorebtn] = useState(true)
  // Popup box function
  const [open, setOpen] = useState(true)
  const [popupcontent, setPopupcontent] = useState([])
  const [homeActivityViewCount, setHomeActivityViewCount] = useState([])
  const [popupopentoggle, setPopupopentoggle] = useState(false)
  const [LanguageSelect, setLanguageSelect] = useState('')
  const [search, setSearch] = useState('')
  const [UserUidVal, setUserUidVal] = useState('')
  const [selectedLan, setSelectedLan] = useState('')


  const NewUserUidVal = JSON.parse(localStorage.getItem('selectCurrentUserUID'))
  
  let WebImgUrl = 'http://www.atakat.com/Palansoft'

//Get Grade Data
  useEffect(() => {    
    async function fetchData() {        
          var sendData = {
            UID: 'f2e4f268-f4bb-4c72-80e1-50c8f6300c9e',
            UserUID: '179c60cb-7db6-4850-8c45-7760523f0199',
            page: 1,
          }
          const request = await AxiosClient({
            method: 'get',
            url: '/activity/activityListingWithoutLogin',
            //method: 'post',
            //url: '/activity/activityFromParams',
            //data: sendData,
          }).then((response) => {
            setLoading(false)
            if (response.data.status) {
              //console.log('homeactivity', response.data)
              setHomeActivity(response.data.data)
              //     ? //localStorage.setItem('homeactivitylist', JSON.stringify(response.data))
              //       //pageNo === 1
              //       setHomeActivity(response.data.data)
              //     : setHomeActivity([...homeactivity, ...response.data.data])
              //     setHomeActivity([response.data.data])
              //   setPageNo((prevValue) => prevValue + 1)
            } else {
              setShowmorebtn(false)
            }
          })
    }
    fetchData()
  }, [])
  // End Get Grade Data
    
    
  // homeactivity Launch Popup
  const changepopup = (item, selectedLan) => {
    setPopupcontent([item])
    setPopupopentoggle(!popupopentoggle)    
  }

//   const showMoreItems = async () => {
//     setLoading(true)
//     const Langdata = await JSON.parse(
//       localStorage.getItem('filterviewactivity')
//     )
//     fetchData(data, Langdata)
//   }

  const refreshPage = () => {
    window.location.reload()
  }



  return (
    <>
      <div className='container all-activity-demo-page'>
        <div className='row'>
          <div className='user-active-out-con'>
            {homeactivity ? (
              <>
                {homeactivity.map((item, index) => (
                  // <div key={index} className='card act-box-main-con'>
                  //   <span className='con-data act-img-con'>
                  //     <img
                  //       className='card-img-top act-img'
                  //       //src={item.ThumbnailBig}
                  //       //src={item.ThumbnailSmall}
                  //       src={WebImgUrl + item.Image.substring(1)}
                  //       alt={item.HomeActivity}
                  //     />
                  //   </span>
                  //   <div className='card-body act-info-con'>
                  //     <span className='card-title con-data act-name'>
                  //       {item.Title}
                  //     </span>
                  //     <span className='con-data act-category text-blue'>
                  //       {item.Subtitle}
                  //       {/* {item.Category}
                  //     <FaChevronRight className='fa' /> */}
                  //     </span>
                  //     <div className='divider-2'></div>

                  //     <div className='homefree-bton-con'>
                  //       <span
                  //         className='con-data act-rat-icon-con act-clicks text-blue2'
                  //         onClick={(event) => {
                  //           changepopup(item) || setSelectedLan(item.Link1)
                  //         }}
                  //       >
                  //         {/* <FaLanguage className='fa icon-big' /> */}
                  //         <span className='val-con'>{item.Lang1}</span>
                  //       </span>
                  //       <span
                  //         className='con-data act-rat-icon-con act-reviews text-yellow2'
                  //         onClick={(event) => {
                  //           changepopup(item) || setSelectedLan(item.Link2)
                  //         }}
                  //       >
                  //         <span className='val-con '>{item.Lang2}</span>
                  //       </span>
                  //       <span
                  //         className='con-data act-rat-icon-con act-purchase-count text-pink'
                  //         onClick={(event) => {
                  //           changepopup(item) || setSelectedLan(item.Link3)
                  //         }}
                  //       >
                  //         <span className='val-con'>{item.Lang3}</span>
                  //       </span>
                  //     </div>

                  //   </div>
                  // </div>
                  <div key={index} className='card act-box-main-con'>
                    <span className='con-data act-img-con'>
                      {/* <span
                        className={`con-data act-price ${
                          item.Price > 0 && 'paid'
                        }`}
                      >
                        <FaRupeeSign className='fa icon-small' />
                        {item.Price}
                      </span> */}
                      <img
                        className='card-img-top act-img'
                        //src={item.ThumbnailBig}
                        src={item.ThumbnailSmall}
                        alt={item.Activity}                       
                      />
                    </span>
                    <div className='card-body act-info-con'>
                      {/* <span className='con-data act-category text-blue'>
                        {item.Category}
                        <FaChevronRight className='fa' />
                        {item.Subject}
                      </span> */}
                      <span className='card-title con-data act-name'>
                        {item.Activity}
                      </span>
                      {/* <span className='con-data act-chapter'>
                        {item.Chapter}
                      </span>
                      <span className='con-data act-grade text-text-gray-1 '>
                        Grade : {item.Grade}
                        <span className='con-data act-lang'>
                          <FaLanguage className='fa icon-big' />
                          {item.Language}
                        </span>
                      </span>

                      <span className='con-data act-rat-icon-con act-clicks'>
                        <FaEye className='fa icon-big' />
                        <span className='val-con'>{item.Views}</span> Total
                        views
                      </span>
                      <span className='con-data act-rat-icon-con act-reviews'>
                        <FaEye className='fa icon-big' />
                        <span className='val-con'>{item.OwnViews}</span> Own
                        views
                      </span>
                      <span className='con-data act-rat-icon-con act-purchase-count'>
                        <FaShoppingCart className='fa icon-big' />
                        <span className='val-con'>{item.Purchase}</span> Total
                        purchases
                      </span> */}

                      {/* {item.Status === 'buy' ? (
                        <span className={`con-data act-buy-btn-con`}>
                          <Link
                            to='/'
                            onClick={() => placeOrder(item)}
                            className='btn btn-warning text-white'
                          >
                            Buy
                          </Link>
                        </span>
                      ) : (
                        <span className={`con-data act-buy-btn-con`}>
                          <Link
                            to='/'
                            className='btn btn-danger text-white'
                            //onClick={() => changepopup(item); activityviewcount(item)}
                            onClick={(event) => {
                              changepopup(item) || activityviewcount(item.Code)
                            }}
                          >
                            Launch
                          </Link>
                        </span>
                      )} */}
                    </div>
                  </div>
                ))}
              </>
            ) : (
              ''
            )}
          </div>

          {loading ? (
            <div className='container text-center mt-5'>
              <FaSpinner className='text-white loader' />
            </div>
          ) : null}
          {/* {showmorebtn ? (
          <>
            <div className='container text-center mt-5'>
              <button
                className='btn btn-danger'
                //onClick={showMoreItems}
                //onClick={() => setPageNo(pageNo + 1)}
                //onClick={() => alert( pageNo + 1)}
              >
                Load More
              </button>
            </div>
          </>
        ) : (
          <>
            <div className='container text-center mt-5'>
              <span className='btn btn-secondary text-white disabled'>
                That's all, for now, more activities are coming soon.
              </span>
            </div>
          </>
        )} */}
          {loading ? (
            ''
          ) : (
            <div className='container text-center mt-5'>
              <span className='btn btn-secondary text-white disabled'>
                That's all, for now, more activities are coming soon.
              </span>
            </div>
          )}
        </div>
      </div>
      <div className='divider-9'></div>
    </>
  )
}

export default HomeViewAllActivity
