import React from 'react'
import Faqs from '../components/Faqs'
function FaqPage() {
  return (
    <div className='container min-height'>
      <div className='faqpage'>
        <Faqs />
      </div>
    </div>
  )
}

export default FaqPage
