//import { Link } from 'react-router-dom'
import { FaPhoneAlt, FaRegEnvelope, FaMapMarkerAlt } from 'react-icons/fa'
import Metatags from '../components/shared/Metatags'
function Contact(props) {
  return (
    <>
      <Metatags
        pagetitle='Palansoft Interactive Learning - Contact'        
        canonicalurl='https://palansoft.com/contact'
      />
      <div className='container min-height'>
        <div className='contact'>
          <h1>Contact Palansoft Interactive Learning</h1>

          <div className='d-flex bd-highlight'>
            <div className='p-2 flex-fill bd-highlight contact-iframe-con-out'>
              <iframe title='Map Location India'
                src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7345.519253725228!2d72.533203!3d22.995865!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe7df12e87ae2a481!2sPalansoft%20(India)!5e0!3m2!1sen!2som!4v1651564519803!5m2!1sen!2som'
                className='contact-frame'
              ></iframe>

              <div className='row'>
                <div className='address-item'>
                  <div className='address-icon'>
                    <FaMapMarkerAlt />
                  </div>
                  <div className='address-text'>
                    <h3 className='contact-title'>India</h3>
                    <ul className='unorderList'>
                      <li>S-84 Al Muqaam, Vishala</li>
                      <li>Ahmedabad, Gujarat. India</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='address-item'>
                  <div className='address-icon'>
                    <FaRegEnvelope />
                  </div>
                  <div className='address-text'>
                    <h3 className='contact-title'>Mail Us</h3>
                    <ul className='unorderList'>
                      <li>
                        <a href='#'>support[at]palansoft.com</a>
                      </li>
                      <li>
                        <a href='#'>sales[at]palansoft.com</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='address-item '>
                  <div className='address-icon'>
                    <FaPhoneAlt />
                  </div>
                  <div className='address-text'>
                    <h3 className='contact-title'>Call Us</h3>
                    <ul className='unorderList'>
                      <li>
                        <a href='tel:00917043179522'>(91) 70431 79522</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className='p-2 flex-fill bd-highlight contact-iframe-con-out'>
              <iframe title='Map Location Oman'
                src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7312.597074221184!2d58.426615000000005!3d23.593624!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1090ee5b81ebeef2!2sModern%20Digital%20World%20LLC!5e0!3m2!1sen!2sus!4v1651564338079!5m2!1sen!2sus'
                className='contact-frame'
              ></iframe>

              <div className='row'>
                <div className='address-item'>
                  <div className='address-icon'>
                    <FaMapMarkerAlt />
                  </div>
                  <div className='address-text'>
                    <h3 className='contact-title'>Oman</h3>
                    <ul className='unorderList'>
                      <li>Al-Khuwair, Muscat.</li>
                      <li>Sultanate of Oman.</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='address-item'>
                  <div className='address-icon'>
                    <FaRegEnvelope />
                  </div>
                  <div className='address-text'>
                    <h3 className='contact-title'>Mail Us</h3>
                    <ul className='unorderList'>
                      <li>
                        <a href='#'>support[at]palansoft.com</a>
                      </li>
                      <li>
                        <a href='#'>sales[at]palansoft.com</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='address-item '>
                  <div className='address-icon'>
                    <FaPhoneAlt />
                  </div>
                  <div className='address-text'>
                    <h3 className='contact-title'>Call Us</h3>
                    <ul className='unorderList'>
                      <li>
                        <a href='tel:0096895175929'>(968) 9517 5929</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <p>
          <Link className='btn btn-danger' to='/'>
            Back To Home
          </Link>
        </p> */}
        </div>
      </div>
    </>
  )
}
export default Contact