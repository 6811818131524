import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'

import AxiosClient from '../api/axiosClient'
import { FaSpinner } from 'react-icons/fa'
import demoimg from '../assets/book-banner/Book_Banner_Science-6_Eng.jpg'

import {useParams} from "react-router-dom"

function BookChapterDetail({props }) {
  //const name = props.title;
  const {title} = useParams()
  const [loading, setLoading] = useState(false)
  const [chapterinfo, setChapterinfo] = useState([])
  const [chapterinfodetail, setChapterinfodetail] = useState([])
  const [apiError, setApiError] = useState(null)
  const [pdfUrl, setPdfUrl] = useState()
  const location = useLocation();

  const navigate = useNavigate();

  useEffect (() => {
    //console.log('dddd', location.state.bookchapinfo);
    setChapterinfodetail(location.state.bookchapinfo)
    setPdfUrl(location.state.bookchapinfo)
  },[])

  async function getBookinfo() {
    var sendData = {
      GradeCode: 'GRD-06',
      BoardCode: 'BRD-01',
      SubjectCode: 'SUB-001',
    }
    await AxiosClient({
      method: 'post',
      url: '/master/chapterBoardList',
      //url: APIURLUpdate,
      data: sendData,
    })
      .then((response) => {
        setLoading(false)
        if (response.data.status) {
          setChapterinfo(response.data.data)
          //console.log('book detail ', response.data)
        } 
        else if (response.data.status == false){
          //console.log('Activity ', response.data)
          setApiError( response.data.message)
        }
          else {
            setApiError(response.data.message)
            //console.log(response.data.message)
        }
      })
      .catch(function (error) {
        //console.log('Activity ', error)
        setApiError(error)
      })
  }

  useEffect(() => {
    getBookinfo()
    //console.log('book detail page', chapterinfo)
  }, [])
  

  return (
    <>
      <div className='container min-height'>

        {loading ? (
          <>
            <div className='col-12'>
              <div className='divider-4'></div>
              <FaSpinner className='loader' />
            </div>
          </>
        ) : (
          <>
            <div className='booklisting-main-con'>
              <div className='booklisting-mainbook-con'>
                <img
                  src={demoimg}
                  alt='Loading...'
                  style={{ width: 'auto', margin: 'auto', display: 'block' }}
                />
              </div>
              <h2 className=' '>{chapterinfodetail.ChapterName}</h2>               
              <div className='divider-3'></div>
              <nav aria-label='breadcrumb'>
                <ol onClick={() => navigate(-1)} className='breadcrumb'>
                  <li className='breadcrumb-item'>{chapterinfodetail.BoardCode} </li>
                  <li className='breadcrumb-item'> Grade-6</li>
                  <li className='breadcrumb-item'> English</li>
                  <li className='breadcrumb-item active' aria-current='page'>
                    Science
                  </li>
                  
                </ol>
              </nav>
              <div className='divider-1'></div>              
            </div>
            
            <div className='divider-4'></div>
            
             <embed
              src={chapterinfodetail.PDF}
              type="application/pdf"
              height={800}
              width={1200}
            />
          </>
        )}
      </div>
    </>
  )
}

export default BookChapterDetail
