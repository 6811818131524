import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'

import AxiosClient from '../api/axiosClient'
import { FaSpinner } from 'react-icons/fa'
import demoimg from '../assets/book-banner/Book_Banner_Science-6_Eng.jpg'

import {useParams} from "react-router-dom"

function BookSolution({props }) {
  //const name = props.title;
  const {title} = useParams()
  const [loading, setLoading] = useState(false)
  const [chapterinfo, setChapterinfo] = useState([])
  const [chapterinfodetail, setChapterinfodetail] = useState([])
  const [apiError, setApiError] = useState(null)
  const [pdfUrl, setPdfUrl] = useState()
  const location = useLocation();

  const navigate = useNavigate();
  const teacherbookinfo = JSON.parse(localStorage.getItem('filterbook'))
  const WebImgUrl = 'http://www.atakat.com/Palansoft'
  // useEffect(() => {
  //   if (!localStorage.getItem('data')) {
  //     navigate('/signin')
  //   }
  // }, [])

  useEffect (() => {
    if (!localStorage.getItem('filterbook')) {      
      navigate('/signin')
    } 
    //console.log('dddd', location.state.bookchapinfo);
    setChapterinfodetail(location.state.bookchapinfo)
    setPdfUrl(location.state.bookchapinfo)
  },[])



  useEffect(() => {
    //getBookinfo()
    //console.log('book detail page', chapterinfo)
  }, [])
  

  return (
    <>
      <div className='container min-height'>

        {loading ? (
          <>
            <div className='col-12'>
              <div className='divider-4'></div>
              <FaSpinner className='loader' />
            </div>
          </>
        ) : (
          <>
            <div className='booklisting-main-con'>
              {/* <div className='booklisting-mainbook-con'>
                <img
                  src={demoimg}
                  alt='Loading...'
                  style={{ width: 'auto', margin: 'auto', display: 'block' }}
                />
              </div> */}
              <h2 className=' '>{chapterinfodetail.ChapterName}</h2>               
              <div className='divider-2'></div>
              <nav aria-label='breadcrumb'>
                <ol onClick={() => navigate(-1)} className='breadcrumb'>                                    
                  {/* <li className='breadcrumb-item'>{chapterinfodetail.BoardCode} </li> */}
                  <li className='breadcrumb-item'>{teacherbookinfo.Board}</li>
                  <li className='breadcrumb-item'>{teacherbookinfo.GradeCode}</li>
                  <li className='breadcrumb-item'>{teacherbookinfo.Language}</li>
                  <li className='breadcrumb-item active' aria-current='page'>
                  {teacherbookinfo.Subject}
                  </li>
                 
                </ol>
              </nav>
              <div className='divider-1'></div>              
            </div>
            
            <div className='divider-3'></div>            
            <embed className='webview-con'  src={chapterinfodetail.Solution}   type="application/pdf" />                         
          </>
        )}
      </div>
    </>
  )
}

export default BookSolution
