import React, { useState, useEffect } from 'react'
import { useCallback } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import AxiosClient from '../api/axiosClient'

function FilterMenu() {
  const navigate = useNavigate()
  const [Language, setLanguage] = useState('')
  const [Grade, setGrade] = useState('')
  const [Subject, setSubject] = useState('')
  const [Category, setCategory] = useState('')
  const [Chapter, setChapter] = useState('')
  const [UID, setUID] = useState('')

  
  const [LanguageList, setLanguageList] = useState([])
  const [Gradelist, setGradelist] = useState([])
  const [SubjectList, setSubjectList] = useState([])
  const [CategoryList, setCategoryList] = useState([])
  const [ChapterList, setChapterList] = useState([])

  useEffect(() => {
    fetchLanguageData()
    fetchGradeData()
    fetchSubjectData()
    fetchCategoryData()
  }, [])

  async function fetchLanguageData() {
    const request = await AxiosClient({
      method: 'get',
      url: '/master/Language',
    }).then((response) => {
      if (response.data.status) {
        setLanguageList(response.data.data)
      } else {
        setLanguageList([])
      }
    })
  }

  async function fetchGradeData() {
    const request = await AxiosClient({
      method: 'get',
      url: '/master/grade',
    }).then((response) => {
      if (response.data.status) {
        setGradelist(response.data.data)
      } else {
        setGradelist([])
      }
    })
  }

  async function fetchSubjectData() {
    const request = await AxiosClient({
      method: 'get',
      url: '/master/Subject',
    }).then((response) => {
      if (response.data.status) {
        setSubjectList(response.data.data)
      } else {
        setSubjectList([])
      }
    })
  }

  async function fetchCategoryData() {
    const request = await AxiosClient({
      method: 'get',
      url: '/master/Category',
    }).then((response) => {
      if (response.data.status) {
        setCategoryList(response.data.data)
      } else {
        setCategoryList([])
      }
    })
  }

  const FindChapter = async () => {
    var sendData = {
      page: 1,
      Keywords: '',
      GradeCode: Grade,
      LanguageCode: Language,
      SubjectCode: Subject,
      CategoryCode: Category,
    }
    //console.log('all filter', sendData)
    await AxiosClient({
      method: 'post',
      url: '/master/chapterFromParams',
      data: sendData,
    }).then((response) => {
      //console.log(response)
      if (response.data.status) {
        //console.log(response.data.data)
        setChapterList(response.data.data)
      } else {
        setChapterList([])
      }
    })
  }

  useEffect(() => {
    FindChapter()
  }, [Language, Grade, Subject, Category])

  const LanguageSelect = useCallback(
    (e) => {
      setLanguage(e.target.value)
    },
    [Language]
  )
  const GradeSelect = useCallback(
    (e) => {
      setGrade(e.target.value)
    },
    [Grade]
  )
  const SubjectSelect = useCallback(
    (e) => {
      setSubject(e.target.value)
    },
    [Subject]
  )
  const CategorySelect = useCallback(
    (e) => {
      setCategory(e.target.value)
    },
    [Category]
  )
  const ChapterSelect = useCallback(
    (e) => {
      setChapter(e.target.value)
    },
    [Chapter]
  )

  return (
    <div className='filtermenu-con-main'>
      <form>
        <div className='col-12 option-box'>
          <div className='input-group has-validation'>
            <select
              defaultValue={'Language'}
              className='form-select'
              aria-label='Select language'
              onChange={LanguageSelect}
            >
              <option value='Language' disabled>
                Select Language
              </option>

              {LanguageList.map((item, index) => (
                <option key={index} value={item.Code}>
                  {item.Language}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className='col-12 option-box'>
          <div className='input-group has-validation'>
            <select
              defaultValue={'Grade'}
              className='form-select'
              aria-label='Select Grade'
              onChange={GradeSelect}
            >
              <option value='Grade' disabled>
                Select Grade
              </option>
              {Gradelist.map((item, index) => (
                <option key={index} value={item.Code}>
                  {item.Grade}
                </option>
              ))}
              {/* <option value='One'>One</option>
                <option value='Two'>Two</option>
                <option value='Three'>Three</option> */}
            </select>
          </div>
        </div>
        <div className='col-12 option-box'>
          <div className='input-group has-validation'>
            <select
              defaultValue={'Subject'}
              className='form-select'
              aria-label='Select Subject'
              onChange={SubjectSelect}
            >
              <option value='Subject' disabled>
                Select Subject
              </option>

              {SubjectList.map((item, index) => (
                <option key={index} value={item.Code}>
                  {item.Subject}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className='col-12 option-box'>
          <div className='input-group has-validation'>
            <select
              defaultValue={'Category'}
              className='form-select'
              aria-label='Select Category'
              onChange={CategorySelect}
            >
              <option value='Category' disabled>
                Select Category
              </option>

              {CategoryList.map((item, index) => (
                <option key={index} value={item.Code}>
                  {item.Category}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className='col-12 option-box'>
          <div className='input-group has-validation'>
            <select
              defaultValue={'Chapter'}
              className='form-select'
              aria-label='Select Chapter'
              onChange={ChapterSelect}
            >
              <option value='Chapter' disabled>
                Select Chapter
              </option>

              {ChapterList.map((item, index) => (
                <option key={index} value={item.Code}>
                  {item.ChapterName}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className='col-12'>
          <button
            onClick={FindChapter}
            className='btn btn-primary d-grid gap-2 col-12'
            type='button'
          >
            Show Chapter
          </button>
        </div>
      </form>
    </div>
  )
}

export default FilterMenu
