import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import demoimg from '../assets/book-banner/Book_Banner_Science-6_Eng.jpg'
import AxiosClient from '../api/axiosClient'
import { FaSpinner } from 'react-icons/fa'

function BookDetail() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [bookinfo, setBookinfo] = useState([])  
  const [apiError, setApiError] = useState(null)
  
  const teacherbookinfo = JSON.parse(localStorage.getItem('filterbook'))
  const WebImgUrl = 'http://www.atakat.com/Palansoft';
  
  useEffect(async () => {      
      const mainData = await JSON.parse(localStorage.getItem('filterbook'))      
  })
  useEffect(() => {
    if (!localStorage.getItem('filterbook')) {      
      navigate('/signin')
    } 
  }, [])

  const getBookinfo = async (mainData) => {
  //async function getBookinfo(mainData) {
    var sendData = {
      GradeCode: teacherbookinfo != null ? teacherbookinfo.GradeCode : mainData.GradeCode,
      BoardCode: teacherbookinfo != null ? teacherbookinfo.BoardCode : mainData.BoardCode,
      SubjectCode: teacherbookinfo != null ? teacherbookinfo.SubjectCode : mainData.SubjectCode,
    }
    //console.log('book detail page data', sendData)
    await AxiosClient({
      method: 'post',
      url: '/master/chapterBoardList',      
      data: sendData,
    })
      .then((response) => {
       
        if (response.data.status) {
          setBookinfo(response.data.data)
          //console.log('book detail ', response.data)
          setLoading(false)
          setApiError(null)
        } 
        else if (response.data.status == false){
          //console.log('Activity ', response.data)
          //setApiError( response.data.message)
        }
          else {
            setApiError(response.data.message)
            //console.log(response.data.message)
        }
      })
      .catch(function (error) {
        //console.log('Activity ', error)
        setApiError(error)
      })
  }

  useEffect(() => {
    getBookinfo()
    //console.log('book detail page', bookinfo)     
  }, [])

  return (
    <div className='container min-height'>
      <div className='booklisting-main-con'>
        <div className='booklisting-mainbook-con'>
          {loading ? (
            <>
              <div className='col-12'>
                <div className='divider-2'></div>
                <FaSpinner className='loader' />
              </div>
            </>
          ) : (
          <>   
          {teacherbookinfo && <img              
            src={WebImgUrl + teacherbookinfo.img?.substring(1)}
            alt='Loading...'
            style={{ width: 'auto', margin: 'auto', display: 'block' }}
          /> }
          </>
          )}
        </div>        
        {teacherbookinfo !== null ? 
        <nav aria-label='breadcrumb'>
          <ol onClick={() => navigate(-1)} className='breadcrumb'>
            <li className='breadcrumb-item'>{teacherbookinfo.Board}</li>
            <li className='breadcrumb-item'>{teacherbookinfo.GradeCode}</li>
            <li className='breadcrumb-item'>{teacherbookinfo.Language}</li>
            <li className='breadcrumb-item active' aria-current='page'>
            {teacherbookinfo.Subject}
            </li>            
          </ol>
        </nav>
        : null        
        }

        <div className='divider-2'></div>
        {apiError ? (
            <>            
            <div className='divider-2'></div>              
            <h3 className='text-yellow'>{apiError}</h3>
          </>
          ) : (
            null
          )}
        <ul className='book-chapterlist-con'>
        {loading ? <FaSpinner className='loader' /> : ''}
          {bookinfo.map((bookitem, index) => (            
            <li key={index}>
           <Link  //to={`/book-chapter-detail/${item.title.replace(/ /g, "-")}`}
            to='/book-chapter' state={{bookitem}} >                    
              {bookitem.ChapterName}
              </Link>
            </li> 
          ))}
        </ul>
      </div>
      
      <div className='divider-4'></div>
    </div>
  )
}

export default BookDetail
