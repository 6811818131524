import React, { useState,  useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {  FaUserAlt,FaSpinner,  FaRegCalendarAlt,FaEye,  FaEyeSlash} from 'react-icons/fa'
import demoimg from '../assets/users/1.png'
import AxiosClient from '../api/axiosClient'
import moment from 'moment'

function AddUser() {
  const navigate = useNavigate()

  const [Gender, setGender] = useState('')
  const [Name, setName] = useState('')
  const [CountryCode, setCountryCode] = useState('91')
  const [Mobile, setMobile] = useState('')
  const [DOB, setDOB] = useState('')
  const [Address, setAddress] = useState('')
  const [City, setCity] = useState('')
  const [DeviceToken, setDeviceToken] = useState('Deviceweb')
  const [IPAddress, setIPAddress] = useState('')
  const [MobileOS, setMobileOS] = useState('OSweb')
  const [Mpin, setMpin] = useState('')
  const [Grade, setGrade] = useState('')
  const [Language, setLanguage] = useState('')
  const [AllowAccess, setAllowAccess] = useState('')
  const [loginresult, setLoginresult] = useState('')
  const [UID, setUID] = useState('')
  const [apiStatus, setApiStatus] = useState('')

  
  const [gradelist, setGradelist] = useState([])
  const [languageList, setLanguageList] = useState([])
  const [userinfo, setUserinfo] = useState(null)
  const [loading, setLoading] = useState(false)
  
  
  let date = moment(new Date()).format('MM/DD/YYYY')

  useEffect(async () => {
    const userdata = await JSON.parse(localStorage.getItem('userinfo'))
    //console.log('user data is',userdata)
    setUserinfo(userdata)
    setUID(setUserinfo.UID)
  }, [])

   useEffect(() => {
     if (localStorage.getItem(userinfo )) {
       navigate('/signin')
     }
   }, [])
  
  //Get Grade Data
  useEffect(() => {
    
    async function fetchData() {
        
          const request = await AxiosClient({
            method: 'get',
            url: '/master/grade',
          })
            .then((response) => {
              if (response.data.status) {
                localStorage.setItem(
                  'AllGradelist',
                  JSON.stringify(response.data)
                )
                setGradelist(response.data.data)
                //console.log(response.data.data)
              } else {
              }
            })
            .catch(function (error) {
              console
                .log
                // 'There has been a problem with your fetch operation: ',
                //console.log('this is a', error.message),
                //console.log(error.message)
                ()
              setApiStatus('Network error, Please try again after some time')
            })
         
    }
    fetchData()
  }, [])
  // End Get Grade Data

  //Get Subject list Data
  useEffect(() => {
    async function fetchData() {
      const request = await AxiosClient({
        method: 'get',
        url: '/master/Language',
      })
        .then((response) => {
          if (response.data.status) {
            localStorage.setItem(
              'AlllanguageList',
              JSON.stringify(response.data)
            )
            setLanguageList(response.data.data)
            //console.log(response.data.data)
          } else {
          }
        })
        .catch(function (error) {
          console
            .log
            // 'There has been a problem with your fetch operation: ',
            //console.log('this is a', error.message),
            //console.log(error.message)
            ()
          setApiStatus('Network error, Please try again after some time')
        })
    }
    fetchData()
  }, [])
  // End Get Subject list Data

  // Add User Function
  const registernewuser = (data) => {
    setLoading(true)
    // let objGrade = null,
    //   objLanguage = null
    // try {
    //   objGrade = gradelist.find((obj) => obj.Code == Grade)
    //   objLanguage = languageList.find((obj) => obj.Code == Language)
    // } catch (error) {
    //   //console.log(error)
    // }
    var sendData = {
      UID: userinfo.UID,
      Name: formValues.FullName,
      Grade: formValues.Grade,
      GradeCode: formValues.Grade,
      Language: formValues.Language,
      LanguageCode: formValues.Language,
      DOB: formValues.DOB,
      PIN: formValues.Mpin,
      //AllowActivityCurrentGrade: true,
      //AllowActivityLowerGrade: false,
      //AllowAll: false,
      Gender: formValues.Gender,
      AllowAccess: formValues.AllowAccess,
    }
    //console.log(sendData)
    AxiosClient.post('/user/addUser', sendData)
      .then((response) => {
        if (response.data.status) {
          localStorage.setItem(
            'userroleadded',
            JSON.stringify(response.data.data)
          )
          setLoginresult(response.data)
          //loginresult(response.data)
          //console.log('add student response', response.data)
          navigate('/userlisting')
          setLoading(true)
          //window.location.reload(false)
          //setTimeout(() => {}, 2000)
        } else {
          setLoginresult(response.data)
          //loginresult(response.data)
          setLoading(false)
        }
      })
  }

  // Toggle Passowrd Text
  const togglePassword = () => {
    setPasswordShown(!passwordShown)
  }

  // Form validation new

  const [FullName, setFullName] = useState('')
  const [passwordShown, setPasswordShown] = useState(false)
  // New validation
  const initialValues = {
    FullName: '',
    DOB: '',
    Gender: '',
    Grade: '',    
    Language: '',
    AllowAccess: '',
    Mpin: '',
  }
  const [formValues, setFormValues] = useState(initialValues)
  const [formErrors, setFormErrors] = useState({})
  const [isSubmit, setIsSubmit] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
    //console.log('Form value is', formValues)
  }

  //  New validation function
  const addUserSubmit = (e) => {
    //e.preventDefault()
    setFormErrors(validate(formValues))
    setIsSubmit(true)
  }

  useEffect(() => {
    //console.log(formErrors)
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      //console.log('New User info',formValues)
      registernewuser()
    }
  }, [formErrors])
  const validate = (values) => {

    const FormValErrors = {}
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i
    const regexm = /^[0-9]*$/
    
    if (!values.FullName) {
      FormValErrors.FullName = 'Full Name is required!'
    }
    if (!values.DOB) {
      FormValErrors.DOB = 'Date of birth is required!'
    }
    if (!values.Gender) {
      FormValErrors.Gender = 'Please select Gender.'
    }
    if (!values.Grade) {
      FormValErrors.Grade = 'Please select grade.'
    }
    if (!values.Language) {
      FormValErrors.Language = 'Please select language.'
    }
    if (!values.AllowAccess) {
      FormValErrors.AllowAccess = 'Please select allowAccess.'
    }
    if (!values.Mpin) {
      FormValErrors.Mpin = 'Valid Mpin is required!'
    } else if (!regexm.test(values.Mpin)) {
      FormValErrors.Mpin = 'Mpin should be only numeric!'
    } else if (values.Mpin.length < 6) {
      FormValErrors.Mpin = 'Mpin must 6 characters'
    } else if (values.Mpin.length > 6) {
      FormValErrors.Mpin = 'Mpin must 6 characters'
    }
    // if (!values.Mpin) {
    //   FormValErrors.Mpin = 'Mpin is required!'
    // } else if (!regexm.test(values.Mpin)) {
    //   FormValErrors.Mpin = 'Mpin should be only numeric.'
    // } else if (values.Mpin.length < 6) {
    //   FormValErrors.Mpin = 'Mpin min 6 characters'
    // } else if (values.Mpin.length > 6) {
    //   FormValErrors.Mpin = 'Mpin max 6 characters'
    // }

    // if (!values.Address) {
    //   FormValErrors.Address = 'Address is required!'
    // } else if (values.Address.length < 10) {
    //   FormValErrors.Address = 'Address must be more than 10 characters'
    // } else if (values.Address.length > 70) {
    //   FormValErrors.Address = 'Address cannot exceed more than 70 characters'
    // }


    return FormValErrors
  }
  return (
    <>
      <div className='form-main-con-1'>
        <form>
          {loading ? <FaSpinner className='loader' /> : ''}

          <h2 className='page-title-1 text-left'>
            Enter details to create user
          </h2>
          <h2 className='page-title-1 text-left'>{loginresult.message}</h2>
          <div className='col-12'>
            <p>Enter your credential to continue</p>
          </div>
          {/* <div className='col-12'>
            <div className='input-group flex-nowrap'>
              <img width={100} src={demoimg} />
            </div>
          </div> */}
          <div className='col-12'>
            <div className='input-group has-validation'>
              <span className='input-group-text' id='FullName'>
                <FaUserAlt />
              </span>
              <input
                type='text'
                className='form-control'
                id='FullName'
                name='FullName'
                placeholder='Full Name'
                value={formValues.FullName}
                onChange={handleChange}
              />
              <div className='invalid-feedback form-error'>
                {formErrors.FullName}
              </div>
            </div>
          </div>
          <div className='col-12'>
            <div className='input-group has-validation'>
              <span className='input-group-text' id='Name'>
                <FaRegCalendarAlt />
              </span>
              <input
                type='date'
                className='form-control'
                id='DOB'
                required
                min='1950-01-01'
                max='2021-12-31'
                name='DOB'
                value={formValues.DOB}
                onChange={handleChange}
                format={date}
                placeholder={date}
                onLoad={(e) => date()}
                //onChange={(e) => setDOB(e.target.value)}
              />
              <div className='invalid-feedback form-error'>
                {formErrors.DOB}
              </div>
            </div>
          </div>
          <div className='col-12'>
            <h2 className='inline page-sub-title-1 text-left'>Gender</h2>
            <div className='form-check form-check-inline'>
              <input
                className='form-check-input'
                type='radio'
                id='Male'
                //onChange={(e) => setGender(e.target.value)}
                name='Gender'
                value='Male'
                onChange={handleChange}
              />
              <label className='form-check-label' htmlFor='Male'>
                Male
              </label>
            </div>
            <div className='form-check form-check-inline'>
              <input
                className='form-check-input'
                type='radio'
                id='Female'
                name='Gender'
                value='Female'
                onChange={handleChange}
              />
              <label className='form-check-label' htmlFor='Female'>
                Female
              </label>
            </div>
            <div className='invalid-feedback form-error'>
              {formErrors.Gender}
            </div>
          </div>

          <div className='col-12'>
            <div className='input-group has-validation'>
              <select
                //defaultValue={Grade}
                className='form-select'
                name='Grade'
                //value={formValues.Grade}
                onChange={handleChange}
                //onChange={(e) => setGrade(e.target.value)}
              >
                <option value=''>Select Grade</option>
                {gradelist.map((item, index) => (
                  <option key={index} value={item.Grade}>
                    {item.Grade}
                  </option>
                ))}
                {/* <option value='One'>One</option>
                <option value='Two'>Two</option>
                <option value='Three'>Three</option> */}
              </select>
              <div className='invalid-feedback form-error'>
                {formErrors.Grade}
              </div>
            </div>
          </div>
          <div className='col-12'>
            <div className='input-group has-validation'>
              <select
                //defaultValue={Language}
                className='form-select'
                name='Language'
                //value={formValues.Language}
                onChange={handleChange}
                //onChange={(e) => setLanguage(e.target.value)}
              >
                <option value=''>Select Language</option>

                {languageList.map((item, index) => (
                  <option key={index} value={item.Language}>
                    {item.Language}
                  </option>
                ))}
              </select>
              <div className='invalid-feedback form-error'>
                {formErrors.Language}
              </div>
            </div>
          </div>
          <div className='col-12'>
            <h2 className='inline page-sub-title-1 text-left'>Allow Access</h2>
            <div className='form-check'>
              <input
                className='form-check-input'
                type='radio'
                id='AllowCurrentGrade'
                //value='1'
                //onChange={(e) => setAllowAccess(e.target.value)}
                name='AllowAccess'
                value='1'
                onChange={handleChange}
              />
              <label className='form-check-label' htmlFor='AllowCurrentGrade'>
                Allow Activity Current Grade
              </label>
            </div>
            <div className='form-check form-check-inline'>
              <input
                className='form-check-input'
                type='radio'
                id='AllowLowerGrade'
                //value='2'
                //onChange={(e) => setAllowAccess(e.target.value)}
                name='AllowAccess'
                value='2'
                onChange={handleChange}
              />
              <label className='form-check-label' htmlFor='AllowLowerGrade'>
                Allow Activity Lower Grade
              </label>
            </div>
            <div className='form-check form-check-inline'>
              <input
                className='form-check-input'
                type='radio'
                id='AllowAllGrade'
                //value='3'
                //onChange={(e) => setAllowAccess(e.target.value)}
                name='AllowAccess'
                value='3'
                onChange={handleChange}
              />
              <label className='form-check-label' htmlFor='AllowAllGrade'>
                Allow All
              </label>
            </div>
            <div className='invalid-feedback form-error'>
              {formErrors.AllowAccess}
            </div>
          </div>
          <div className='col-12'>
            <div className='input-group has-validation'>
              <input
                type={passwordShown ? 'text' : 'password'}
                className='form-control'
                placeholder='Mpin'
                aria-label='Mpin'
                id='Mpin'
                min='1'
                max='4'
                //onChange={(e) => setMpin(e.target.value)}
                name='Mpin'
                value={formValues.Mpin}
                onChange={handleChange}
              />
              <span
                className='input-group-text'
                id='basic-addon2'
                onClick={togglePassword}
              >
                {passwordShown ? (
                  <>
                    <FaEye />
                  </>
                ) : (
                  <>
                    <FaEyeSlash />
                  </>
                )}
              </span>
              <div className='invalid-feedback form-error'>
                {formErrors.Mpin}
              </div>
            </div>
          </div>
          <div className='col-12'>
            <button
              onClick={addUserSubmit}
              className='btn btn-primary d-grid gap-2 col-12'
              type='button'
            >
              Add User
            </button>
          </div>
        </form>
        {/* <div className='col-12'>
          <div className='divider-2'></div>
          <p className='text-white  text-center'>{loginresult}</p>
          <p className='text-center text-white'>
            <Link
              className='btn btn-primary text-white text-center'
              to='/userlisting'
            >
              View Users
            </Link>
          </p>
        </div> */}
      </div>
    </>
  )
}

export default AddUser
