import React, { useState, useEffect, useCallback, Select } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FaSpinner} from 'react-icons/fa'
import AxiosClient from '../api/axiosClient'
import Demoimg from '../assets/demoimg.jpg'
function ActivityPoster() {
  const navigate = useNavigate()
  const [mainData, setMainData] = useState(null)
  const [data, setData] = useState(null)
  const [activity, setActivity] = useState([])
  const [loading, setLoading] = useState(false)
  const [pageNo, setPageNo] = useState(1)
  const [showmorebtn, setShowmorebtn] = useState(true)
  // Popup box function
  const [open, setOpen] = useState(true)
  const [popupcontent, setPopupcontent] = useState([])
  const [activityBookListing, setActivityBookListing] = useState([])
  const [popupopentoggle, setPopupopentoggle] = useState(false)
  const [LanguageSelect, setLanguageSelect] = useState(null)
  const [LanguageEng, setLanguageEng] = useState('')
  const [LanguageGuj, setLanguageGuj] = useState('')
  const [LanguageMaths, setLanguageMaths] = useState('')
  const [LanguageHindi, setLanguageHindi] = useState('')
  const [search, setSearch] = useState('')
  

  let WebImgUrl = 'http://www.atakat.com/Palansoft'
  //var actImgUrl = activityBookListing.img
  //var NewactImgUrl = actImgUrl.replace('~', 'https://www.atakat.com/Palansoft')
  
  useEffect(async () => {
    const mainData = await JSON.parse(localStorage.getItem('userinfo'))
    //const data = await JSON.parse(localStorage.getItem('userUID'))
    setActivity(mainData)
    //setData(data)
    //fetchData(data.UID, data.UserUID, data.LanguageCode, data.Language)
  }, [search])

  // Get Activity Poster from API
    useEffect(() => {
      async function fetchData() {
        const request = await AxiosClient({
          method: 'get',
          url: '/activity/bookListing',
        }).then((response) => {
          if (response.data.status) {
            setActivityBookListing(response.data.data)
            //console.log('Book', response.data.data)
          } else {
          }
        })          
      }
      fetchData()
    }, [])
  
  //https://www.atakat.com/Palansoft/Images/Thumbnail/33b7815eSmall.jpg
  
  function filterviewactivity(item) {
    //localStorage.setItem('LanguageSelected', JSON.stringify('English'))
    localStorage.setItem('filterviewactivity', JSON.stringify(item))
    navigate('/')
    window.location.reload()
  }
  
  function chapterdetail(item) {
    setLoading(true)
    localStorage.setItem('filterbook', JSON.stringify(item))
    setTimeout(() => {
      setLoading(false)
      navigate('/book-detail')
    },4000)    
  }

  return (
    <>
      <div className='row'>        
        {/* <h1 style={{ opacity: '0' }}>Trending Activity</h1> */}
        {loading ? <FaSpinner className='loader' /> : null }
        <div className='movie-row-inn-con'>
          {activityBookListing.map((item, index) => (
            <div
              //id='LanguageEng'
              //key='indexEnglish'
              className='row movie-img'
              //onClick={() => filterviewactivity(item)}
              onClick={() => chapterdetail(item)}
              id={item.id}
              key={index}
              
              //onClick={() => viewactivity(item)}
            >
              
              {/* <img
                className=''
                alt={item.Language}
                title={item.Name}
                src={WebImgUrl  + item.img.substring(1)}
              /> */}
              <img src={item.img ? WebImgUrl  + item.img.substring(1) : Demoimg } 
                alt={item.Language}
                title={item.Name}
              />
            </div>
          ))}
          {/* {movies.map((movie) => (
          <img
            className={`movie-img ${isLargeRow && 'movie-img-large'}`}
            key={movie.id}
            alt={movie.name}
            title={movie.name}
            src={`${base_url}${
              isLargeRow ? movie.poster_path : movie.backdrop_path
            }`}
          />
        ))} */}
         
        </div>
      </div>
    </>
  )
}

export default ActivityPoster
