import { Link } from 'react-router-dom'
import Metatags from '../components/shared/Metatags'
function PageNotFound() {
  return (
    <>
      <Metatags
        pagetitle='Palansoft Interactive Learning - Team'
      />
      <div className='container min-height text-center'>
      
         
          <div className='divider-5'></div>
          <h1>Ohh... Something went wrong?</h1>
          <p className='text-white'>
            Sorry, we can't find that page. You'll find lots to explore on the
            home page.
          </p>
          <div className='divider-5'></div>
          <div className='container text-center'>
            <Link className='btn btn-sm btn-start btn-danger' to='/'>
              Explore More...
            </Link>
           
        
      </div></div>
    </>
  )
}

export default PageNotFound
