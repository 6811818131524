import React, { useState, useEffect, useCallback } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import {motion} from 'framer-motion'
import AxiosClient from '../api/axiosClient'

import demoimg from '../assets/book-banner/Book_Banner_Science-6_Eng.jpg'
import { Collapse } from 'react-bootstrap'
import {useParams} from "react-router-dom"
import {
    FaEye,
    FaChevronRight,
    FaComment,
    FaShoppingCart,
    FaSpinner,
    FaLanguage,
    FaRupeeSign,
    FaFilter,
  } from 'react-icons/fa'

function BookActivities({props }) {
  //const name = props.title;
  const {title} = useParams()
  const [loading, setLoading] = useState(false)
  const [chapterinfo, setChapterinfo] = useState([])
  const [chapterinfodetail, setChapterinfodetail] = useState([])
  const [apiError, setApiError] = useState(null)
  const [pdfUrl, setPdfUrl] = useState()
  
  const [mainData, setMainData] = useState(null)
  const [data, setData] = useState(null)
  const [activity, setActivity] = useState([])
  const [pageNo, setPageNo] = useState(1)
  const [showmorebtn, setShowmorebtn] = useState(true)
  // Popup box function
  const [open, setOpen] = useState(true)
  const [popupcontent, setPopupcontent] = useState([])
  const [ActivityViewCount, setActivityViewCount] = useState([])
  const [popupopentoggle, setPopupopentoggle] = useState(false)
  const [LanguageSelect, setLanguageSelect] = useState('')
  const [search, setSearch] = useState(null)
  const [UserUidVal, setUserUidVal] = useState('')
  const [APIURLUpdate, setAPIURLUpdate] = useState('')
  const [videoNewUrl, setVideoNewUrl] = useState('')
  const [bookchapinfo, setBookchapinfo] = useState([])
  const [bookchapinfono, setBookchapinfono] = useState()

  const location = useLocation();
  const navigate = useNavigate();
  
  const teacherbookinfo = JSON.parse(localStorage.getItem('filterbook'))

   
  useEffect (() => {
    if (!localStorage.getItem('filterbook')) {      
      navigate('/signin')
    } 
    setBookchapinfo(location.state.bookchapinfo)   
  },[])
  //Activity function
  const [UserScoreStart, setUserScoreStart] = useState('')

  const NewUserUidVal = JSON.parse(localStorage.getItem('selectCurrentUserUID'))

  useEffect(async () => {
    const mainData = await JSON.parse(localStorage.getItem('userinfo'))
    const data = await JSON.parse(localStorage.getItem('selectCurrentUserUID'))
    const Langdata = await JSON.parse(localStorage.getItem('filterviewactivity'))
    const activitylistdata = await JSON.parse(localStorage.getItem('activitylist'))
    //console.log('Langdata', Langdata)
    setMainData(mainData)
    setData(data)
    //setUserUidVal(data.UserUID)
    fetchData(data, Langdata, mainData)
    // activityviewcount(
    //   data.UID,
    //   data.UserUID,
    //   data.LanguageCode,
    //   data.Language,
    //   Langdata
    // )
  }, [search])

  async function fetchData(data, Langdata, mainData) {
    var sendData = {
      UID: NewUserUidVal? NewUserUidVal.UID : mainData.UID,
      UserUID: NewUserUidVal ? NewUserUidVal.UserUID : '0',
      LanguageCode: teacherbookinfo ? teacherbookinfo.LanguageCode : Langdata.LanguageCode,           
      //SubjectCode: "SUB-001",
      ChapterCode: location.state.bookchapinfo.Code,
      //ChapterCode: 'CHP-0001',
      //GradeCode: Langdata ? Langdata.GradeCode : 'GRD-06',
      GradeCode: teacherbookinfo ? teacherbookinfo.GradeCode : Langdata.GradeCode,
      SubjectCode: teacherbookinfo ? teacherbookinfo.SubjectCode : Langdata.SubjectCode,
      page: pageNo,
      Keywords: search,
    }
    //console.log('Book activity', sendData)
    await AxiosClient({
      method: 'post',
      url: '/activity/activityFromParams',
      //url: APIURLUpdate,
      data: sendData,
    })
      .then((response) => {
        setLoading(false)
        if (response.data.status) {
          //console.log('activity ok', response.data)
          localStorage.setItem('activitylist', JSON.stringify(response.data))
          pageNo == 1
            ? setActivity(response.data.data)
            : setActivity([...activity, ...response.data.data])
          setPageNo((prevValue) => prevValue + 1)
        } else {
          setShowmorebtn(false)
        }
      })
      .catch(function (error) {
        //console.log('Activity ',error)
      })
  }
  
 // View Count Function
 async function activityviewcount(value) {
    
    var sendData = {
      ActivityCode: value,
      UserUID: UserUidVal,
    }
    await AxiosClient({
      method: 'post',
      url: '/activity/viewActivityInsert',
      data: sendData,
    }).then((response) => {
      if (response.data.status) {
        //console.log('View is ',response.data.data)
        //setActivityViewCount((prevValue) => prevValue + 1) 
        setUserScoreStart(response.data.data)
        setVideoNewUrl('?uniqid=' + response.data.data)
      } else {
        //console.log('View is ', response.data.status)
      }
    })
  }

  // Activity Launch Popup
  const changepopup = (item) => {    
    //console.log('popup', UserScoreStart)
    //setVideoNewUrl('?uniqid=' + UserScoreStart)
    setTimeout(() => {
      setPopupcontent([item])
      //setVideoNewUrl("?userid="+NewUserUidVal.UserUID+"&activity="+item.Code)
      setPopupopentoggle(!popupopentoggle)      
      setLoading(true)
    },)
  }
  const showMoreItems = async () => {
    setLoading(true)
    const Langdata = await JSON.parse(
      localStorage.getItem('filterviewactivity')
    )
    fetchData(data, Langdata)
  }

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement('script')
      script.src = src
      script.onload = () => {
        resolve(true)
      }
      script.onerror = () => {
        resolve(false)
      }
      document.body.appendChild(script)
    })
  }

  async function placeOrder(item) {
    if (data != null) {
      var sendData = {
        amount: item.Price * 100,
        notes: {
          key1: item.Code,
          key2: data.UID,
        },
      }
      const res = await loadScript(
        'https://checkout.razorpay.com/v1/checkout.js'
      )
      if (!res) {
        alert('Netwark error, Try after some time.')
        return
      }
      const response = await AxiosClient({
        method: 'post',
        url: '/payment/orderPayment',
        data: sendData,
      })
      if (response.data.status) {
        var options = {
          name: 'Palansoft Interactive',
          description: `Activity ${item.Activity}`,
          image: '',
          currency: response.data.data.currency,
          amount: response.data.data.amount,
          key: 'rzp_live_wdc8RsAWzwLlE4',
          order_id: response.data.data.id,
          handler: function (handlerRes) {
            sendServerData(
              mainData,
              item,
              'success',
              response.data.data.amount,
              response.data.data.receipt,
              handlerRes
            )
          },
          receipt: response.data.data.receipt,
          prefill: {
            email: mainData.Email,
            contact: mainData.Mobile,
            name: mainData.Name,
          },
          theme: { color: '#2196f3' },
        }
        var rzp1 = new window.Razorpay(options)
        rzp1.open()

        rzp1.on('payment.failed', function (failRes) {
          var errorData = {
            razorpay_order_id: '',
            razorpay_payment_id: '',
            razorpay_signature: '',
          }
          sendServerData(
            mainData,
            item,
            'failure',
            response.data.data.amount,
            response.data.data.receipt,
            errorData
          )
        })
      } else {
        alert('oh No Something not right')
        //console.log('Razor', response.data)
      }
    }
  }

  async function sendServerData(
    loginData,
    item,
    status,
    amount,
    receipt,
    razorpayData
  ) {
    var sendData = {
      UID: loginData.UID,
      ActivityCode: item.Code,
      ActivityName: item.Activity,
      Description: item.Description,
      Grade: item.Grade,
      Language: item.Language,
      Subject: item.Subject,
      Category: item.Category,
      Chapter: item.Chapter,
      Status: status,
      razorpay_price: amount / 100,
      razorpay_receipt: receipt,
      razorpay_order_id: razorpayData.razorpay_order_id,
      razorpay_payment_id: razorpayData.razorpay_payment_id,
      razorpay_signature: razorpayData.razorpay_signature,
      ProceedFrom: 'web',
      Name: loginData.Name,
      Email: loginData.Email,
      Address: loginData.Address,
      City: loginData.City,
    }
    //console.log(sendData)
    const response = await AxiosClient({
      method: 'post',
      url: '/payment/capturePayment',
      data: sendData,
    })
    if (response.data.status) {
      item.Purchase += 1
      item.Status = 'purchased'
      const updatedData = activity.map((x) => (x.Code === item.Code ? item : x))
      setActivity(updatedData)
    } else {
      alert(response.data.message)
    }
  }

  const refreshPage = () => {
    window.location.reload()
  }

  const searchMethods = useCallback(
    (e) => {
      window.localStorage.removeItem('filterviewactivity')
      setSearch(e.target.value)
      setShowmorebtn(true)
      setPageNo(1)
    },
    [search]
  )
  // Start Search filter
  const [showSearchFilter, setShowSearchFilter] = useState(true)
  const toggleSearchFilter = () => {
    setShowSearchFilter(!showSearchFilter)
  }

  return (
    <>
    <div className='homepage-main-con'>
    <div className='container min-height'>
        <div className='home-inn-con-1'>   
          <div className='booklisting-main-con'>          
              <h2 className=' '>{location.state.bookchapinfo?.ChapterName}</h2>               
              <div className='divider-0'></div>
              <nav aria-label='breadcrumb'>
                <ol onClick={() => navigate(-1)} className='breadcrumb'>
                  <li className='breadcrumb-item'>{teacherbookinfo.Board}</li>
                  <li className='breadcrumb-item'> {teacherbookinfo.GradeCode}</li>
                  <li className='breadcrumb-item'> {teacherbookinfo.Language}</li>
                  <li className='breadcrumb-item active' aria-current='page'>{teacherbookinfo.Subject}</li>                 
                </ol>
              </nav>                        
            </div>     
      </div>
      <div className='divider-2'></div>
      <div className=''>
      <div className='scrollableDiv'>      
        <div className='user-active-out-con'>
          {activity ? (
            <>
              {activity
                

                .map((item, index) => (
                  <motion.div
                    layout
                    key={index}
                    className='card act-box-main-con'
                  >
                    <span className='con-data act-img-con'>
                      <span
                        className={`con-data act-price ${
                          item.Price > 0 && 'paid'
                        }`}
                      >
                        <FaRupeeSign className='fa icon-small' />
                        {item.Price}
                      </span>
                      <img
                        className='card-img-top act-img'
                        //src={item.ThumbnailBig}
                        src={item.ThumbnailSmall}
                        alt={item.Activity}
                      />
                    </span>
                    <div className='card-body act-info-con'>
                      <span className='con-data act-category text-blue'>
                        {item.Category}
                        <FaChevronRight className='fa' />
                        {item.Subject}
                      </span>
                      <span className='card-title con-data act-name'>
                        {item.Activity}
                      </span>
                      <span className='con-data act-chapter'>
                        {item.Chapter}
                      </span>
                      <span className='con-data act-grade text-text-gray-1 '>
                        Grade : {item.Grade}
                        <span className='con-data act-lang'>
                          <FaLanguage className='fa icon-big' />
                          {item.Language}
                        </span>
                      </span>

                      <span className='con-data act-rat-icon-con act-clicks'>
                        <FaEye className='fa icon-big' />
                        <span className='val-con'>{item.Views}</span> Total
                        views
                      </span>
                      <span className='con-data act-rat-icon-con act-reviews'>
                        <FaEye className='fa icon-big' />
                        <span className='val-con'>{item.OwnViews}</span> Own
                        views
                      </span>
                      <span className='con-data act-rat-icon-con act-purchase-count'>
                        <FaShoppingCart className='fa icon-big' />
                        <span className='val-con'>{item.Purchase}</span> Total
                        purchases
                      </span>

                      {item.Status === 'buy' ? (
                        <span className={`con-data act-buy-btn-con`}>
                          <Link
                            to='/'
                            onClick={() => placeOrder(item)}
                            className='btn btn-warning text-white'
                          >
                            Buy
                          </Link>
                        </span>
                      ) : (
                        <span className={`con-data act-buy-btn-con`}>
                          <Link
                            to='/'
                            className='btn btn-danger text-white'
                            //onClick={() => changepopup(item); activityviewcount(item)}
                            onClick={(event) => {
                              //changepopup(item) || userScoreStart(item.Code) || activityviewcount(item.Code)
                              changepopup(item) || activityviewcount(item.Code)
                            }}
                          >
                            Launch
                          </Link>
                        </span>
                      )}
                    </div>
                  </motion.div>
                ))}
            </>
          ) : (
            ''
          )}
        </div>
        <div className='divider-2'></div>
        {loading ? (
          <div className='container text-center mt-5'>
            <FaSpinner className='loader' />
          </div>
        ) : null}
        {showmorebtn ? (
          <>
            <div className='container text-center mt-5'>
              {/* <button
                className='btn btn-danger'
                onClick={showMoreItems}                
              >
                Load More
              </button> */}
            </div>
          </>
        ) : (
          <>
            {loading ? (
              ''
            ) : (
              <div className='container text-center mt-5'>
                <span className='btn btn-secondary text-white disabled'>
                  That's all, for now, more activities are coming soon.
                </span>
              </div>
            )}
          </>
        )}
      </div>

      {popupopentoggle && (
        <div className='popup-outer-con' onClick={(e) => e.stopPropagation()}>
          
              <div className='popup-header' onClick={changepopup}>
                x
              </div>
              <div className='popup-body'>
                {popupcontent.map((popupinfo) => {
                  return (
                    <div>
                      <div key={popupinfo.id} className='viewdetailinfobox-con'>
                        <a
                          className='btn btn-danger'
                          onClick={() => setOpen(!open)}
                          aria-controls='viewdetailinfobox'
                          aria-expanded={open}
                        >
                          Detail
                        </a>
                        <Collapse in={open}>
                          <div id='viewdetailinfobox'>
                            <span>Chapter: {popupinfo.Chapter}</span>
                            <span>Activity: {popupinfo.Activity}</span>
                            <span>Grade: {popupinfo.Grade}</span>
                            <span>Language: {popupinfo.Language}</span>
                            <span>Subject: {popupinfo.Subject}</span>
                            
                          </div>
                        </Collapse>
                      </div>
                      <div className='iframe-box-outer-con'>
                        <div className='iframe-box-inn-con'>
                          <iframe
                            className='iframe-responsive'
                            src={popupinfo.URL + videoNewUrl}
                            //src={popupinfo.URL + '?uniqid=' + UserScoreStart}
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className='popup-footer'></div>
           
        </div>
      )}
      </div>
      </div>
    </div>
    <div className='divider-8'></div>
    </>
  )
}

export default BookActivities
