import { Link } from 'react-router-dom'
import appstore from '../assets/app-icon-appstore.png'
import playstore from '../assets/app-icon-playstore.png'
import { FaTwitter, FaFacebookF, FaYoutube, FaInstagram, FaLinkedin } from 'react-icons/fa'
import PalansoftSupportIcon from '../assets/app-icon-palansoft-support.png'
function Footer({ text, bgColor, textColor }) {
  const headerStyles = {
    backgroundColor: bgColor,
    color: textColor,
  }

  return (
    <>
      <div className='footer-main-con'>
        <div className='container'>
          <footer className='py-5'>
            <div className='row'>
              <div className='col-6 col-lg-3 footer-colum'>
                <h5 className='footer-title'>Compnay</h5>
                <ul className='nav flex-column'>
                  <li className='nav-item mb-2'>
                    <a href='/' className=''>
                      Home
                    </a>
                  </li>
                  <li className='nav-item mb-2'>
                    <a href='/about' className=''>
                      About
                    </a>
                  </li>
                  <li className='nav-item mb-2'>
                    <a href='/contact' className=''>
                      Contact
                    </a>
                  </li>
                  <li className='nav-item mb-2'>
                    <a href='/team' className=''>
                      Team
                    </a>
                  </li>
                </ul>
              </div>
              <div className='col-6 col-lg-3 footer-colum'>
                <h5 className='footer-title'>Account</h5>
                <ul className='nav flex-column'>
                  <li className='nav-item mb-2'>
                    <a href='/signin' className=''>
                      Signin
                    </a>
                  </li>
                  <li className='nav-item mb-2'>
                    <a href='/signup' className=''>
                      SignUp
                    </a>
                  </li>
                  <li className='nav-item mb-2'>
                    <a href='/careers' className=''>
                      Careers
                    </a>
                  </li>
                  <li className='nav-item mb-2'>
                    <a href='/schools' className=''>
                      Schools
                    </a>
                  </li>
                </ul>
              </div>

              <div className='col-6 col-lg-3 footer-colum'>
                <h5 className='footer-title'>Support</h5>
                <ul className='nav flex-column'>
                  <li className='nav-item mb-2'>
                    <a href='/faqs' className=''>
                      FAQs
                    </a>
                  </li>
                  <li className='nav-item mb-2'>
                    <a href='/privacy' className=''>
                      Privacy
                    </a>
                  </li>
                  <li className='nav-item mb-2'>
                    <a href='/terms' className=''>
                      Terms
                    </a>
                  </li>
                  <li className='nav-item mb-2'>
                    <a href='/refund' className=''>
                      Refund
                    </a>
                  </li>
                </ul>
              </div>

              <div className='col-6 col-lg-3 footer-colum'>
                <form>
                  <h5 className='footer-title'>Download app from</h5>
                  <a
                    href='https://play.google.com/store/apps/details?id=com.palansoftinteractive'
                    target='_blank'
                  >
                    <img
                      src={playstore}
                      alt='Download from Playstore'
                      style={{
                        width: '160px',
                        height: 'auto',
                        margin: '20px 0px auto auto',
                        display: 'block',
                        float: 'left',
                        clear: 'both',
                      }}
                    />
                  </a>
                  <a href='https://www.apple.com/in/app-store/' target='_blank'>
                    <img
                      src={appstore}
                      alt='Download on Appstore'
                      style={{
                        width: '160px',
                        height: 'auto',
                        margin: '10px 0px auto auto',
                        display: 'block',
                        float: 'left',
                      }}
                    />
                  </a>
                </form>
              </div>
            </div>

            <div className='d-flex justify-content-between py-4 my-4 border-top'>
              <p>
                © 2023 Palansoft Interactive Learning, Inc. All rights reserved.
              </p>
              <ul className='list-unstyled d-flex'>
                <li className='ms-3'>
                  <a
                    className='link-dark'
                    target='_blank'
                    href='https://www.instagram.com/palansoft/'
                  >
                    <FaInstagram />
                  </a>
                </li>
                <li className='ms-3'>
                  <a
                    className='link-dark'
                    target='_blank'
                    href='https://twitter.com/palansoft'
                  >
                    <FaTwitter />
                  </a>
                </li>
                <li className='ms-3'>
                  <a
                    className='link-dark'
                    target='_blank'
                    href='https://www.facebook.com/Palansoft'
                  >
                    <FaFacebookF />
                  </a>
                </li>
                <li className='ms-3'>
                  <a
                    className='link-dark'
                    target='_blank'
                    href='https://www.youtube.com/channel/UCZSJU5tA-mpcmt4b-Ht6Owg'
                  >
                    <FaYoutube />
                  </a>
                </li>
                <li className='ms-3'>
                  <a
                    className='link-dark'
                    target='_blank'
                    href='https://www.linkedin.com/company/interactives/'
                  >
                    <FaLinkedin />
                  </a>
                </li>                
              </ul>
            </div>
          </footer>
        </div>
      </div>
      <div className='quick-support-side-con'>
        <a
          title='Try our Support Portal'
          href='https://support.palansoft.com/'
          target='_blank'
        >
          <img
            className='applogo icon-palan'
            src={PalansoftSupportIcon}
            alt='Palansoft Support'
          />
        </a>
        <a
          title='Get it on Google Play'
          href='https://play.google.com/store/apps/details?id=com.palansoftinteractive'
          target='_blank'
        >
          <img
            className='applogo icon-palan'
            src={playstore}
            alt='Download from Playstore'
          />
        </a>
        <a
          title='Available on the App Store'
          href='https://www.apple.com/in/app-store/'
          target='_blank'
        >
          <img
            className='applogo icon-palan'
            src={appstore}
            alt='Download on Appstore'
          />
        </a>
      </div>
    </>
  )
}

export default Footer
