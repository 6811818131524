import React, {
  useState,
  useEffect,
  useCallback,
  Select,
  Component,
} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Collapse } from 'react-bootstrap'
import {
  FaEye,
  FaChevronRight,
  FaComment,
  FaShoppingCart,
  FaSpinner,
  FaLanguage,
  FaRupeeSign,
  FaFilter,
} from 'react-icons/fa'
import { VscSettings } from 'react-icons/vsc'
import AxiosClient from '../api/axiosClient'
//import InfiniteScroll from 'react-infinite-scroll-component'
import FilterMenu from './FilterMenu'
//import Logo from '../assets/logo.png'
//Axios.defaults.withCredentials = true
import {motion} from 'framer-motion'

function ActivityRaw() {
  const navigate = useNavigate()
  const [mainData, setMainData] = useState(null)
  const [data, setData] = useState(null)
  const [activity, setActivity] = useState([])
  const [loading, setLoading] = useState(true)
  const [pageNo, setPageNo] = useState(1)
  const [showmorebtn, setShowmorebtn] = useState(true)
  // Popup box function
  const [open, setOpen] = useState(true)
  const [popupcontent, setPopupcontent] = useState([])
  const [ActivityViewCount, setActivityViewCount] = useState([])
  const [popupopentoggle, setPopupopentoggle] = useState(false)
  const [LanguageSelect, setLanguageSelect] = useState('')
  const [search, setSearch] = useState('')
  const [UserUidVal, setUserUidVal] = useState('')
  const [APIURLUpdate, setAPIURLUpdate] = useState('')
  const [videoNewUrl, setVideoNewUrl] = useState('')

  const [UserScoreStart, setUserScoreStart] = useState('')

  const NewUserUidVal = JSON.parse(localStorage.getItem('selectCurrentUserUID'))

  useEffect(async () => {
    const mainData = await JSON.parse(localStorage.getItem('userinfo'))
    const data = await JSON.parse(localStorage.getItem('selectCurrentUserUID'))
    const Langdata = await JSON.parse(localStorage.getItem('filterviewactivity'))
    const activitylistdata = await JSON.parse(localStorage.getItem('activitylist'))
    //console.log('Langdata', Langdata)
    setMainData(mainData)
    setData(data)
    //setUserUidVal(data.UserUID)
    fetchData(data, Langdata)
    // activityviewcount(
    //   data.UID,
    //   data.UserUID,
    //   data.LanguageCode,
    //   data.Language,
    //   Langdata
    // )
  }, [search])

  useEffect(() => {
    if (localStorage.getItem('data')) {
      navigate('/UserListing')
    }
  }, [])

  async function fetchData(data, Langdata) {
    var sendData = {
      UID: NewUserUidVal? NewUserUidVal.UID : '0',
      UserUID: NewUserUidVal ? NewUserUidVal.UserUID : '0',
      LanguageCode: Langdata ? Langdata.LanguageCode : '',
      //Language: value3,
      //Language: laneng,
      //LanguageCode: lancode,
      GradeCode: Langdata ? Langdata.GradeCode : 'GRD-06',
      SubjectCode: Langdata ? Langdata.SubjectCode : '',
      page: pageNo,
      Keywords: search,
    }
    //console.log('languagecode', sendData)
    await AxiosClient({
      method: 'post',
      url: '/activity/activityFromParams',
      //url: APIURLUpdate,
      data: sendData,
    })
      .then((response) => {
        setLoading(false)
        if (response.data.status) {
          //console.log('activity ok', response.data)
          localStorage.setItem('activitylist', JSON.stringify(response.data))
          pageNo == 1
            ? setActivity(response.data.data)
            : setActivity([...activity, ...response.data.data])
          setPageNo((prevValue) => prevValue + 1)
        } else {
          setShowmorebtn(false)
        }
      })
      .catch(function (error) {
        //console.log('Activity ',error)
      })
  }

  // Start Filter button function'
  useEffect(async () => {
    //setActivity(null)
    //fetchDataFree()
    //console.log('Filter active 1', activity)
  }, [APIURLUpdate])

  //console.log('user uid', NewUserUidVal)
  // async function fetchDataFree(Langdata) {
  //   //setActivity(null)
  //   var sendData = {
  //     UID: NewUserUidVal.UID,
  //     UserUID: NewUserUidVal.UserUID,
  //     //UID: 'f2e4f268-f4bb-4c72-80e1-50c8f6300c9e',
  //     //UserUID: '179c60cb-7db6-4850-8c45-7760523f0199',
  //     GradeCode: Langdata ? Langdata.GradeCode : '',
  //     SubjectCode: Langdata ? Langdata.SubjectCode : '',
  //     Keywords: search,
  //     page: 1,
  //   }

  //   //console.log('Filter button send data is', activity)
  //   await AxiosClient({
  //     method: 'post',
  //     url: APIURLUpdate,
  //     data: sendData,
  //   }).then((response) => {
  //     setLoading(true)

  //     setShowmorebtn(false)
  //     if (response.data.status) {
  //       //console.log('Filter success activity', response.data)
  //       //localStorage.setItem('activitylist', JSON.stringify(response.data))
  //       //setSearch([...activity, ...response.data.data])

  //       //setActivity([...response.data.data])
  //       //setPageNo((prevValue) => prevValue + 1)

  //       setTimeout(() => {
  //         //setActivity(response.data.data)
  //         //setFilteredActivity(response.data.data)
  //         setLoading(false)
  //        //  pageNo == 1
  //          //  ? setActivity(response.data.data)
  //            //: setActivity([...response.data.data])
  //         //pageNo == 1 ? setActivity(response.data.data) : setActivity()
  //         //console.log('Filter act 1')
  //       },1000)

  //       //setPageNoFilter((prevValue) => prevValue + 1)
  //       //window.location.reload()
  //     } else {
  //       //setActivity(null)
  //       setShowmorebtn(false)
  //       setLoading(false)
  //       //console.log('Filter act 2')
  //     }
  //   })
  // }

  // View Count Function
  async function activityviewcount(value) {
    
    var sendData = {
      ActivityCode: value,
      UserUID: UserUidVal,
    }
    await AxiosClient({
      method: 'post',
      url: '/activity/viewActivityInsert',
      data: sendData,
    }).then((response) => {
      if (response.data.status) {
        //console.log('View is ',response.data.data)
        //setActivityViewCount((prevValue) => prevValue + 1) 
        setUserScoreStart(response.data.data)
        setVideoNewUrl('?uniqid=' + response.data.data)
      } else {
        //console.log('View is ', response.data.status)
      }
    })
  }

  // Activity Launch Popup
  const changepopup = (item) => {    
    //console.log('popup', UserScoreStart)
    //setVideoNewUrl('?uniqid=' + UserScoreStart)
    setTimeout(() => {
      setPopupcontent([item])
      //setVideoNewUrl("?userid="+NewUserUidVal.UserUID+"&activity="+item.Code)
      setPopupopentoggle(!popupopentoggle)      
      setLoading(true)
    },)
  }
  const showMoreItems = async () => {
    setLoading(true)
    const Langdata = await JSON.parse(
      localStorage.getItem('filterviewactivity')
    )
    fetchData(data, Langdata)
  }

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement('script')
      script.src = src
      script.onload = () => {
        resolve(true)
      }
      script.onerror = () => {
        resolve(false)
      }
      document.body.appendChild(script)
    })
  }

  async function placeOrder(item) {
    if (data != null) {
      var sendData = {
        amount: item.Price * 100,
        notes: {
          key1: item.Code,
          key2: data.UID,
        },
      }
      const res = await loadScript(
        'https://checkout.razorpay.com/v1/checkout.js'
      )
      if (!res) {
        alert('Netwark error, Try after some time.')
        return
      }
      const response = await AxiosClient({
        method: 'post',
        url: '/payment/orderPayment',
        data: sendData,
      })
      if (response.data.status) {
        var options = {
          name: 'Palansoft Interactive',
          description: `Activity ${item.Activity}`,
          image: '',
          currency: response.data.data.currency,
          amount: response.data.data.amount,
          key: 'rzp_live_wdc8RsAWzwLlE4',
          order_id: response.data.data.id,
          handler: function (handlerRes) {
            sendServerData(
              mainData,
              item,
              'success',
              response.data.data.amount,
              response.data.data.receipt,
              handlerRes
            )
          },
          receipt: response.data.data.receipt,
          prefill: {
            email: mainData.Email,
            contact: mainData.Mobile,
            name: mainData.Name,
          },
          theme: { color: '#2196f3' },
        }
        var rzp1 = new window.Razorpay(options)
        rzp1.open()

        rzp1.on('payment.failed', function (failRes) {
          var errorData = {
            razorpay_order_id: '',
            razorpay_payment_id: '',
            razorpay_signature: '',
          }
          sendServerData(
            mainData,
            item,
            'failure',
            response.data.data.amount,
            response.data.data.receipt,
            errorData
          )
        })
      } else {
        alert('oh No Something not right')
        //console.log('Razor', response.data)
      }
    }
  }

  async function sendServerData(
    loginData,
    item,
    status,
    amount,
    receipt,
    razorpayData
  ) {
    var sendData = {
      UID: loginData.UID,
      ActivityCode: item.Code,
      ActivityName: item.Activity,
      Description: item.Description,
      Grade: item.Grade,
      Language: item.Language,
      Subject: item.Subject,
      Category: item.Category,
      Chapter: item.Chapter,
      Status: status,
      razorpay_price: amount / 100,
      razorpay_receipt: receipt,
      razorpay_order_id: razorpayData.razorpay_order_id,
      razorpay_payment_id: razorpayData.razorpay_payment_id,
      razorpay_signature: razorpayData.razorpay_signature,
      ProceedFrom: 'web',
      Name: loginData.Name,
      Email: loginData.Email,
      Address: loginData.Address,
      City: loginData.City,
    }
    //console.log(sendData)
    const response = await AxiosClient({
      method: 'post',
      url: '/payment/capturePayment',
      data: sendData,
    })
    if (response.data.status) {
      item.Purchase += 1
      item.Status = 'purchased'
      const updatedData = activity.map((x) => (x.Code === item.Code ? item : x))
      setActivity(updatedData)
    } else {
      alert(response.data.message)
    }
  }

  const refreshPage = () => {
    window.location.reload()
  }

  const searchMethods = useCallback(
    (e) => {
      window.localStorage.removeItem('filterviewactivity')
      setSearch(e.target.value)
      setShowmorebtn(true)
      setPageNo(1)
    },
    [search]
  )
  // Start Search filter
  const [showSearchFilter, setShowSearchFilter] = useState(true)
  const toggleSearchFilter = () => {
    setShowSearchFilter(!showSearchFilter)
  }

  return (
    <>
      <div className='row'>
        <div className='divider-1'></div>
        <h2 className='page-title-1 text-left'>User Activity</h2>
        <div className='search-main-con-1'>
          <div key='searchbar' id='searchbar' className='search-con-main'>
            <input
              className='searchfield'
              type='text'
              id='searchfield'
              placeholder='Search activity...'
              value={search}
              // onChange={(event) => { setSearchterm(event.target.value) }}
              onChange={searchMethods}
            />
            {/* <VscSettings
              onClick={() =>
                toggleSearchFilter(
                  showSearchFilter === 'fasle' ? 'true' : 'false'
                )
              }
              className='fa icon-big text-white search-filter-button'
            /> */}
            <button
              onClick={() =>
                setAPIURLUpdate('/activity/activityFromParams') ||
                window.location.reload() ||
                window.localStorage.removeItem('filterviewactivity')
              }
              className='btn nobtn btn-red search-filter-button'
            >
              View All
            </button>
          </div>

          <div
            className={
              showSearchFilter
                ? 'search-filter-options show'
                : 'search-filter-options hide'
            }
          >
            <div
              className={
                showSearchFilter ? 'filter-active show' : 'filter-active hide'
              }
            >
              {/* {APIURLUpdate} */}
              <button
                onClick={() =>
                  //setAPIURLUpdate('/activity/activityFromParams') ||
                  //fetchDataFree()
                  //window.location.reload()
                  window.localStorage.removeItem('filterviewactivity') ||
                  refreshPage()
                }
                className='btn nobtn btn-red'
              >
                All
              </button>
              <button
                onClick={
                  () => setAPIURLUpdate('/activity/freeActivityList')
                  //setActivity(null) ||
                  //fetchDataFree()
                }
                className='btn nobtn btn-pink'
              >
                Free
              </button>
              <button
                onClick={
                  () => setAPIURLUpdate('/activity/gradeActivityList')
                  //setActivity(null) ||
                  //fetchDataFree()
                }
                className='btn nobtn btn-blue'
              >
                Grade
              </button>
              <button
                onClick={
                  () => setAPIURLUpdate('/activity/purchasedActivityList')
                  //setActivity(null) ||
                  //fetchDataFree()
                }
                className='btn nobtn btn-brown'
              >
                Purchased
              </button>
              <button
                onClick={
                  () => setAPIURLUpdate('/activity/markedActivityList')
                  //setActivity(null) ||
                  // fetchDataFree()
                }
                className='btn nobtn btn-green'
              >
                Marked
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <FilterMenu /> */}
      {/* <Select
        options={options}
        onChange={handleFilterInput}
        value={selectedValue}
        placeholder='Select a region'
      /> */}
      {showSearchFilter ? '' : ''}

      <div className='scrollableDiv'>
        {/* <InfiniteScroll
          dataLength={activity.length}
          next={showMoreItems}
          hasMore={true}
          //loader={<h4>Loading...</h4>}
          scrollableTarget='scrollableDiv'
        >
        </InfiniteScroll> */}
        <div className='user-active-out-con'>
          {activity ? (
            <>
              {activity
                // .filter((item) => {
                //   if (APIURLUpdate == '') {
                //     return item
                //   }
                //   {
                //     return item
                //   }
                // })

                .map((item, index) => (
                  <motion.div
                    layout
                    key={index}
                    className='card act-box-main-con'
                  >
                    <span className='con-data act-img-con'>
                      <span
                        className={`con-data act-price ${
                          item.Price > 0 && 'paid'
                        }`}
                      >
                        <FaRupeeSign className='fa icon-small' />
                        {item.Price}
                      </span>
                      <img
                        className='card-img-top act-img'
                        //src={item.ThumbnailBig}
                        src={item.ThumbnailSmall}
                        alt={item.Activity}
                      />
                    </span>
                    <div className='card-body act-info-con'>
                      <span className='con-data act-category text-blue'>
                        {item.Category}
                        <FaChevronRight className='fa' />
                        {item.Subject}
                      </span>
                      <span className='card-title con-data act-name'>
                        {item.Activity}
                      </span>
                      <span className='con-data act-chapter'>
                        {item.Chapter}
                      </span>
                      <span className='con-data act-grade text-text-gray-1 '>
                        Grade : {item.Grade}
                        <span className='con-data act-lang'>
                          <FaLanguage className='fa icon-big' />
                          {item.Language}
                        </span>
                      </span>

                      <span className='con-data act-rat-icon-con act-clicks'>
                        <FaEye className='fa icon-big' />
                        <span className='val-con'>{item.Views}</span> Total
                        views
                      </span>
                      <span className='con-data act-rat-icon-con act-reviews'>
                        <FaEye className='fa icon-big' />
                        <span className='val-con'>{item.OwnViews}</span> Own
                        views
                      </span>
                      <span className='con-data act-rat-icon-con act-purchase-count'>
                        <FaShoppingCart className='fa icon-big' />
                        <span className='val-con'>{item.Purchase}</span> Total
                        purchases
                      </span>

                      {item.Status === 'buy' ? (
                        <span className={`con-data act-buy-btn-con`}>
                          <Link
                            to='/'
                            onClick={() => placeOrder(item)}
                            className='btn btn-warning text-white'
                          >
                            Buy
                          </Link>
                        </span>
                      ) : (
                        <span className={`con-data act-buy-btn-con`}>
                          <Link
                            to='/'
                            className='btn btn-danger text-white'
                            //onClick={() => changepopup(item); activityviewcount(item)}
                            onClick={(event) => {
                              //changepopup(item) || userScoreStart(item.Code) || activityviewcount(item.Code)
                              changepopup(item) || activityviewcount(item.Code)
                            }}
                          >
                            Launch
                          </Link>
                        </span>
                      )}
                    </div>
                  </motion.div>
                ))}
            </>
          ) : (
            ''
          )}
        </div>
        <div className='divider-2'></div>
        {loading ? (
          <div className='container text-center mt-5'>
            <FaSpinner className='loader' />
          </div>
        ) : null}
        {showmorebtn ? (
          <>
            <div className='container text-center mt-5'>
              <button
                className='btn btn-danger'
                onClick={showMoreItems}
                //onClick={() => setPageNo(pageNo + 1)}
                //onClick={() => alert( pageNo + 1)}
              >
                Load More
              </button>
            </div>
          </>
        ) : (
          <>
            {loading ? (
              ''
            ) : (
              <div className='container text-center mt-5'>
                <span className='btn btn-secondary text-white disabled'>
                  That's all, for now, more activities are coming soon.
                </span>
              </div>
            )}
          </>
        )}
      </div>

      {popupopentoggle && (
        <div className='popup-outer-con' onClick={(e) => e.stopPropagation()}>
          
              <div className='popup-header' onClick={changepopup}>
                x
              </div>
              <div className='popup-body'>
                {popupcontent.map((popupinfo) => {
                  return (
                    <div>
                      <div key={popupinfo.id} className='viewdetailinfobox-con'>
                        <a
                          className='btn btn-danger'
                          onClick={() => setOpen(!open)}
                          aria-controls='viewdetailinfobox'
                          aria-expanded={open}
                        >
                          Detail
                        </a>
                        <Collapse in={open}>
                          <div id='viewdetailinfobox'>
                            <span>Chapter: {popupinfo.Chapter}</span>
                            <span>Activity: {popupinfo.Activity}</span>
                            <span>Grade: {popupinfo.Grade}</span>
                            <span>Language: {popupinfo.Language}</span>
                            <span>Subject: {popupinfo.Subject}</span>
                            {/* {UserScoreStart} 
                            {popupinfo.URL + videoNewUrl}
                             URL= {popupinfo.URL+videoNewUrl}*/}
                            {/* 
                    <span>Subscription Type {popupinfo.SubscriptionType}</span>
                    <span>ID {popupinfo.id}</span>
                    <span>Code {popupinfo.Code}</span>
                    
                    <span>Price {popupinfo.Price}</span>
                    <span>Category {popupinfo.Category}</span>
                    <span>URL {popupinfo.URL}</span>
                    <span>Clicks {popupinfo.Clicks}</span>
                    <span>Ratings {popupinfo.Ratings}</span>
                    <span>Review {popupinfo.Review}</span>
                     <span>Status {popupinfo.Status}</span>
                    <span>IsMarked {popupinfo.IsMarked}</span>
                    <span>Purchase {popupinfo.Purchase}</span>
                    <span>ThumbnailBig {popupinfo.ThumbnailBig}</span>
                    <span>ThumbnailSmall {popupinfo.ThumbnailSmall}</span> */}
                          </div>
                        </Collapse>
                      </div>
                      <div className='iframe-box-outer-con'>
                        <div className='iframe-box-inn-con'>
                          <iframe
                            className='iframe-responsive'
                            src={popupinfo.URL + videoNewUrl}
                            //src={popupinfo.URL + '?uniqid=' + UserScoreStart}
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className='popup-footer'></div>
           
        </div>
      )}
    </>
  )
}

export default ActivityRaw
