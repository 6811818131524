import React, { useState, useEffect } from 'react'
import { useCallback } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import AxiosClient from '../api/axiosClient'

function FilterTeacher() {
  const [Language, setLanguage] = useState('')

  const [loading, setLoading] = useState(true)
  const [Subject, setSubject] = useState('')
  const [Category, setCategory] = useState('')
  const [Chapter, setChapter] = useState('')
  const [UID, setUID] = useState('')

  const navigate = useNavigate()
  const [LanguageList, setLanguageList] = useState([])
  const [Grade, setGrade] = useState('')
  const [Gradelist, setGradelist] = useState([])
  const [Gradeall, setGradeall] = useState([])
  const [Teachername, setTeachername] = useState('')
  const [Gradeselected,setGradeselected]= useState ([])
  const [SubjectList, setSubjectList] = useState([])
  const [CategoryList, setCategoryList] = useState([])
  const [ChapterList, setChapterList] = useState([])



  const teachernameold = JSON.parse(localStorage.getItem('teacherinfo'))
  useEffect(async () => {
    const userdata = typeof window !== 'undefined' ? await JSON.parse(localStorage.getItem('userinfo')) : null
    const teacherdata = typeof window !== 'undefined' ? await JSON.parse(localStorage.getItem('teacherinfo')) : null
    setTeachername(teacherdata)
    setGradeselected(userdata)
    setGradeall(userdata)
    fetchGradeData(teacherdata)
  }, [])

  useEffect(() => {
    const cleargrade = fetchGradeData()
    return () => {
        clearInterval(cleargrade)
    }
  }, [])

  const fetchGradeData = async (teacherdata) => {
    var sendData = {
      //GradeCode: Gradeall,
      UserName:teacherdata ? teacherdata : teachernameold, 
      //UserName:'bapu@gmail.com'
    }
    //console.log('Grade all filter', sendData)
    await AxiosClient({
      method: 'post',
      url: '/user/gradeListForTeacher',
      data: sendData,
    }).then((response) => {
      //console.log('chapter', response)
      //console.log('Grade filter resule', response.data)
      if (response.data.status) {
        //localStorage.setItem('teacherbookinfo', JSON.stringify(response.data.data))
        setGradelist(response.data.data)
        setGrade(response.data.data[0].Gradecode)
      } else {
        setGradelist([])
      }
    })
  }




  const FindChapter = async () => {
    setLoading (true)
    var sendData = {
      GradeCode: Grade ? Grade : Gradeall,
      UserName:Teachername
    }
    //console.log('Book all filter', sendData)
    await AxiosClient({
      method: 'post',
      //url: '/user/gradeListForTeacher',
      url: '/activity/bookListingFromGrade',
      data: sendData,
    }).then((response) => {
      //console.log('chapter', response)
      //console.log('Book filter resule', response.data.data)
      if (response.data.status) {
        localStorage.setItem('teacherbookinfo', JSON.stringify(response.data.data))
        setChapterList(response.data.data)
        //navigate('/')
      }      
      else {
        setChapterList([])
      }
    })
  }

  useEffect(() => {
    const clearfind =  FindChapter()
    return () => {
        clearInterval(clearfind)
    }
  }, [Language, Gradeall, Grade, Subject, Category])
  
  const GradeSelect = useCallback(
    (e) => {
      setGrade(e.target.value)
    },
    [Grade]
  )
  // const LanguageSelect = useCallback(
  //   (e) => {
  //     setLanguage(e.target.value)
  //   },
  //   [Language]
  // )
  // const SubjectSelect = useCallback(
  //   (e) => {
  //     setSubject(e.target.value)
  //   },
  //   [Subject]
  // )
  // const CategorySelect = useCallback(
  //   (e) => {
  //     setCategory(e.target.value)
  //   },
  //   [Category]
  // )
  // const ChapterSelect = useCallback(
  //   (e) => {
  //     setChapter(e.target.value)
  //   },
  //   [Chapter]
  // )

  return (
    <div className='filtermenu-con-main'>
      <form>
        {/* <div className='col-12 option-box'>
          <div className='input-group has-validation'>
            <select
              defaultValue={'Language'}
              className='form-select'
              aria-label='Select language'
              onChange={LanguageSelect}
            >
              <option value='Language' disabled>
                Select Language
              </option>

              {LanguageList.map((item, index) => (
                <option key={index} value={item.Code}>
                  {item.Language}
                </option>
              ))}
            </select>
          </div>
        </div> */}

        {Teachername ?
          <div className='col-12 option-box'>
            <div className='input-group has-validation'>
              <select
                defaultValue={Grade}
                className='form-select'
                aria-label='Select Grade'
                onChange={GradeSelect}
                //defaultValue={{ label: "GRD-06", value: "GRD-06" }}
              >
                 {/* <option value='Grade' disabled>
                  Select Grade
                </option>  */}
                {Gradelist.map((item, index) => (
                  <option key={index} value={item.Gradecode}>
                     {item.Gradecode}
                  </option>
                ))}
                {/* <option value='One'>One</option>
                <option value='Two'>Two</option>
                <option value='Three'>Three</option> */}
              </select>
            </div>
          </div>
          :
          <div className='col-12 option-box'>
          <div className='input-group has-validation'>
            <select
              defaultValue={'Grade'}
              className='form-select'
              aria-label='Select Grade'
              onChange={GradeSelect}
            >
              {/* <option value='Grade' disabled>
                Select Grade
              </option> */}
              {Gradelist.map((item, index) => (
                <option key={index} value={item.Grade}>
                  {item.Gradecode}
                </option>
              ))}
            </select>
          </div>
        </div>
        }
        {/* <div className='col-12 option-box'>
          <div className='input-group has-validation'>
            <select
              defaultValue={'Subject'}
              className='form-select'
              aria-label='Select Subject'
              onChange={SubjectSelect}
            >
              <option value='Subject' disabled>
                Select Subject
              </option>

              {SubjectList.map((item, index) => (
                <option key={index} value={item.Code}>
                  {item.Subject}
                </option>
              ))}
            </select>
          </div>
        </div> */}
        {/* <div className='col-12 option-box'>
          <div className='input-group has-validation'>
            <select
              defaultValue={'Category'}
              className='form-select'
              aria-label='Select Category'
              onChange={CategorySelect}
            >
              <option value='Category' disabled>
                Select Category
              </option>

              {CategoryList.map((item, index) => (
                <option key={index} value={item.Code}>
                  {item.Category}
                </option>
              ))}
            </select>
          </div>
        </div> */}

        {/* <div className='col-12 option-box'>
          <div className='input-group has-validation'>
            <select
              defaultValue={'Chapter'}
              className='form-select'
              aria-label='Select Chapter'
              onChange={ChapterSelect}
            >
              <option value='Chapter' disabled>
                Select Chapter
              </option>

              {ChapterList.map((item, index) => (
                <option key={index} value={item.Code}>
                  {item.ChapterName}
                </option>
              ))}
            </select>
          </div>
        </div> */}
        {/* <div className='col-12'>
          <button
            onClick={FindChapter}
            className='btn btn-primary d-grid gap-2 col-12'
            type='button'
          >
            Show Chapter
          </button>
        </div> */}
      </form>
    </div>
  )
}

export default FilterTeacher
