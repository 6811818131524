import { Link } from 'react-router-dom'


function PrivacyPage(props) {
  return (
    <div className='container min-height'>
      <div className='privay'>
        <h1>Privacy Policy</h1>
        <p>Last Updated: 28-May-2022</p>
        <p>
          This privacy notice for ("Company," "we," "us," or "our"), describes
          how and why we might collect, store, use, and/or share ("process")
          your information
        </p>
        <p>when you use our services ("Services"), such as when you:</p>
        <p>
          Questions or concerns? Reading this privacy notice will help you
          understand your privacy rights and choices. If you do not agree with
          our policies and practices, please do not use our Services. If you
          still have any questions or concerns, please contact us at.
        </p>
        <div className='divider-5'></div>
        <h3>SUMMARY OF KEY POINTS</h3>
        <p>
          This summary provides key points from our privacy notice, but you can
          find out more details about any of these topics by clicking the link
          following each key point or by using our table of contents below to
          find the section you are looking for. You can also click here to go
          directly to our table of contents.
        </p>
        <p>
          What personal information do we process? When you visit, use, or
          navigate our Services, we may process personal information depending
          on how you interact with and the Services, the choices you make, and
          the products and features you use. Click here to learn more.
        </p>
        <p>
          Do we process any sensitive personal information? We do not process
          sensitive personal information.
        </p>
        <p>
          Do we receive any information from third parties? We may receive
          information from public databases, marketing partners, social media
          platforms, and other outside sources. Click here to learn more.
        </p>
        <p>
          How do we process your information? We process your information to
          provide,improve, and administer our Services, communicate with you,
          for security and fraud prevention, and to comply with law. We may also
          process your information for other purposes with your consent. We
          process your information only when we have a valid legal reason to do
          so. Click here to learn more.
        </p>
        <p>
          In what situations and with which parties do we share personal
          information?
        </p>
        <p>
          We may share information in specific situations and with specific
          third parties. Click here to learn more.
        </p>
        <p>
          What are your rights? Depending on where you are located
          geographically, the applicable privacy law may mean you have certain
          rights regarding your personal information. Click here to learn more.
        </p>
        <p>
          How do I exercise my rights? The easiest way to exercise your rights
          is by filling out our data subject request form available here, or by
          contacting us. We willconsider and act upon any request in accordance
          with applicable data protection laws.
        </p>
        <p>
          Want to learn more about what does with any information we collect?
          Click here to review the notice in full.
        </p>
        <div className='divider-5'></div>
        <h3>TABLE OF CONTENTS</h3>
        <ol>
          <li>WHAT INFORMATION DO WE COLLECT?</li>
          <li>HOW DO WE PROCESS YOUR INFORMATION?</li>
          <li>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</li>
          <li>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</li>
          <li>HOW DO WE HANDLE YOUR SOCIAL LOGINS?</li>
          <li>IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</li>
          <li>HOW LONG DO WE KEEP YOUR INFORMATION?</li>
          <li>DO WE COLLECT INFORMATION FROM MINORS?</li>
          <li>WHAT ARE YOUR PRIVACY RIGHTS?</li>
          <li>CONTROLS FOR DO-NOT-TRACK FEATURES</li>
          <li>DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</li>
          <li>DO WE MAKE UPDATES TO THIS NOTICE?</li>
          <li>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
          <li>
            HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
          </li>
        </ol>
        <div className='divider-5'></div>
        <h5>1. WHAT INFORMATION DO WE COLLECT?</h5>
        <p>Personal information you disclose to us</p>
        <p>In Short: We collect personal information that you provide to us.</p>
        <p>
          We collect personal information that you voluntarily provide to us
          when you register on the Services, express an interest in obtaining
          information about us or our products and Services, when you
          participate in activities on the Services, or otherwise when you
          contact us.
        </p>
        <p>Sensitive Information. We do not process sensitive information.</p>
        <p>
          All personal information that you provide to us must be true,
          complete, and accurate, and you must notify us of any changes to such
          personal information.
        </p>
        <p>Information automatically collected</p>
        <p>
          In Short: Some information — such as your Internet Protocol (IP)
          address and/or browser and device characteristics — is collected
          automatically when you visit our Services.
        </p>
        <p>
          We automatically collect certain information when you visit, use, or
          navigate the services. This information does not reveal your specific
          identity (like your name or contact information) but may include
          device and usage information, such as your IP address, browser and
          device characteristics, operating system, language preferences,
          referring URLs, device name, country, location, information about how
          and when you use our Services, and other technical information. This
          information is primarily needed to maintain the security and operation
          of our Services, and for our internal analytics and reporting
          purposes.
        </p>
        <p>
          Like many businesses, we also collect information through cookies and
          similar technologies.
        </p>

        <div className='divider-5'></div>
        <h5>2. HOW DO WE PROCESS YOUR INFORMATION?</h5>
        <p>
          In Short: We process your information to provide, improve, and
          administer ourServices, communicate with you, for security and fraud
          prevention, and to complywith law. We may also process your
          information for other purposes with your consent.
        </p>
        <p>
          We process your personal information for a variety of reasons,
          depending on how you interact with our Services, including:
        </p>

        <div className='divider-5'></div>
        <h5>3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h5>
        <p>
          In Short: We may share information in specific situations described in
          this section and/or with the following third parties.
        </p>
        <p>
          We may need to share your personal information in the following
          situations:
        </p>
        <p>
          Business Transfers. We may share or transfer your information in
          connection with, or during negotiations of, any merger, sale of
          company assets, financing, or acquisition of all or a portion of our
          business to another company.
        </p>
        <p>
          Affiliates. We may share your information with our affiliates, in
          which case we will require those affiliates to honor this privacy
          notice. Affiliates include our parent company and any subsidiaries,
          joint venture partners, or other companies that we control or that are
          under common control with us.
        </p>
        <p>
          Business Partners. We may share your information with our business
          partners to offer you certain products, services, or promotions.
        </p>

        <div className='divider-5'></div>
        <h5>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h5>
        <p>
          In Short: We may use cookies and other tracking technologies to
          collect and store your information.
        </p>
        <p>
          We may use cookies and similar tracking technologies (like web beacons
          and pixels) to access or store information. Specific information about
          how we use such technologies and how you can refuse certain cookies is
          set out in our Cookie Notice.
        </p>

        <div className='divider-5'></div>
        <h5>5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h5>
        <p>
          In Short: If you choose to register or log in to our services using a
          social media account, we may have access to certain information about
          you.
        </p>
        <p>
          Our Services offer you the ability to register and log in using your
          third-party social media account details (like your Facebook or
          Twitter logins). Where you choose to do this, we will receive certain
          profile information about you from your social media provider. The
          profile information we receive may vary depending on the social media
          provider concerned, but will often include your name, email address,
          friends list, and profile picture, as well as other information you
          choose to make public on such a social media platform.
        </p>
        <p>
          We will use the information we receive only for the purposes that are
          described in this privacy notice or that are otherwise made clear to
          you on the relevant Services.
        </p>
        <p>
          Please note that we do not control, and are not responsible for, other
          uses of yourpersonal information by your third-party social media
          provider. We recommend that you review their privacy notice to
          understand how they collect, use, and share your personal information,
          and how you can set your privacy preferences on their sites and apps.
        </p>

        <div className='divider-5'></div>
        <h5>6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</h5>
        <p>
          In Short: We may transfer, store, and process your information in
          countries other than your own.
        </p>
        <p>
          Our servers are located in. If you are accessing our Services from
          outside, please be aware that your information may be transferred to,
          stored, and processed by us in our facilities and by those third
          parties with whom we may share your personalinformation (see "WHEN AND
          WITH WHOM DO WE SHARE YOUR PERSONALINFORMATION?" above), in and other
          countries.
        </p>
        <p>
          You have surpassed your 1 policy limit. Please upgrade to publish
          additional policies.
        </p>
        <p>
          laws as comprehensive as those in your country. However, we will take
          all necessary measures to protect your personal information in
          accordance with this privacy notice and applicable law.
        </p>
        <div className='divider-5'></div>
        <h5>7. HOW LONG DO WE KEEP YOUR INFORMATION?</h5>
        <p>
          In Short: We keep your information for as long as necessary to fulfill
          the purposes outlined in this privacy notice unless otherwise required
          by law.
        </p>
        <p>
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy notice, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting, or other legal requirements). No purpose in this notice
          will require us keeping your personal information for longer than
        </p>
        <p>
          When we have no ongoing legitimate business need to process your
          personal
        </p>
        <p>
          information, we will either delete or anonymize such information, or,
          if this is not possible (for example, because your personal
          information has been stored in backup archives), then we will securely
          store your personal information and isolate it from any further
          processing until deletion is possible.
        </p>

        <div className='divider-5'></div>
        <h5>8. DO WE COLLECT INFORMATION FROM MINORS?</h5>
        <p>
          In Short: We do not knowingly collect data from or market to children
          under 18 years of age.
        </p>
        <p>
          We do not knowingly solicit data from or market to children under 18
          years of age. By using the Services, you represent that you are at
          least 18 or that you are the parent or guardian of such a minor and
          consent to such minor dependent&rsquo;s use of theServices. If we
          learn that personal information from users less than 18 years of age
          has been collected, we will deactivate the account and take reasonable
          measures to promptly delete such data from our records. If you become
          aware of any data we may have collected from children under age 18,
          please contact us at.
        </p>

        <div className='divider-5'></div>
        <h5>9. WHAT ARE YOUR PRIVACY RIGHTS?</h5>
        <p>
          In Short: You may review, change, or terminate your account at any
          time.
        </p>
        <p>
          Withdrawing your consent: If we are relying on your consent to process
          your personal information, which may be express and/or implied consent
          depending on the applicable law, you have the right to withdraw your
          consent at any time. You can withdraw your consent at any time by
          contacting us by using the contact details provided in the section
          "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below.
        </p>
        <p>
          However, please note that this will not affect the lawfulness of the
          processing before its withdrawal, nor when applicable law allows, will
          it affect the processing of your personal information conducted in
          reliance on lawful processing grounds other than consent.
        </p>
        <strong>Account Information</strong>
        <p>
          If you would at any time like to review or change the information in
          your account or terminate your account, you can:
        </p>
        <p>
          Upon your request to terminate your account, we will deactivate or
          delete your account and information from our active databases.
          However, we may retain some information in our files to prevent fraud,
          troubleshoot problems, assist with anyinvestigations, enforce our
          legal terms and/or comply with applicable legal requirements.
        </p>

        <div className='divider-5'></div>
        <h5>10. CONTROLS FOR DO-NOT-TRACK FEATURES</h5>
        <p>
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track ("DNT") feature or setting you can
          activate to signal yourprivacy preference not to have data about your
          online browsing activities monitoredand collected. At this stage no
          uniform technology standard for recognizing andimplementing DNT
          signals has been finalized. As such, we do not currently respond to
          DNT browser signals or any other mechanism that automatically
          communicates your choice not to be tracked online. If a standard for
          online tracking is adopted that we must follow in the future, we will
          inform you about that practice in a revised version of this privacy
          notice.
        </p>

        <div className='divider-5'></div>
        <h5>11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h5>
        <p>
          In Short: Yes, if you are a resident of California, you are granted
          specific rights regarding access to your personal information.
        </p>
        <p>
          California Civil Code Section 1798.83, also known as the "Shine The
          Light" law, permits our users who are California residents to request
          and obtain from us, once a year and free of charge, information about
          categories of personal information (if any) we disclosed to third
          parties for direct marketing purposes and the names and addresses of
          all third parties with which we shared personal information in the
          immediately preceding calendar year. If you are a California resident
          and would like to make such a request, please submit your request in
          writing to us using the contact information provided below.
        </p>
        <p>
          If you are under 18 years of age, reside in California, and have a
          registered account with Services, you have the right to request
          removal of unwanted data that you publicly post on the Services. To
          request removal of such data, please contact us using the contact
          information provided below and include the email address associated
          with your account and a statement that you reside in California. We
          will make sure the data is not publicly displayed on the Services, but
          please be aware that the data may not be completely or comprehensively
          removed from all our systems (e.g., backups, etc.).
        </p>
        <div className='divider-5'></div>
        <h5>12. DO WE MAKE UPDATES TO THIS NOTICE?</h5>
        <p>
          In Short: Yes, we will update this notice as necessary to stay
          compliant with relevant laws.
        </p>
        <p>
          We may update this privacy notice from time to time. The updated
          version will beindicated by an updated "Revised" date and the updated
          version will be effective as soon as it is accessible. If we make
          material changes to this privacy notice, we may notify you either by
          prominently posting a notice of such changes or by directly sending
          you a notification. We encourage you to review this privacy notice
          frequently to be informed of how we are protecting your information.
        </p>

        <div className='divider-5'></div>
        <h5>13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h5>
        <p>
          If you have questions or comments about this notice, you may email us
          ator by post to:
        </p>
        <p>
          <address>
            <strong>Address</strong>
            <br />
            <br />
            S-84 Al Muqaam Complex, <br />
            Vishala Circle, Nr. APMC Market,
            <br />
            Ahmedabad-380055. Gujarat. India
          </address>
        </p>
        <p>
          <i>Or </i>
          <strong>Email</strong> support[at]palansoft.com
        </p>
        <div className='divider-5'></div>
        <h5>
          14. HOW CAN YOU REVIEW, UPDATE, OR DETELE THE DATA WE COLLECT FROM
          YOU?
        </h5>
        <p>
          Based on the applicable laws of your country, you may have right to
          request to the personal information we collect from you, change the
          information, or delete it in some circumstances. To request or review,
          update or delete your personal informationplease submit a request form
          by clicking here. (Mail to support[at]palansoft.com)
        </p>

        {/* <p>
          Your use of the Sites is also expressly subject to our Terms of Use,
          available at &nbsp;
          <Link className='text-red' to='/terms'>
            TERMS
          </Link>
        </p> */}
      </div>
    </div>
  )
}

export default PrivacyPage
