import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Header from './components/Header'
import Footer from './components/Footer'
import AboutPage from './pages/AboutPage'
import Signin from './pages/Signin'
import Signup from './pages/Signup'
import ResetPassword from './pages/ResetPassword'
import ForgotPassword from './pages/ForgotPassword'
import Homepage from './pages/Home'
import Userlisting from './pages/UserListing'
import EditUser from './pages/EditUser'
import AddUser from './pages/AddUser'
import FaqPage from './pages/Faq'
import PrivacyPage from './pages/Privacy'
import TermsPage from './pages/Terms'
import Contact from './pages/Contact'
import Careers from './pages/Careers'
import Team from './pages/Team'
import Refund from './pages/Refund'
import Schools from './pages/Schools'
import Metatags from './components/shared/Metatags'
import PageNotFound from './pages/PageNotFound'
import HomeViewAllActivity from './components/HomeViewAllActivity'
import BookDetail from './pages/BookDetail'
import BookChapter from './pages/BookChapter'
import BookChapterDetail from './pages/BookChapterDetail'
import BookPDF from './pages/BookPDF'
import BookSolution from './pages/BookSolution'
import BookActivities from './pages/BookActivities'
import BookQna from './pages/BookQna'
import BookKeywords from './pages/BookKeywords'
//import ChatLogin from '../src/chat/chatnew/components/ChatLogin' 
//import ChatDemoPage from '../src/chat/chatnew/components/ChatPage';
//import ChatLogin from './chat/ChatLogin'
import ChatPage from './pages/Chat'
import ChatPageOld from './pages/ChatOld'

//import socketIO from "socket.io-client"
//const socket = socketIO.connect("http://192.168.0.106:4002")

function App() {
  
  return (
    <Router>
      <Metatags
        pagetitle='Palansoft Interactive Learning :- Pamper Your Curiousity'
        canonicalurl='https://palansoft.com/'
      />
      <Header />
      <Routes>
        <Route path='/' element={<Homepage />} />
        <Route path='/signin' element={<Signin />} />
        <Route path='/signup' element={<Signup />} />
        <Route path='/about' element={<AboutPage />} />
        <Route path='/edit-user' element={<EditUser />} />
        <Route path='/reset-password' element={<ResetPassword />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/userlisting' element={<Userlisting />} />
        <Route path='/adduser' element={<AddUser />} />
        <Route path='/faqs' element={<FaqPage />} />
        <Route path='/chatold' element={<ChatPageOld />} />
        
        {/* <Route path="/chatlogin" element={<ChatLogin socket={socket}/>}></Route>
        <Route path="/chat" element={<ChatPage socket={socket}/>}></Route> 
        <Route path="/chat" element={<ChatPage />}></Route>*/}
          
        <Route path='/privacy' element={<PrivacyPage />} />
        <Route path='/terms' element={<TermsPage />} />
        <Route path='/refund' element={<Refund />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/careers' element={<Careers />} />
        <Route path='/team' element={<Team />} />
        <Route path='/schools' element={<Schools />} />
        <Route path='/all-activity' element={<HomeViewAllActivity />} />
        <Route path='/book-detail' element={<BookDetail/>} />
        <Route path='/book-chapter' element={<BookChapter/>} />        
        <Route path='/book-chapter-detail' element={<BookChapterDetail/>} />
        <Route path='/Book-PDF' element={<BookPDF/>} /> 
        <Route path='/Solution' element={<BookSolution/>} />
        <Route path='/Activities' element={<BookActivities/>} />
        <Route path='/Q-&-A' element={<BookQna/>} />        
        <Route path='/Keywords' element={<BookKeywords/>} />                
        <Route path='*' element={<PageNotFound />} />
      </Routes>
      <Footer />
    </Router>
  )
}

export default App
