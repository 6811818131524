import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import AxiosClient from '../api/axiosClient'
import Logo from '../assets/logo-new.png'
import { FaSpinner, FaEye, FaEyeSlash } from 'react-icons/fa'
import Demoimg from '../assets/users/1.png'

function UserListing(props) {
  const navigate = useNavigate()
  const [activity, setActivity] = useState([])
  const [userList, setUserList] = useState([])  
  const [daysCount, setdaysCount] = useState('')
  const [activitylistresult, setActivitylistresult] = useState(null)
  const [apiStatus, setApiStatus] = useState(null)

  const [VerificationCodeData, setVerificationCodeData] = useState(false)
  const [VerOTPStatus, setVerOTPStatus] = useState([])
  const [loading, setLoading] = useState(false)
  const [FormApiResult, setFormApiResult] = useState('')
  const [loginresult, setLoginresult] = useState([])
  const [isSubmit, setIsSubmit] = useState(false)
  // Form validation new
  const [FullName, setFullName] = useState('')
  const [passwordShown, setPasswordShown] = useState(false)

  const [CurrentUserUID, setCurrentUserUID] = useState('')
  const [selectedNewUserUID, setSelectedNewUserUID] = useState('')
  useEffect(async () => {
    const currentuserdata = await JSON.parse(
      localStorage.getItem('selectCurrentUserUID')
    )
    setCurrentUserUID(currentuserdata)
  }, [])

  // get login user info
  const [userinfo, setUserinfo] = useState(null)
  useEffect(async () => {
    const userdata = await JSON.parse(localStorage.getItem('userinfo'))
    setUserinfo(userdata)

    if (userdata) {
      const response = await AxiosClient({
        method: 'post',
        url: '/user/listing',
        data: {
          UID: userdata.UID,
        },
      })
      if (response.data.status) {
        //console.log('activity', response.data.data)
        setdaysCount(response.data.data.freeDays)
        //localStorage.setItem('userinfo', JSON.stringify(response.data.data))
        setActivity(response.data.data.data)
        setUserList(response.data.data.data)
      }
      if (!response.data.data) {
        //console.log(response.data.data)
        setApiStatus('No user found')
      }
    } else {
      navigate('/signin')
    }
  }, [])

  //console.log('all data is ', activity)

  // Start Delete Users function
  function deleteUser(name) {
    //alert('Are You Sure?')
    let deleteuser = `${name}`
    //console.log(deleteuser)

    //console.log(this)
    AxiosClient({
      method: 'post',
      url: '/user/deleteUser',
      data: {
        UserUID: deleteuser,
      },
    }).then((response) => {
      if (response.data.status) {
        //localStorage.setItem('userinfo', JSON.stringify(response.data.data))
        //setLoginresult(response.data.data.Name)
        navigate('/')
        //window.location.reload(false)
      } else {
      }
    })
  }

  // Toggle Passowrd Text
  const togglePassword = () => {
    setPasswordShown(!passwordShown)
  }
  const [selectedUserid, setSelectedUserid] = useState('');
  function viewactivity(item) {
    localStorage.setItem('selectCurrentUserUID', JSON.stringify(item))
    //navigate('/')    
    //const setSelectedUserid = item
    //let CurrentUserUID = JSON.stringify(item)
    setVerificationCodeData(true)
    //  if (activity.length > '1') {
    //    setVerificationCodeData(true)
    //  } else {
    //    navigate('/')
    //  }
  }

  // Start Verification code ////////////////////////////////////////

  // Start Verification code

  // New Verification validation
  const VeriCodeinitialValues = {
    //Mobile: '',
    //CountryCode: '',
    //Password: '',
    UserUID: selectedUserid,
    VerificationCodeOTP: '',
  }
  const [VerFormformValues, setVerFormformValues] = useState(
    VeriCodeinitialValues
  )
  const [VerCodeformErrors, setVerCodeformErrors] = useState({})
  const [VerCodeisSubmit, setVerCodeisSubmit] = useState(false)

  const [isOTPSubmit, setisOTPSubmit] = useState(false)

  const VerCodehandleChange = (e) => {
    const { name, value } = e.target
    setVerFormformValues({ ...VerFormformValues, [name]: value })
    //console.log('Form value is', formValues)
  }

  //  New Verification validation function
  const SubmitVerificationCode = (e) => {
    //e.preventDefault()
    setVerCodeformErrors(verivalidate(VerFormformValues))
    setisOTPSubmit(true)
  }

  useEffect(() => {
    //console.log(formErrors)
    if (Object.keys(VerCodeformErrors).length === 0 && isOTPSubmit) {
      //console.log(VerFormformValues)
      verifymeOTP()
    }
  }, [VerCodeformErrors])

  useEffect(() => {
      if (VerFormformValues.VerificationCodeOTP.length == 6) {
        SubmitVerificationCode()
    }
    },[VerFormformValues])
  // New Verification Function From
  const verifymeOTP = (item) => {    
     setLoading(true)
     setVerificationCodeData(true)
    var sendData = {
      UserUID: selectedNewUserUID,
      PIN: VerFormformValues.VerificationCodeOTP,
    }
    //console.log('user pin data',sendData)
    AxiosClient({
      method: 'post',
      url: '/user/userVerification',
      data: sendData,
    }).then((response) => {
      if (response.data.status === true) {
        localStorage.setItem('userUID', JSON.stringify(response.data))
        localStorage.setItem('MPINStatus', JSON.stringify(response.data.status))
        //setLoginresult(response.data)
        //console.log('OTPCheckForgot API msg', response.data)
        setTimeout(() => {
          setVerificationCodeData(true)
          setLoading(true)
          navigate('/')
          window.location.reload()
        }, 4000)
      } else if (response.data.status === false) {
        setLoginresult(response.data)
        setLoading(false)
        setVerificationCodeData(true)
        //console.log('OTPCheckForgot else if API msg', response.data)
      } else {
        // setUserdata = response.data.data.Name
        setLoginresult(response.data)
        setFormApiResult(response.data)
        //console.log('OTPCheckForgot API msg fale', response.data)
        //console.log(response.data.data.Name)
      }
    })
  }

  const verivalidate = (values) => {
    const VeriFormValErrors = {}
    const regexm = /^([+]\d{2})?\d{8,10}$/

    if (!values.VerificationCodeOTP) {
      VeriFormValErrors.VerificationCodeOTP = 'MPIN is required!'
    } else if (values.VerificationCodeOTP.length < 6) {
      VeriFormValErrors.VerificationCodeOTP = 'MPIN should be 6 characters'
    } else if (values.VerificationCodeOTP.length > 6) {
      VeriFormValErrors.VerificationCodeOTP = 'MPIN should be 6 characters'
    }

    return VeriFormValErrors
  }

  const OTPWindowClose = () => {
    setLoading(false)
    setVerificationCodeData(false)
    //window.location.reload()
  }
  // const CloseOTPBox = () => {
  //   setVerificationCodeData(false)
  // }
  // End Delete Users function

  

  const onImageError = (e) => {
    e.target.src = Demoimg
  }


  return (
    <div className='form-main-con-1 container'>
      {VerificationCodeData ? (
        <div className='verificationbox-out-con'>
          <div className='verificationbox-con'>
            {/* <img width='auto' height={60} alt='' src={Logo} />
            <div className='divider-4'></div> */}
            <h2 className='page-title-1 text-left'>Enter MPIN</h2>
            <p>Please enter MPIN to access activity's, and other features...</p>
            <p className='text-red'>{loginresult.message}</p>
            <div className='col-12'>
              <div className='input-group has-validation'>
                <input
                  type={passwordShown ? 'text' : 'password'}
                  placeholder='MYMPIN'
                  className='form-control pinverification-input'
                  id='VerificationCodeOTP'
                  name='VerificationCodeOTP'
                  value={VerFormformValues.VerificationCodeOTP}
                  onChange={VerCodehandleChange}
                />
                <span
                  className='input-group-text'
                  id='basic-addon2'
                  onClick={togglePassword}
                >
                  {passwordShown ? (
                    <>
                      <FaEye />
                    </>
                  ) : (
                    <>
                      <FaEyeSlash />
                    </>
                  )}
                </span>
                <div className='invalid-feedback form-error'>
                  {VerCodeformErrors.VerificationCodeOTP}
                </div>
              </div>
            </div>

            {/* <div className='col-12'>
              <button
                onClick={SubmitVerificationCode}
                className='btn btn-primary d-grid gap-2 col-12'
                type='button'
              >
                Verify
              </button>
            </div> */}
            {loading ? (
              <>
                <div className='col-12'>
                  <div className='divider-4'></div>
                  <FaSpinner className='loader' />
                </div>
              </>
            ) : (
              <div className='col-12'>
                <div className='divider-4'></div>
                <p className='text-left'>
                  <button
                    onClick={OTPWindowClose}
                    className='text-red gap-2 nobtn'
                  >
                    Back
                  </button>
                </p>
              </div>
            )}
            {/* <div className='col-12'>
              <div className='divider-2'></div>
              {loading ? <FaSpinner className='loader' /> : ''}
              <p>
                <button
                  onClick={CloseOTPBox}
                  className='text-white gap-2 nobtn pull-left'
                >
                  Close
                </button>
                <button
                  onClick={ResendOTP}
                  className='text-red gap-2 nobtn pull-right'
                >
                  Resend!
                </button>
              </p>
            </div> */}
          </div>
        </div>
      ) : (
        ''
      )}
      <div className='col-12'>
        {/* <h1>Add Users</h1> */}
        {activity.length < 1 ? (
          <h3 className='text-center'>{apiStatus}</h3>
        ) : (
          <>
            <h2 className='page-title-1 text-left'>Who is learning?</h2>
            <div className='text-white'>
              {daysCount >= '1' && (
                <p className='text-white'>
                  For next {daysCount} days, All activities are free.
                </p>
              )}
              {daysCount == '1' && (
                <p className='text-yellow'>
                  Free access to chargeable activities ends tomorrow.
                </p>
              )}
              {/* {daysCount <= '0' && (
                <p className='text-red'>
                  Free access to chargeable activities is expired.
                </p>
              )}  */}
            </div>
            <div className='col-12'>
              <p>Please select user to continue</p>
            </div>
          </>
        )}

        {/* {!apiStatus && (
          
        )} */}
        {userList.map((item, index) => (
          <div
            id={item.UserUID}
            key={index}
            className='row'
            onClick={() =>
              viewactivity(item) || setSelectedNewUserUID(item.UserUID)
            }
          >
            <div className='user-info-box'>
              <a
                title='Edit User'
                //onClick={() => viewactivity(item.UserUID)}

                className='icon-edit'
              >
                {/* <FaUserEdit className='fa' /> */}
              </a>
              <a
                title='Delete User'
                //onClick={() => deleteUser(item.UserUID)}
                className='icon-delete text-red'
              >
                {/* <FaUserTimes className='fa text-red' /> */}
              </a>
              <span className='user-image'>
                <img
                  width={100}
                  height={100}
                  alt={item.Name}
                  src={item.ProPic ? item.ProPic : Demoimg }
                  onError={onImageError}
                />

                {/* <img
                  width={100}
                  height={100}
                  alt={item.Name}
                  src={item.ProPic}
                /> */}
              </span>
              <span className='user-name'>{item.Name}</span>
              <span className='user-grade'>Grade: {item.Grade}</span>
              <span className='user-language'>Lang: {item.Language}</span>
            </div>
          </div>
        ))}
      </div>

      {activity.length < 3 ? (
        <div className='col-12'>
          <div className='divider-2'></div>
          {/* <p className='text-white  text-center'>Max 3 Users</p> */}
          <p className='text-center text-white'>
            <Link
              className='btn btn-primary text-white text-center'
              to='/adduser'
            >
              Add User
            </Link>
          </p>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default UserListing
