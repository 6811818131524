import PropTypes from 'prop-types'
import { Link, useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import Logo from '../assets/logo-new.png'
import HomeBaImg1 from '../assets/bg-net-1.jpg'
import {  Nav,  NavDropdown,  Navbar,  Container} from 'react-bootstrap'
import { FaSearch } from 'react-icons/fa'

function Header() {
  const navigate = useNavigate()
  const [userinfo, setUserinfo] = useState(null)
  const [useruid, setUseruid] = useState(null)
  const [Teachername, setTeachername] = useState('null')
  useEffect(async () => {
    const userdata = typeof window !== 'undefined' ? await JSON.parse(localStorage.getItem('userinfo')) : null
    const data = typeof window !== 'undefined' ? await JSON.parse(localStorage.getItem('selectCurrentUserUID')) : null
    const teacherdata = typeof window !== 'undefined' ? await JSON.parse(localStorage.getItem('teacherinfo')) : null
    setUserinfo(userdata)
    setUseruid(data)
    setTeachername(teacherdata)
    //fetchData(data.UID, data.UserUID, data.LanguageCode)
  }, [])



  function logOut() {
    localStorage.clear();
    //activitylist.clear();
    window.localStorage.clear(); //clear all localstorage
    window.localStorage.removeItem(
      'activitylist',
      'userinfo',
      'AllGradelist',
      'AlllanguageList',
      'teacherbookinfo',
      '*'
    )
    window.location.reload(false)
    navigate('/signin')
  }
  return (
    <>
      <img
        className='homepage-main-bg-1'
        src={HomeBaImg1}
        alt='Palansoft Interactive Learning :- Pamper Your Curiousity...'
      />

      <Navbar collapseOnSelect expand='lg' variant='dark'>
        <Container>
          <Navbar.Brand>
            <Link to='/'>
              <img
                src={Logo}
                alt='Loading...'
                style={{ width: '298px', margin: 'auto', display: 'block' }}
              />
            </Link>
          </Navbar.Brand>
          {Teachername && <span className='header-address-con'>School-146,City,District.</span> }
          <Navbar.Toggle aria-controls='responsive-navbar-nav' />
          <Navbar.Collapse
            id='responsive-navbar-nav'
            className='navbar-right-con'
          >
            <Nav className='justify-content-end flex-grow-1 pe-3'>
              
              {userinfo ? (
                <>
                  {useruid && (
                    <>
                      <button
                        className='btn btn-link-white nobtn'
                        type='button'
                      >
                        Welcome back,  {useruid.Name}
                      </button>
                    </>
                  )}
                  {!useruid && (
                    <>
                      <button
                        className='btn btn-link-white nobtn'
                        type='button'
                      >
                        Welcome back, {userinfo.Name}
                      </button>
                    </>
                  )}
                  <button
                    onClick={logOut}
                    className='btn btn-link-white nobtn'
                    type='button'
                  >
                    Log Out
                  </button>
                  <a className='btn btn-link-white nobtn searchicon' href='#searchbar'>
                    <FaSearch /> &nbsp;
                  </a>
                  <NavDropdown
                    className='btn btn-link-white nobtn'
                    title='Profile'
                    id='collasible-nav-dropdown'
                  >
                    <Link className='btn btn-link-white' to='/userlisting'>
                      View All Users
                    </Link>
                  </NavDropdown>
                </>
              ) : (
                <>
                  <Link className='btn btn-danger' to='/signin'>
                    Sign In
                  </Link>
                  <Link className='btn btn-link-white' to='/signup'>
                    Sign Up
                  </Link>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}

Header.defaultProps = {
  text: 'Palansoft Interactive Learning',
  background: 'assets/logo.png',
  bgColor: 'rgba(255,255,255)',
  textColor: '#ff6a95',
}

Header.propTypes = {
  text: PropTypes.string,
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
}

export default Header
