import { Link } from 'react-router-dom'
function Refund() {
  return (
    <div className='container min-height'>
      <div className='terms'>
        <h1>RETURNS</h1>
        <p>
          We strictly follow <strong>NO REFUND</strong> Policy,
        </p>
        <p>
          Any of our ticket size does not exceed INR 99.00 (including taxes
          applicable in India) or US$ 1.70 anywhere in the world.
        </p>
        <p>
          If you have paid the amount for any activity, and haven’t received
          access toactivity yet at, first check your bank account again.
        </p>
        <p>
          If you still believe, everything went well from your side, please
          contact us at support[at]palansoft.com and provide following details.
        </p>
        <p>
          Main UserID (Country Code)
          <br />
          Mobile Number
          <br />
          Name of the Purchased Activity (which is failed to activate)
          <br />
          Amount deducted from your account
          <br />
          Date and approximate Time of purchase.
          <br />
        </p>
        <p>We will resolve the issue within 12 working hours. </p>
        <div className='divider-5'></div>
        <h3>Contacting PALANSOFT</h3>
        <p>
          If you have any questions about this refund policy, please contact us
          at the following address:
        </p>
        <p>
          <strong>Palansoft Private Limited,</strong>
          <br />
          S-84, Al Muqaam, Vishala
          <br />
          Circle, Nr. APMC Market, <br />
          Ahmedabad, 380055 Gujarat, India. <br />
          <br />
          <strong>Phone:</strong> +91 989 898 3059 <br />
          <br />
          <strong>Email:</strong> help[at]palansoft.com
        </p>
        <div className='divider-5'></div>
      </div>
    </div>
  )
}

export default Refund