import React, {useState, useEffect} from 'react'
import {useNavigate} from "react-router-dom"
import Demoimg from '../assets/users/1.png'

const ChatBody = ({messages, typingStatus, lastMessageRef}) => { 
  const navigate = useNavigate()
  
  const [userinfo, setUserinfo] = useState(null)
  const [chatUserName, setChatUserName] = useState("")
  
  useEffect(async () => {
    const chatuser = typeof window !== 'undefined' ? localStorage.getItem('chatUserName') : null  
    setChatUserName(chatuser)
  }, [chatUserName])

  const handleLeaveChat = () => {
    localStorage.removeItem("chatUserName")
    navigate("/chatlogin")
    window.location.reload()
  }
  
  return (
    <>
      {/* <header className='chat__mainHeader'>
          <p>Hangout with Colleagues</p>
          <button className='leaveChat__btn' onClick={handleLeaveChat}>LEAVE CHAT</button>
        </header>


        <div className='message__container'>
          {messages.map(message => (
            message.name === localStorage.getItem("chatUserName") ? (
              <div className="message__chats" key={message.id}>
            <p className='sender__name'>You</p>
            <div className='message__sender'>
                <p>{message.text}</p>
            </div>
          </div>
            ): (
              <div className="message__chats" key={message.id}>
            <p>{message.name}</p>
            <div className='message__recipient'>
                <p>{message.text}</p>
            </div>
          </div>
            )
            ))}

          <div className='message__status'>
            <p>{typingStatus}</p>
          </div>
          <div ref={lastMessageRef} />   
        </div> */}
        <div className='card-header' id='kt_chat_messenger_header'>
            <div className='card-title'>
              <div className='symbol-group symbol-hover'></div>
              
              <div className='d-flex justify-content-center flex-column me-3'>
              
                <a
                  href='#'
                  className='fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1'
                >
                   {chatUserName} 
                </a>

                <div className='mb-0 lh-1'>
                  <span className='badge badge-success badge-circle w-10px h-10px me-1'></span>
                  <span className='fs-7 fw-bold text-gray-400'>Active</span>
                </div>
              </div>
              
            </div>

            <div className='card-toolbar'>
              <div className='me-n3'>
                {/* <button
                  className='btn btn-sm btn-icon btn-active-light-primary'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                >
                  <i className='bi bi-three-dots fs-2'></i>
                </button>
                <Dropdown1 /> */}
                <button className='btn btn-sm btn-icon btn-active-light-primary' onClick={handleLeaveChat}>LEAVE CHAT</button>
              </div>
            </div>
          </div>

          <div className='card-body chat-max-height' id='kt_chat_messenger_body'>
            <div className='me-n5 pe-5 h-300px h-lg-auto'>                    
          {messages.map(message => (            
            message.name === chatUserName ? (
              // <div className='d-flex d-flex justify-content-start mb-10 mb-10' key={message.id}>
              //   <p className='sender__name'>You</p>
              //   <div className='message__sender'>
              //       <p>{message.text}</p>
              //   </div>
              // </div>
              <>                            
              <div className='d-flex d-flex justify-content-start mb-10 mb-10' key={message.id}>                 
                <div className='d-flex flex-column align-items align-items-start'>
                  <div className='d-flex align-items-center mb-2'>
                    <div className='symbol  symbol-35px symbol-circle '>
                      {/* <img alt='Pic' src={Demoimg} /> */}
                      
                    </div>
                    <div className='ms-3'>
                      <a
                        href='#'
                        className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1'
                      >
                         {message.name}
                      </a>
                      <span className='text-muted fs-7 mb-1'>2 mins</span>
                    </div>
                  </div>
                  <div
                    className='p-5 rounded bg-light-info text-dark fw-bold mw-lg-400px text-start'
                    data-kt-element='message-text'
                  >
                   {message.text}
                  </div>
                </div>
              </div>
              </>
            ): (
              // <div className='d-flex d-flex justify-content-start mb-10 mb-10' key={message.id}>
              //   <p>{message.name}</p>
              //   <div className='message__recipient'>
              //       <p>{message.text}</p>
              //   </div>
              // </div>
              <div className='d-flex d-flex justify-content-end mb-10 mb-10' key={message.id}>
                <div className='d-flex flex-column align-items align-items-end'>
                  <div className='d-flex align-items-center mb-2'>
                    <div className='me-3'>
                      <span className='text-muted fs-7 mb-1'>5 mins</span>
                      <a
                        href='#'
                        className='fs-5 fw-bolder text-gray-900 text-hover-primary ms-1'
                      >
                        {message.name}
                      </a>
                    </div>
                    <div className='symbol  symbol-35px symbol-circle '>
                      <img alt='Pic' src={Demoimg} />
                    </div>
                  </div>
                  <div
                    className='p-5 rounded bg-light-primary text-dark fw-bold mw-lg-400px text-end'
                    data-kt-element='message-text'
                  >
                   {message.text}
                  </div>
                </div>
              </div>
            )
            ))}

          <div className='message__status '>
            <p className='fs-7 fw-bold text-gray-400'><i>{typingStatus}</i></p>
          </div>
          <div ref={lastMessageRef} />   
        </div></div>
    </>
  )
}

export default ChatBody